<template>
    <form @submit.prevent="onFormSubmit" class="c-modal-seach-form" :class="{ 'is-loading': isLoading }">
        <input-base
            type="search"
            v-model="searchTerm"
            placeholder="Rechercher..."
            :is-full="true"
            class="filter-input"
            @input="debouncedOnInput"
        />
    </form>
    <div ref="predictiveSearchResults"></div>
</template>

<script setup>
import { computed, ref } from "vue";
import { debounce } from "@utils";
import { useTranslationDescriptions } from "@vue-composables";

import InputBase from "@vue-components/InputBase";

const { getDescription } = useTranslationDescriptions();

const isLoading = ref(false);
const searchTerm = ref(null);
const predictiveSearchResults = ref(null);

const trimmedSearchTerm = computed(() => searchTerm.value.trim());

function onFormSubmit(event) {
    if (trimmedSearchTerm.value.length) event.preventDefault();
}

function clearResults() {
    predictiveSearchResults.value.innerHTML = "";
}

function onInput() {
    const newSearchTerm = trimmedSearchTerm.value;
    if (!searchTerm.value || !newSearchTerm.startsWith(searchTerm.value)) {
        // Remove the results when they are no longer relevant for the new search term
        // so they don't show up when the dropdown opens again
        clearResults();
    }

    if (!searchTerm.value.length) {
        clearResults();
        return;
    }

    getSearchResults();
}

const debouncedOnInput = debounce(() => {
    onInput();
}, 500);

function getSearchResults() {
    isLoading.value = true;

    const query = encodeURIComponent(searchTerm.value);

    fetch(`${theme.routes.predictive_search_url}?q=${query}&section_id=api-predictive-search`)
        .then((response) => {
            if (!response.ok) {
                var error = new Error(response.status);
                throw error;
            }

            return response.text();
        })
        .then((text) => {
            const resultsMarkup = new DOMParser()
                .parseFromString(text, "text/html")
                .querySelector("#shopify-section-api-predictive-search").innerHTML;

            renderSearchResults(resultsMarkup);
        })
        .catch((error) => {
            if (error?.code === 20) {
                // Code 20 means the call was aborted
                return;
            }

            throw error;
        });
}

function renderSearchResults(resultsMarkup) {
    predictiveSearchResults.value.innerHTML = resultsMarkup;
    isLoading.value = false;
}
</script>

<style lang="scss" scoped>
.c-modal-seach-form {
    position: sticky;
    top: 0;
    z-index: 10;
    padding-bottom: 1em;
    &.is-loading {
        opacity: 0.5;
    }
}
</style>
