<template>
    <button class="c-toast-base-close-button | c-button-icon" aria-label="Fermer">
        <icon-base name="cross-sm" :size="2" :bg-size="2" :show-bg="true" bg-color="blue-light" :bg-radius="2" />
        <div class="c-button-icon__label">Fermer</div>
    </button>
</template>

<style lang="scss">
.c-toast-base-close-button {
    --toast-close-btn-width: 2rem;
    --toast-close-btn-bg-color: var(--color-blue);
    --toast-close-btn-offset: calc(0px - var(--toast-close-btn-width) / 2);
    transform: translateX(var(--toast-close-btn-offset)) translateY(var(--toast-close-btn-offset));
}
</style>
