<template>
    <slot v-bind="{ data }"></slot>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useBoutiquesStore } from "@vue-stores";

const props = defineProps({
    boutique_id: {
        type: String,
        required: false,
        default: "",
    },
    limit: {
        type: Number,
        required: false,
        default: 4,
    },
});

const boutiquesStore = useBoutiquesStore();
const { boutiques } = storeToRefs(boutiquesStore);

const data = computed(() => {
    if (props.boutique_id) {
        return boutiquesStore.getBoutiqueByID(props.boutique_id);
    } else {
        return boutiques.value.slice(0, props.limit);
    }
});
</script>
