<template>
    <button class="c-search-button" @click="openModal">
        <slot />
    </button>
</template>

<script setup>
import { useModalsStore } from "@vue-stores";

const modals = useModalsStore();

const openModal = () => {
    modals.openModal("search-modal");
};
</script>

<style lang="scss" scoped></style>
