<template>
    <div class="c-open-quick-view" @click="userClicked">
        <slot></slot>
    </div>
</template>
<script setup>
import { useModalsStore } from "@vue-stores";

const modals = useModalsStore(); // mock

const props = defineProps({
    url: {
        type: String,
        required: false,
        default: null,
    },
});

const modalName = "quick-view-modal";

////////////////////////////////
//       START USER CLICKED
////////////////////////////////
const userClicked = () => {
    // open modal
    openModal();
    // fetch API
    fetchProductAPI();
};
////////////////////////////////
//       END USER CLICKED
////////////////////////////////

////////////////////////////////
//       START OPEN MODAL
////////////////////////////////

/*------------------------------
Start open modal
------------------------------*/
const openModal = () => {
    modals.openModal(modalName);
};
/*------------------------------
End open modal
------------------------------*/

/*------------------------------
Start fetch product
------------------------------*/
const productData = `${props.url}?view=quickview.json`;

const fetchProductAPI = async () => {
    const response = await fetch(productData);
    const json = await response.json();
    addURLToJson(json);
};

const addURLToJson = (json) => {
    const productURL = props.url;
    const refactoredJSON = { ...json, productURL };

    fillQuickViewStore(refactoredJSON);
};

/*------------------------------
End fetch product
------------------------------*/

/*------------------------------
Start fill up quick view store
------------------------------*/
const fillQuickViewStore = (clonedProduct) => {
    const valuesToStore = {
        modalName: modalName,
        values: clonedProduct,
    };
    // fill up
    modals.fillModalByName(valuesToStore);
};

/*------------------------------
End fill up quick view store
------------------------------*/
</script>
<style lang="scss">
.c-open-quick-view {
    --display-quick-view: none;
    display: var(--display-quick-view);

    @include min("md") {
        --display-quick-view: inline-block;
    }
}
</style>
