export class ValidationError extends Error {
    constructor(message) {
        super(message);
        this.name = "ValidationError";
    }
}

export const modalRequiredSettingsError = new ValidationError(
    "`component` and `name` settings are required to register a new modal."
);

export const toastRequiredSettingsError = new ValidationError("A `name` is required to register a new toast.");
