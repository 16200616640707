<template>
    <div class="c-boutiques-listing-split-view">
        <div class="c-boutiques-listing-split-view__list">
            <slot name="count"></slot>

            <slot name="list"></slot>
        </div>
        <div class="c-boutiques-listing-split-view__map">
            <slot name="map"></slot>
        </div>
    </div>
</template>

<script setup>
import { inject, watch } from "vue";

import { QuesoScrollable } from "@allomambo/queso";

const activeMarker = inject("activeMarker");

// ScrollTo
const listScrollTo = (markerSlug) => {
    location.hash = `#${markerSlug}`;
};

watch(
    activeMarker,
    (newMarker) => {
        listScrollTo(`boutique-${newMarker.shop_id}`);
    },
    {
        deep: true,
    }
);
</script>

<style lang="scss" scoped>
.c-boutiques-listing-split-view {
    --split-view-list-width: 30rem;
    display: grid;
    grid-template-columns: var(--split-view-list-width) auto;
    gap: dvar(container-gutter);

    @include min(xl) {
        --split-view-list-width: 50rem;
    }

    &__list {
        @include min(xl) {
            padding-left: var(--grid-gap-2X);
            padding-right: var(--grid-gap-2X);
        }

        :deep(.c-boutiques-listing__cards) {
            li {
                scroll-margin-top: var(--section-offset-scroll);
            }
        }
    }

    &__map {
        position: sticky;
        top: var(--section-offset-scroll);
        height: calc(100vh - var(--section-offset-scroll) - var(--grid-gap));
    }
}
</style>
