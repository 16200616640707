<template>
    <overlay-base class="-modal" :visible="isVisible"></overlay-base>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useOverlayStore } from "@vue-stores";

import OverlayBase from "@vue-components/OverlayBase";

const overlayStore = useOverlayStore();
const { isVisible } = storeToRefs(overlayStore);
</script>
