import { computed, ref, isRef, unref, watchEffect } from "vue";

export function useSearchProducts(url) {
    // Refs
    const results = ref(null);
    const errors = ref(null);

    // Computed
    const allProductsCount = computed(() => results?.value?.all_products_count);
    const currentType = computed(() => results?.value?.current_type);
    const currentVendor = computed(() => results?.value?.current_vendor);
    const filters = computed(() => results?.value?.filters);
    const productsCount = computed(() => results?.value?.products_count);
    const resultsCount = computed(() => results?.value?.results_count);
    const sortBy = computed(() => results?.value?.sort_by);
    const sortOptions = computed(() => results?.value?.sort_options);
    const terms = computed(() => results?.value?.terms);

    const activeValues = computed(() => (filters?.value ? getActiveValues(filters.value) : []));
    const activeValuesCount = computed(() => (filters?.value ? getActiveValues(filters.value).length : 0));

    async function fetchResultsFromApi() {
        try {
            // Add suffix here
            let newUrl = unref(url);
            const suffix = "view=filters.json";
            const separator = newUrl.includes("?") ? "&" : "?";

            // Construct URL to fetch
            newUrl = newUrl + separator + suffix;

            const response = await fetch(newUrl);
            results.value = await response.json();
        } catch (e) {
            errors.value = e;
        }
    }

    function getActiveValues(filters) {
        return filters.flatMap((filter) => filter.active_values || []);
    }

    if (isRef(url)) {
        // setup reactive re-fetch if input URL is a ref
        watchEffect(fetchResultsFromApi);
    } else if (url) {
        // otherwise, just fetch once
        // and avoid the overhead of a watcher
        fetchResultsFromApi();
    }

    return {
        activeValues,
        activeValuesCount,
        allProductsCount,
        currentType,
        currentVendor,
        errors,
        filters,
        productsCount,
        results,
        resultsCount,
        retry: fetchResultsFromApi,
        sortBy,
        sortOptions,
        terms,
    };
}
