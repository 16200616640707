<template>
    <div class="c-facets-navigation-filters">
        <facets-navigation-filters-button :modal-name="modalName">
            <slot name="filter-button-icon" />
        </facets-navigation-filters-button>

        <facets-navigation-filters-modal :modal-name="modalName" @clearAllFilters="emit('clear')">
            <template #modal-header-icon>
                <slot name="modal-header-icon" />
            </template>

            <facets-navigation-filters-list :filters="filters" />
        </facets-navigation-filters-modal>
    </div>
</template>

<script setup>
import FacetsNavigationFiltersButton from "./components/FacetsNavigationFiltersButton";
import FacetsNavigationFiltersList from "./components/FacetsNavigationFiltersList";
import FacetsNavigationFiltersModal from "./components/FacetsNavigationFiltersModal";

const modalName = "filters-modal";

const emit = defineEmits(["clear"]);

const props = defineProps({
    filters: {
        type: Object,
        required: true,
    },
});
</script>

<style lang="scss" scoped></style>
