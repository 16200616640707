import { createApp } from "vue";
import { createPinia } from "pinia";
import barba from "@barba/core";
import useCartStore from "../vue/stores/cart";

import { VueMasonryPlugin } from "vue-masonry";
import TypeMate from "typemate";

import { createCustomElements } from "@/middlewares/customElements";

const pinia = createPinia();
const html = document.documentElement;

/**
 * Create vue instance function
 */
const createVueApp = (appName) => {
    const app = createApp({
        name: appName || "",
    });

    /**
     * Auto-import all vue components
     */
    const vueComponents = import.meta.glob(["@vue-components/**/index.js", "!@vue-components/**/components"], {
        eager: true,
    });

    for (const path in vueComponents) {
        const match = path.split("/");
        const name = match && match[4];
        name && app.component(name, vueComponents[path].default);
    }

    app.use(pinia);
    app.use(VueMasonryPlugin);

    return app;
};

/**
 * Create and mount vue instance(s)
 */
const vueElements = document.querySelectorAll("[vue]");

if (vueElements) {
    vueElements.forEach((el) => {
        const elName = el.getAttribute("vue");
        createVueApp(elName).mount(el);
    });
}

// Main wrapper don't use [vue] to be apart from other instances
let MainApp = null;

const nextMain = document.querySelector("#main");
MainApp = createVueApp("Main");
MainApp.mount(nextMain);

/**
 * Define custom elements
 */
const customElements = createCustomElements();

/**
 * Init Barba
 */
barba.init({
    debug: false,
    prefetchIgnore: true,
    preventRunning: true,
    prevent: ({ event, href }) => {
        if (event.type === "click") {
            // prevent the user to reload the page if the location is the same
            if (href === window.location.href) {
                event.preventDefault();
                event.stopPropagation();

                // automatically scroll to the top of the page on same location
                if (window.scrollY !== 0) {
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                }

                return true;
            }
        }
    },
    transitions: [
        {
            name: "default-transition",
            once: async () => {
                const cartStore = useCartStore();
                await cartStore.getCart();

                html.classList.add("is-site-ready");

                /**
                 * Init TypeMate to avoid orphans on <p>
                 */
                const typeMateInstance = new TypeMate();
                typeMateInstance.apply();
            },
            // to: {
            //     custom: () => {
            //         return setTimeout(() => {}, 250);
            //     },
            // },
            leave() {
                html.classList.add("is-site-loading");
            },
            enter() {
                html.classList.remove("is-site-loading");
            },
        },
    ],
});

barba.hooks.afterLeave((data) => {
    // Scroll to top of page
    window.scrollTo({ top: 0, behavior: "instant" });

    // Mount a completely new Main Vue app on each page change
    if (MainApp) {
        MainApp.unmount();
    }
    MainApp = createVueApp("Main");
    MainApp.mount(data.next.container);
});

// Reinit Shopify stuff based on the "next" page
barba.hooks.afterEnter((data) => {
    // Use a DOM parser to convert a String into an HTMLDocument
    let parser = new DOMParser();
    let dom = parser.parseFromString(data.next.html, "text/html");
    let bodyClasses = dom.body.classList;

    // Shopify Product Reviews
    if (window.SPR && bodyClasses.contains("p-product")) {
        window.SPR.initDomEls();
        window.SPR.loadProducts();
    }

    /**
     * Init TypeMate to avoid orphans on <p>
     */
    const typeMateInstance = new TypeMate();
    typeMateInstance.apply();
});

/**
 * Fixes for Shopify sections
 * 1. properly render vue components on user insert in the theme editor
 * 2. reload the current page to rerender async inserted sections with vue components
 *
 * add the 'vue' keyword to the section's wrapper classes if the section uses any vue functionality e.g.:
 * {% schema %}
 * {
 *   "class": "vue-section"
 * }
 * {% endschema %}
 */

if (Shopify.designMode) {
    document.addEventListener("shopify:section:load", (event) => {
        if (event.target.classList.value.includes("vue")) {
            createVueApp().mount(event.target);
        }
    });
} else if (!Shopify.designMode && import.meta.env.MODE === "development") {
    new MutationObserver((mutationsList) => {
        mutationsList.forEach((record) => {
            const vue = Array.from(record.addedNodes).find(
                (node) => node.classList && node.classList.value.includes("vue")
            );
            if (vue) window.location.reload();
        });
    }).observe(document.body, {
        childList: true,
        subtree: true,
    });
}
