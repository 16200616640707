<template>
    <queso-collapsible
        class="c-accordion-compact"
        :class="`theme-${themeColor}`"
        :expandOnMount="expandOnMount"
        @toggle="(value) => (isOpen = value)"
    >
        <template #headerText>
            <slot name="header"></slot>
        </template>

        <template #headerIcon>
            <!-- Opened -->
            <icon-base v-if="isOpen" name="chevron-sm" :size="2" :rotation="-90" show-bg :bg-size="2" :bg-radius="10" />
            <!-- Closed -->
            <icon-base v-else name="chevron-sm" :size="2" :rotation="90" show-bg :bg-size="2" :bg-radius="10" />
        </template>

        <template #content>
            <slot></slot>
        </template>
    </queso-collapsible>
</template>

<script setup>
import { ref } from "vue";
import { QuesoCollapsible } from "@allomambo/queso";

import IconBase from "@vue-components/IconBase";

const isOpen = ref(false);

const props = defineProps({
    expandOnMount: {
        type: Boolean,
        default: false,
    },
    themeColor: {
        type: String,
        default: "blue", // 'blue' or 'yellow'
    },
});
</script>

<style lang="scss">
.c-accordion-compact {
    --accordion-compact-header-gap-default: var(--grid-gap-two-third);
    --accordion-compact-header-font-size-default: var(--fs-medium);
    --accordion-compact-header-font-weight-default: 700;

    --accordion-compact-content-padding-top-default: var(--grid-gap-half);

    &.theme-blue {
        --icon-bg-color: var(--color-blue-light);
        &:hover {
            --icon-bg-color: var(--color-blue);

            .queso-collapsible__header__text {
                color: var(--color-accent-blue);
            }
        }
    }
    &.theme-yellow {
        --icon-bg-color: var(--color-yellow-light);
        &:hover {
            --icon-bg-color: var(--color-yellow);

            .queso-collapsible__header__text {
                color: var(--color-accent-yellow);
            }
        }
    }
    .queso-collapsible {
        &__header {
            --queso-collapsible-header-justify: flex-start;
            gap: dvar(accordion-compact-header-gap);

            &__text {
                font-size: dvar(accordion-compact-header-font-size);
                font-weight: dvar(accordion-compact-header-font-weight);
                transition: 0.3s color ease;
            }

            &__icon {
                margin-left: inherit;
                .c-icon {
                    transition: 0.3s background-color ease;
                }
            }
        }

        &__content {
            &__inner {
                padding-top: dvar(accordion-compact-content-padding-top);
            }
        }
    }
}
</style>
