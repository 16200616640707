<template>
    <div v-if="selectedVariant" class="c-product-form" :class="{ '-modal': isModal }">
        <div class="c-product-form__header">
            <div class="c-product-form__header__meta">
                <div v-if="displayVendor || $slots.vendor" class="c-product-form__header__vendor">
                    <slot name="vendor">{{ product.vendor }}</slot>
                </div>

                <div v-if="$slots.rating" class="c-product-form__header__rating">
                    <slot name="rating"></slot>
                </div>
            </div>

            <div v-if="displayTitle || $slots.title" class="c-product-form__header__title">
                <slot name="title">
                    <h1 class="t-h4">
                        {{ getCleanProductTitle(product.title) }}
                    </h1>
                </slot>
                <div v-if="styleNumber" class="c-product-form__header__style">#{{ styleNumber }}</div>
            </div>

            <div class="c-product-form__header__price">
                <price-component :variant="selectedVariant"></price-component>
                <sezzle-snippet v-if="displaySezzle" />
            </div>
        </div>

        <div class="c-product-form__variants">
            <div v-if="$slots['before-variants']" class="c-product-form__before-variants">
                <slot name="before-variants"></slot>
            </div>

            <variant-selector
                :current-variant="selectedVariant"
                :product="product"
                :options-with-values="optionsWithValues"
                :allow-unavailable-selection="!isModal"
                @update="(variant) => updateProductVariant(variant)"
            ></variant-selector>

            <div v-if="$slots['after-variants']" class="c-product-form__after-variants">
                <slot name="after-variants"></slot>
            </div>
        </div>

        <div v-if="isInStoreOnly">
            <div class="c-warning" v-html="inStoreOnlyCopy" />
        </div>

        <div v-if="isInStoreOnly">
            <div v-if="$slots.after_actions" class="c-product-form__atc__after-actions">
                <slot name="after_actions" v-bind="{ product, selectedVariant }"></slot>
            </div>
        </div>

        <div v-else class="c-product-form__atc">
            <div class="c-product-form__atc__quantity">
                <quantity-input
                    :label="getDescription('product.quantity')"
                    :min="selectedVariant?.quantity_rule?.min || 1"
                    :max="10"
                    :step="selectedVariant?.quantity_rule?.increment || 1"
                    :model-value="quantityValue"
                    @update:model-value="(newValue) => (quantityValue = newValue)"
                >
                    <template #minus>
                        <slot name="quantity-input-control-minus" />
                    </template>
                    <template #plus>
                        <slot name="quantity-input-control-plus" />
                    </template>
                </quantity-input>
            </div>

            <div class="c-product-form__atc__actions">
                <add-to-cart
                    ref="target"
                    @added-to-cart="(r) => handleAddition(r)"
                    :quantity="quantityValue"
                    :variant="selectedVariant"
                ></add-to-cart>
                <div v-if="$slots.after_actions" class="c-product-form__atc__after-actions">
                    <slot name="after_actions" v-bind="{ product, selectedVariant }"></slot>
                </div>
            </div>
        </div>
        <div v-if="$slots.additional" class="c-product-form__additional">
            <slot name="additional" v-bind="{ product, selectedVariant }"></slot>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, toRefs, watch, onMounted, onUnmounted } from "vue";
import { useAddToCartToast, useSezzle, useTranslationDescriptions } from "@vue-composables";

import { useElementVisibility } from "@vueuse/core";
import { getCleanProductTitle } from "@/utils";

import PriceComponent from "@vue-components/PriceComponent";

// Props
const props = defineProps({
    displayTitle: {
        type: Boolean,
        default: true,
    },
    displayVendor: {
        type: Boolean,
        default: true,
    },
    displaySezzle: {
        type: Boolean,
        default: false,
    },
    currentVariant: {
        type: Object,
        required: true,
    },
    optionsWithValues: {
        type: Array,
        required: true,
    },
    product: {
        type: Object,
        required: true,
    },
    styleNumber: {
        type: String,
        required: false,
    },
    isSelectedEmitable: {
        type: Boolean,
        required: false,
        default: false,
    },
    isModal: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const emit = defineEmits(["addedToCart", "updateVariant"]);

const { getDescription } = useTranslationDescriptions();
const { currentVariant, product, styleNumber } = toRefs(props);

// Refs
const quantityValue = ref(1);
const selectedVariant = ref(null);

const variants = computed(() => product?.value.variants);

// Computed
const isInStoreOnly = computed(() => product.value.tags.includes("---instore"));
const inStoreOnlyCopy = computed(() => getDescription("product.in_store_only"));

////////////////////////////////
//       START PROPS UPDATE
////////////////////////////////
onMounted(() => {
    updateVariant(currentVariant.value);
});

watch(
    () => currentVariant.value,
    () => {
        updateVariant(currentVariant.value);
    }
);
////////////////////////////////
//       END PROPS UPDATE
////////////////////////////////

////////////////////////////////
//       START UPDATE VARIANT
////////////////////////////////

const updateProductVariant = (variant) => {
    props.isSelectedEmitable ? emit("updateVariant", variant) : null;
    updateVariant(variant);
};
const updateVariant = (variant) => {
    selectedVariant.value = variant;
};
////////////////////////////////
//       END UPDATE VARIANT
////////////////////////////////

////////////////////////////////
//       START ADDED TO CART
////////////////////////////////

const handleAddition = (response) => {
    emit("addedToCart", response);
};

////////////////////////////////
//       END ADDED TO CART
////////////////////////////////

const { component: sezzleSnippet } = useSezzle(currentVariant);

////////////////////////////////
//       START TOAST
////////////////////////////////

const isToastNeeded = computed(() => props.isModal === false);

const {
    fill: fillAddToCartToast,
    open: openAddToCartToast,
    close: closeAddToCartToast,
    clear: clearAddToCartToast,
} = useAddToCartToast();

watch(selectedVariant, () => {
    isToastNeeded.value ? fillAddToCartToast(product.value, selectedVariant.value) : clearAddToCartToast();
});

const target = ref(null);
const targetIsVisible = useElementVisibility(target);

watch(targetIsVisible, (isVisible) => {
    isVisible ? closeAddToCartToast() : openAddToCartToast();
});

onUnmounted(() => {
    clearAddToCartToast();
});

////////////////////////////////
//       END TOAST
////////////////////////////////
</script>

<style lang="scss" scoped>
.c-product-form {
    --product-form-left-offset: 0;
    --product-form-actions-justify: flex-end;
    --product-form-atc-direction: row;
    --product-form-atc-display: grid;
    --product-form-gap: var(--grid-gap-1-25X);
    --product-form-header-gap: var(--grid-gap-half);

    @include min(sm) {
        --product-form-header-gap: var(--grid-gap);
    }

    @include min(md) {
        --product-form-left-offset: var(--grid-gap);
    }

    display: grid;
    grid-auto-flow: row;
    gap: var(--product-form-gap);
    padding-left: var(--product-form-left-offset);

    &.-modal {
        --product-form-atc-direction: column;
        --product-form-atc-display: flex;
        --product-form-gap: var(--grid-gap);
        --product-form-header-gap: var(--grid-gap-half);
        --product-form-left-offset: 0;
    }

    &__header {
        @include stack("vertical", "left", "top", $gap: var(--product-form-header-gap));

        &__meta {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            gap: var(--grid-gap);
            width: 100%;
        }

        &__vendor {
            color: var(--color-grey-dark);
            font-weight: 700;
            line-height: 1;
        }

        &__title {
            display: flex;
            flex-direction: column;
            gap: var(--grid-gap-third);
            & > * {
                max-width: 15ch;
            }
        }
        &__style {
            color: var(--color-grey-dark);
            font-size: var(--fs-x-small);
        }

        &__price {
            :deep(.c-price) {
                --price-item-font-color: var(--color-accent-blue);
                --price-item-font-size: var(--fs-x-large);
                --price-item-font-weight: 900;
                --price-item-line-height: 1;

                &.is-on-sale {
                    .c-price__item {
                        &.-regular {
                            --price-item-font-color: var(--color-grey-dark);
                            --price-item-font-size: var(--fs-medium);
                            --price-item-font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    &__variants {
        display: flex;
        flex-direction: column;
        gap: var(--grid-gap);
    }

    &__before-variants,
    &__after-variants {
        &:empty {
            display: none;
        }
    }

    &__atc {
        display: var(--product-form-atc-display);
        grid-auto-flow: var(--product-form-atc-direction);
        gap: var(--product-form-gap);

        &__quantity {
            .c-field {
                display: flex;
                flex-direction: var(--product-form-atc-direction);
            }
        }
        &__actions {
            display: flex;
            justify-content: var(--product-form-actions-justify);
            align-items: flex-end;
            flex: auto;

            flex-direction: column;
            gap: var(--grid-gap);
        }

        &__after-actions {
            width: 100%;
        }
    }
}
</style>
