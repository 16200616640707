<template>
    <icon-base
        class="c-map-base__marker"
        name="localisation-sm"
        :size="2"
        show-bg
        :bg-size="5"
        :bg-color="bgColor"
        show-shadow
    />
</template>

<script setup>
import { computed } from "vue";

import IconBase from "@vue-components/IconBase";

const props = defineProps({
    isActive: Boolean,
});

const bgColor = computed(() => (props.isActive ? "yellow" : "blue"));
</script>

<style lang="scss" scoped>
.c-map-base__marker {
    transition: background-color 0.2s ease;
}
</style>
