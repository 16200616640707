<template>
    <div v-if="data" class="c-card-boutique" :class="{ 'is-boxed': boxed }">
        <icon-base
            v-if="boxed"
            name="localisation-sm"
            :size="2"
            show-bg
            :bg-size="3.5"
            bg-color="white"
            :bg-radius="10"
        />

        <h3 v-if="!hideTitle" class="c-card-boutique__title">
            {{ data?.short_name || data?.name }}
        </h3>

        <p class="c-card-boutique__address">
            {{ data?.location.address.full }}
        </p>

        <p v-if="data?.phone" class="c-card-boutique__phone">
            {{ data?.phone }}
        </p>

        <div class="c-card-boutique__links">
            <button-plain
                v-if="!hidePageLink"
                class="c-card-boutique__page-link"
                :label="getDescription('boutique.more_details')"
                :url="pageUrl"
                tag="a"
            />
            <button-plain
                class="c-card-boutique__map-link"
                :label="getDescription('boutique.view_on_map')"
                tag="button"
                @click="viewOnMap"
            />
            <button-plain
                v-if="!hideDirectionsLink"
                class="c-card-boutique__directions-link"
                :label="getDescription('boutique.directions')"
                :url="directionsUrl"
                tag="a"
                is-external
            />
            <div v-if="data?.distance != null" class="c-card-boutique__distance">{{ data?.distance }}&nbsp;km</div>
        </div>

        <card-boutique-business-hours v-if="!boxed && !hideOpeningHours" :fixed="fixedOpeningHours">
            <template #header>
                {{ getDescription("boutique.business_hours") }}
            </template>
            <template #content>
                <table class="c-table -simple">
                    <tr v-for="(hours, day) in data?.business_hours">
                        <td class="c-card-boutique__business-hours__day">
                            {{ getDescription(`general.weekdays.${day}`) }}
                        </td>
                        <td class="c-card-boutique__business-hours__hours">
                            {{ hours }}
                        </td>
                    </tr>
                </table>
            </template>
        </card-boutique-business-hours>

        <slot name="extra"></slot>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useTranslationDescriptions } from "@vue-composables";
import { handleize } from "@utils";

import CardBoutiqueBusinessHours from "./components/CardBoutiqueBusinessHours";
import IconBase from "@vue-components/IconBase";
import ButtonPlain from "@vue-components/ButtonPlain";

const { getDescription } = useTranslationDescriptions();

const props = defineProps({
    data: {
        type: Object,
        required: false,
        default: () => {},
    },
    boxed: {
        type: Boolean,
        required: false,
        default: false,
    },
    hideTitle: {
        type: Boolean,
        required: false,
        default: false,
    },
    hidePageLink: {
        type: Boolean,
        required: false,
        default: false,
    },
    hideDirectionsLink: {
        type: Boolean,
        required: false,
        default: false,
    },
    hideOpeningHours: {
        type: Boolean,
        required: false,
        default: false,
    },
    fixedOpeningHours: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const emits = defineEmits(["boutique:click"]);

const boutiqueHandle = computed(() => handleize(props.data?.short_name));
const pageUrl = computed(() => `/pages/boutiques/${boutiqueHandle.value}`);
const directionsUrl = computed(
    () =>
        `https://www.google.com/maps?saddr=My+Location&daddr=${props.data?.location.position.lat},${props.data?.location.position.lng}`
);

const viewOnMap = () => {
    emits("boutique:click", props.data);
};
</script>

<style lang="scss" scoped>
// src/assets/styles/08.components/_card-boutique.scss
</style>
