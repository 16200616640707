// https://learnersbucket.com/examples/javascript/how-to-format-phone-number-in-javascript/

const formatPhoneNumber = (str) => {
    // Filter only numbers from the input
    const cleaned = ("" + str).replace(/\D/g, "");

    // Check if the input is of correct
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        //Remove the matched extension code
        //Change this to format for any country code.
        let intlCode = match[1] ? "1 " : "";
        return [intlCode, "", match[2], " ", match[3], "-", match[4]].join("");
    }

    return null;
};

export default formatPhoneNumber;
