import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { fetchConfig } from "@/utils";
import { useModalsStore } from "@vue-stores";

const useCartStore = defineStore("cart", {
    state: () => ({
        attributes: useStorage("cart.attributes", {}),
        cart_level_discount_applications: useStorage(
            "cart.cart_level_discount_applications",
            []
        ),
        currency: useStorage("cart.currency", ""),
        item_count: useStorage("cart.item_count", 0),
        items: useStorage("cart.items", []),
        items_subtotal_price: useStorage("cart.items_subtotal_price", 0),
        note: useStorage("cart.note", null),
        original_total_price: useStorage("cart.original_total_price", 0),
        requires_shipping: useStorage("cart.requires_shipping", false),
        token: useStorage("cartToken", null),
        total_discount: useStorage("cart.total_discount", 0),
        total_price: useStorage("cart.total_price", 0),
        total_weight: useStorage("cart.total_weight", 0),
        cart_errors: [],
        cart_loading_state: false,
    }),
    actions: {
        clearCart() {
            const config = fetchConfig("json");

            fetch(`${theme.routes.cart_clear_url}`, config)
                .then((response) => response.json())
                .then((cart) => {
                    this.$patch(cart);
                });
        },
        async getCart() {
            const cart = await this.handleFetch(
                theme.routes.cart_url,
                null,
                false
            );

            // Refresh cart
            this.$patch(cart);

            return cart;
        },
        async addLine(line) {
            const lines = [line];

            return await this.addLines(lines);
        },
        async addLines(lines) {
            if (!Array.isArray(lines)) {
                console.error("Please submit an array to `addLines()` method.");
                return;
            }

            const modals = useModalsStore();
            const items = { items: lines };

            try {
                await this.handleFetch(theme.routes.cart_add_url, items);
            } catch (e) {
                await this.handleCartErrors(e);
            }

            // Refresh the cart
            await this.getCart(true);

            // Then open the cart modal
            modals.openModal("cart-modal");

            return this.formattedResponse();
        },
        removeLine(lineKey) {
            if (!lineKey) {
                console.error(
                    "You must submit a line key to `removeLine()` method."
                );
                return;
            }

            this.updateLineQuantity(lineKey, 0);

            return this.formattedResponse();
        },
        async updateLineQuantity(lineKey, quantity = 1) {
            if (!lineKey) {
                console.error(
                    "You must submit a line key and quantity to `updateLineQuantity()` method."
                );
                return;
            }

            this.updateCartLoadingState(true);

            const item = {
                id: lineKey,
                quantity: quantity,
            };

            try {
                await this.handleFetch(theme.routes.cart_change_url, item);
            } catch (e) {
                this.handleCartErrors(e, item);
            }

            // Get cart anyway
            await this.getCart();
            this.updateCartLoadingState(false);

            return this.formattedResponse();
        },
        async handleFetch(route, body, resetErrors = true) {
            const config = fetchConfig("json");

            if (body) {
                config.body = JSON.stringify(body);
            }

            const response = await fetch(`${route}`, config);
            const json = await response.json();

            if (resetErrors) {
                this.resetErrors();
            }

            if (json?.errors) {
                throw json.errors;
            }

            return json;
        },
        async handleCartErrors(errors, lineItem = null) {
            let newError = {
                // TODO: i18n
                message: `Une erreur est survenue. Il se peut que le produit ne soit pas disponible. (${errors})`,
            };

            if (lineItem) {
                newError = {
                    lineItemId: lineItem.id,
                    message: errors,
                };
            }

            this.cart_errors.push(newError);

            console.error(errors);
            return this.cart_errors;
        },
        updateCartLoadingState(bool = false) {
            this.cart_loading_state = bool;
        },
        resetErrors() {
            this.cart_errors = [];
        },
        formattedResponse() {
            return {
                cart: this,
                hasErrors: this.cart_errors.length,
            };
        },
    },
});

export default useCartStore;
