<template>
    <button class="c-cart-button" @click="openModal">
        <span v-if="cart.item_count > 0" class="c-cart-button__item-count" v-html="cart.item_count"></span>
        <slot :cart="cart"></slot>
    </button>
</template>

<script setup>
import { useCartStore, useModalsStore } from "@vue-stores";

const cart = useCartStore();
const modals = useModalsStore();

const openModal = () => {
    modals.openModal("cart-modal");
};
</script>

<style lang="scss" scoped>
.c-cart-button {
    position: relative;

    // TODO Will change with prototype
    &__item-count {
        @include unselectable;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.8rem;
        height: 1.8rem;
        color: var(--color-white);
        font-size: var(--fs-xx-small);
        font-weight: 700;
        position: absolute;
        top: -0.1rem;
        right: -0.3rem;
        z-index: 2;
        background-color: var(--color-accent-blue);
        border-radius: 100%;
    }
}
</style>
