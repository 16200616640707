<template>
    <div class="c-brands-showcase-compositions-mobile">
        <div
            ref="firstGroup"
            class="c-brands-showcase-compositions-mobile__group -first"
            :style="{ 'margin-left': `-${negativeMargin}px` }"
        >
            <slot></slot>
        </div>
        <div class="c-brands-showcase-compositions-mobile__group -second">
            <slot></slot>
        </div>
        <div v-if="count < 3" class="c-brands-showcase-compositions-mobile__group -third">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useElementBounding } from "@vueuse/core";

const props = defineProps({
    count: {
        type: Number,
        required: true,
    },
});

const firstGroup = ref(null);
const { width: firstGroupWidth } = useElementBounding(firstGroup);

const marqueeSpeed = 0.4;
const negativeMargin = ref(0);

onMounted(() => {
    setInterval(() => {
        negativeMargin.value = negativeMargin.value > firstGroupWidth.value ? 0 : negativeMargin.value + marqueeSpeed;
    }, 0);
});
</script>

<style lang="scss" scoped>
.c-brands-showcase-compositions-mobile {
    display: flex;
    align-items: flex-end;

    &__group {
        display: flex;
        align-items: flex-end;
        gap: dvar(container-gutter);
        padding-left: dvar(container-gutter);
    }

    @include between(md, lg) {
        --brand-composition-width-default: 40rem;
    }

    @include between(lg, xl) {
        --brand-composition-width-default: 48rem;
    }

    @include min(lg) {
        --brand-composition-width-default: 68rem;
    }
}
</style>
