<template>
    <modal-base type="popup" :name="modalName" @onCloseAnimationCompleted="clearModal" class="c-modal-quick-view">
        <div class="c-modal-quick-view__inner">
            <!-- left column -->
            <div v-if="productData.data?.product" class="c-modal-quick-view__images">
                <modal-quick-view-image
                    :is-selected-emitable="false"
                    :medias="productData.data.product.media"
                ></modal-quick-view-image>
            </div>

            <!-- right column -->
            <div class="c-modal-quick-view__body">
                <product-form
                    v-if="productData.data?.current_variant"
                    :current-variant="productData.data.current_variant"
                    :display-vendor="true"
                    :is-selected-emitable="false"
                    :is-modal="true"
                    :style-number="productData.data?.metafields.style"
                    :options-with-values="productData.data.options_with_values"
                    :product="productData.data.product"
                    @added-to-cart="handleAddition"
                >
                    <template #title>
                        <h1 class="t-h6">
                            {{ getCleanProductTitle(productData.data?.product?.title, " - ", true) }}
                        </h1>
                    </template>

                    <!-- Evaluate if this is necessary. Two modals open in top of each other is pretty weird. -->
                    <!-- <template #after-variants>
                        <size-guide-button>-</size-guide-button>
                    </template> -->

                    <template #after_actions>
                        <base-button
                            tag="a"
                            :href="redirectURL"
                            @click="userClicked"
                            class="c-button is-outline is-full"
                        >
                            <template #label> Tous les détails du produit </template>
                        </base-button>
                    </template>
                </product-form>
            </div>
        </div>
    </modal-base>
</template>
<script setup>
import { computed, onBeforeMount } from "vue";

import { useModalsStore } from "@vue-stores";
import { getCleanProductTitle } from "@/utils";

import BaseButton from "@vue-components/BaseButton";
import ModalBase from "@vue-components/ModalBase";
import ModalQuickViewImage from "./components/ModalQuickViewImage";
import ProductForm from "@vue-components/ProductForm";

////////////////////////////////
//       START INIT MODAL
////////////////////////////////
// Register modal
const modals = useModalsStore();
const modalName = "quick-view-modal";

const productData = computed(() => modals.getModalByName(modalName));

onBeforeMount(() => {
    modals.registerModal({
        component: "QuickViewModal",
        name: modalName,
    });
});

////////////////////////////////
//       END INIT MODAL
////////////////////////////////

////////////////////////////////
//       START REDIRECTION
////////////////////////////////
const redirectURL = computed(() => productData.value.data.productURL);
const userClicked = () => {
    // Set redirect URL
    //const redirectURL = productData.value.data.productURL;

    // Close modal
    closeModal();

    // Redirect
    //window.location.href = redirectURL;
};

////////////////////////////////
//       END REDIRECTION
////////////////////////////////

const handleAddition = () => {
    closeModal();
};

const closeModal = () => {
    modals.closeModal(modalName);
};

const clearModal = () => {
    // // clear store
    modals.clearModalByName(modalName);
};
</script>

<style lang="scss">
.c-modal-quick-view {
    --modal-popup-height: auto;
    @include min(md) {
        --modal-popup-min-height: 60rem;
    }
}
</style>

<style lang="scss" scoped>
.c-modal-quick-view {
    &__inner {
        display: flex;
        flex-direction: horizontal;
        justify-content: flex-start;
        align-items: flex-start;
        gap: var(--grid-gap);
    }

    &__images {
        width: 44.5rem;
        max-width: 50%; // avoid horizontal jerks while the children setting up
    }

    &__body {
        flex: 1;

        :deep(.c-product-form) {
            width: 100%;
            padding-top: calc(
                2.5rem + 3rem
            ); // close button is absolute, so I need to do some adjustements to it fits well below the bottom like in the mockup
        }
    }
}
</style>
