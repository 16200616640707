/**
 *
 * This composable do not use Sezzle widget/@app block, but a custom rendering based on the `variant` submitted.
 * We cannot use standard Sezzle integration because of the impossibility to render JS inside Vue apps
 * and because Sezzle widget render the content through JS and not in plain HTML.
 *
 * Other we could have use Shopify Section Render API easily...
 *
 */
import { computed, h } from "vue";
import { moneyFormat } from "@utils";

import IconBase from "@vue-components/IconBase";

export function useSezzle(variant, options = {}) {
    //
    const defaultOptions = {
        fallbackcopy: `ou {{ count }} paiements sans intérêt sur les commandes de plus de 20$ avec`,
        copy: `ou {{ count }} paiements de <strong>{{ amount }}</strong> sans intérêt avec`,
        paymentCount: 4,
    };

    const mergedOptions = { ...options, ...defaultOptions };

    // Computed
    const paymentCount = computed(() => mergedOptions.paymentCount);
    const price = computed(() => variant.value.price);
    const paymentAmount = computed(() => price.value / paymentCount.value);
    const formattedPaymentAmount = computed(() => moneyFormat(paymentAmount.value));

    const x = computed(() => (price.value >= 2000 ? mergedOptions.copy : mergedOptions.fallbackcopy));

    const copy = computed(() =>
        x.value.replace("{{ count }}", paymentCount.value).replace("{{ amount }}", formattedPaymentAmount.value)
    );

    // Renders
    const logo = h(IconBase, { name: "sezzle" });
    const content = h("span", { innerHTML: copy.value });
    const component = h("div", { class: "c-sezzle" }, [content, logo]);

    return { component, paymentAmount, price, formattedPaymentAmount, paymentCount };
}
