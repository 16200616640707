import { defineStore } from "pinia";
import { toastRequiredSettingsError } from "@/errors";
import { log } from "@/utils";

/*

    Usage:

    import { useToastsStore } from "@vue-stores";
    const toasts = useToastsStore();

    toasts.registerToast({
        autoClose: false,
        component: "ToastDefault",
        data: null,
        isOpen: true,
        isClosable: true,
        name: "default-toast",
    });

*/

// Define default settings as fallback
const defaultToastSettings = {
    autoClose: false,
    component: "ToastDefault",
    data: {},
    isOpen: true,
    isClosable: true,
};

const useToastsStore = defineStore("toasts", {
    state: () => ({
        elements: [],
    }),
    getters: {
        getToastsCount: (state) => state.elements.length,
        getOpennedToastsCount: (getters) => getters.getOpennedToasts.length,
        getToastByName: (state) => {
            return (name) => state.elements.find((toast) => toast.name === name);
        },
        getToastIndexByName: (state) => {
            return (name) => state.elements.findIndex((toast) => toast.name === name);
        },
        getOpennedToasts: (state) => state.elements.filter((toast) => toast.isOpen === true),
        getNotExpiredToasts: (getters) =>
            getters.getOpennedToasts.filter((toast) => {
                // If there’s no date_begin or date_end, return true
                if (toast.data.date_begin == null || toast.data.date_end == null) {
                    return true;
                }

                // Get timestamps
                const date_begin = toast.data.date_begin.getTime();
                const date_end = toast.data.date_end.getTime();

                // If the dates are not valid, return false
                if (isNaN(date_begin) || isNaN(date_end)) {
                    return false;
                }

                // If the end date is before the begin date, return false
                if (date_end < date_begin) {
                    return false;
                }

                // Compare both begin and end dates to now, if the toast is not expired, return true
                return date_begin < Date.now() && date_end > Date.now();
            }),
        getValidToasts: (getters) => getters.getOpennedToasts.filter((toast) => toast.data),
    },
    actions: {
        ////////////////////////////////
        //       START REGISTRATION
        ////////////////////////////////
        registerToast(settings) {
            // Check if required settings are available
            if (!settings.hasOwnProperty("name")) {
                throw toastRequiredSettingsError;
                return;
            }

            // Check if there’s already a toast with that name. If so, throw an error.
            if (this.getToastByName(settings.name)) {
                log(
                    `A toast name must be unique, ${settings.name} is already registred so it won\`t be registred again.`
                );
                return;
            }

            // Merge settings with default value
            settings = { ...defaultToastSettings, ...settings };

            // Add valid toast object to the list
            this.elements.push(settings);
        },
        eraseToast(toastName) {
            const toastIndexToDelete = this.getToastIndexByName(toastName);
            if (!toastIndexToDelete) {
                log(`A toast with the name ${toastName} doesn't exist. Can't erase it.`);
                return;
            } else {
                this.elements.splice(toastIndexToDelete, 1);
            }
        },
        ////////////////////////////////
        //       END REGISTRATION
        ////////////////////////////////

        ////////////////////////////////
        //       START OPEN AND CLOSE
        ////////////////////////////////
        openToast(name) {
            const toast = this.getToastByName(name);

            // Skip if there’s no toast using that name
            if (!toast) return;

            // Open if not already open
            if (toast.isOpen === false) {
                toast.isOpen = true;
            }
        },
        closeToast(name) {
            const toast = this.getToastByName(name);

            // Skip if there’s no toast using that name
            if (!toast) return;

            if (toast && toast.isOpen === true) {
                toast.isOpen = false;
            }
        },
        closeAllToasts() {
            const opennedToasts = this.getOpennedToasts;

            if (opennedToasts.length) {
                opennedToasts.forEach((toast) => this.closeToast(toast.name));
            }
        },
        ////////////////////////////////
        //       END OPEN AND CLOSE
        ////////////////////////////////

        ////////////////////////////////
        //       START UPDATE CURRENT MODAL
        ////////////////////////////////

        //======= START FILL MODAL =======//

        fillToastDataByName(name, data) {
            // find the current toast
            const toast = this.getToastByName(name);
            // fill up data in the toast
            toast ? this.fillToastData(toast, data) : null;
        },

        fillToastData(toast, data) {
            toast.data = data;
        },

        //======= END FILL MODAL =======//

        clearToastDataByName(toastName) {
            // find the current toast
            const toast = this.getToastByName(toastName);
            // clear
            toast ? this.clearToastData(toast) : null;
        },
        clearToastData(toast) {
            toast.data = null;
        },

        ////////////////////////////////
        //       END UPDATE CURRENT MODAL
        ////////////////////////////////
    },
});

export default useToastsStore;
