<template>
    <carousel-base class="c-carousel-bleed" :hasArrow="hasArrow" :options="optionsBleeMode">
        <slot></slot>

        <template #arrowPrev>
            <slot name="prev">
                <button class="c-carousel-base__arrow -prev">‹</button>
            </slot>
        </template>
        <template #arrowNext>
            <slot name="next">
                <button class="c-carousel-base__arrow -next">›</button>
            </slot>
        </template>
    </carousel-base>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    options: {
        type: Object,
        required: false,
        default: () => {},
    },
    hasArrow: {
        type: Boolean,
        default: true,
    },
});

const optionsBleeModeDefault = {
    panelsPerView: 1,
    moveType: "freeScroll",
    bound: true,
    align: "prev",
};
const optionsBleeMode = computed(() => ({ ...optionsBleeModeDefault, ...props.options }));
</script>

<style lang="scss">
.c-carousel-bleed {
    --carousel-base-margin-right: var(--grid-gap-half);

    @include min(md) {
        --carousel-base-margin-right: var(--grid-gap);
        --carousel-base-margin-right: var(--grid-gap);
    }

    @include max(md) {
        margin-left: calc(0px - dvar(container-gutter));
        padding-left: dvar(container-gutter);
        margin-right: calc(0px - dvar(container-gutter));
        padding-right: dvar(container-gutter);
    }

    @include min(md) {
        // Calculate medium container offset based on its specific width
        // TODO: Use $containers.medium value
        // TODO: Use flexible(CSS variable/Vue prop) container value
        --oversize-offset: calc((100vw - 1147px) / 2);
        --undersize-offset: var(--container-gutter, var(--container-gutter-default));

        margin-right: calc(0px - max(var(--undersize-offset), var(--oversize-offset)));
        padding-right: max(var(--undersize-offset), var(--oversize-offset));
    }
}
</style>
