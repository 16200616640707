<template>
    <ul class="c-nav-grand-children">
        <template v-for="(item, index) in links" :key="`nav-grand-child--${index}`">
            <li v-if="item?.object?.all_products_count > 0" class="c-nav-grand-children__item">
                <a
                    :aria-label="`Explorer ${item?.object?.title || item.title}`"
                    :title="`Explorer ${item?.object?.title || item.title}`"
                    :href="item.url"
                    :ref="`navigationGrandChildren${index}`"
                    class="c-nav-grand-children__item__link | navigation-button-hover navigation-button-active"
                >
                    <div v-if="item.object?.featured_image && isMobile" class="c-nav-grand-children__item__link__media">
                        <img
                            :src="`${cropImage(item.object.featured_image, imageWidth, imageWidth)}`"
                            :srcset="`${cropImage(item.object.featured_image, imageWidth, imageWidth)} 1x, ${cropImage(
                                item.object.featured_image,
                                imageWidth2x,
                                imageWidth2x
                            )} 2x`"
                            :alt="item.object"
                            loading="lazy"
                        />
                    </div>

                    <span class="c-nav-grand-children__item__link__label">
                        {{ item.title }}
                        <!-- <the-navigation-tags
                            v-if="item.object?.all_tags && item.object?.all_tags.length"
                            :tags="item.object.all_tags"
                        ></the-navigation-tags> -->
                    </span>

                    <icon-base v-if="isMobile" name="chevron-sm" :size="2" />
                </a>
            </li>
        </template>
    </ul>
</template>

<script setup>
import { useMediaQuery } from "@vueuse/core";
import { cropImage } from "@utils";

import IconBase from "@vue-components/IconBase";
import TheNavigationTags from "../TheNavigationTags";

const props = defineProps({
    links: {
        type: Array,
        required: false,
        default: () => [],
    },
});

const isMobile = useMediaQuery("(max-width: 767px)");
const imageWidth = 60;
const imageWidth2x = imageWidth * 2;
</script>

<style lang="scss" scoped>
.c-nav-grand-children {
    --nav-grand-children-image-size: calc(v-bind("imageWidth") * 0.1rem);
    @include stack("vertical", "stretch", $var-prefix: "nav-grand-children");

    @include max("md") {
        --nav-grand-children-gap: var(--grid-gap-half);
    }

    &__item {
        &__link {
            --nav-grand-children-link-width: 100%;
            @include stack(
                "horizontal",
                "space-between",
                "center",
                $wrap: false,
                $var-prefix: "nav-grand-children-link"
            );
            width: var(--nav-grand-children-link-width);

            @include min(md) {
                --nav-grand-children-link-width: fit-content;
            }

            &__media {
                width: var(--nav-grand-children-image-size);
                height: var(--nav-grand-children-image-size);
                aspect-ratio: 1;
                position: relative;
                background-color: var(--color-grey-light);
                border-radius: var(--radius-small);
                overflow: hidden;

                img {
                    @include object-fit;
                    display: block;
                }
            }

            &__label {
                flex: 1;

                @include max(md) {
                    font-weight: bold;
                }
            }
        }
    }
}
</style>
