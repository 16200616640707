<template>
    <component v-if="label" :is="tag" class="c-navigation-arrow-link" :class="{ 'navigation-button-hover': canHover }">
        <span class="c-navigation-arrow-link__label" v-html="label"></span>
        <icon-base class="c-navigation-arrow-link__arrow" name="arrow-thick" :size="2" />
    </component>
</template>

<script setup>
import { computed } from "vue";

import IconBase from "@vue-components/IconBase";

const props = defineProps({
    tag: {
        type: String,
        required: false,
        default: "div",
        validator: (tagName) => ["div", "button", "a"].includes(tagName),
    },
    label: {
        type: String,
        required: true,
        default: null,
    },
    canHover: {
        type: Boolean,
        required: false,
        default: false,
    },
});

// Remove asterix
const label = computed(() => props.label.replace("*", ""));
</script>

<style lang="scss" scoped>
.c-navigation-arrow-link {
    --icon-width: 0.6em;
    @include stack(
        "horizontal",
        "left",
        $gap: var(--grid-gap-half),
        $wrap: false,
        $var-prefix: "navigation-arrow-link"
    );

    font-size: 2rem;
    font-family: var(--ff-heading);
    line-height: 1;
    font-weight: 900;

    &__arrow {
        transform: translateY(14%);
    }
}
</style>
