<template>
    <div class="c-mobile-category-parent-item" :class="{ 'is-sub-nav-open': isOpen }">
        <the-navigation-card :item="category" @click.prevent="openSubMenu"></the-navigation-card>

        <the-navigation-sub-menu :links="category.links">
            <template #before>
                <button class="c-mobile-category-parent-item__back" @click="closeSubMenu">
                    <icon-base name="chevron-sm" :size="1.8" :rotation="180" show-bg :bg-size="2" />
                    <span class="c-mobile-category-parent-item__back__label"> Retour </span>
                </button>
            </template>
        </the-navigation-sub-menu>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useBarbaHook } from "@vue-composables";

import TheNavigationCard from "@vue-components/TheNavigation/components/TheNavigationCard";
import TheNavigationSubMenu from "@vue-components/TheNavigation/components/TheNavigationSubMenu";
import IconBase from "@vue-components/IconBase";

const props = defineProps({
    category: {
        type: Object,
        required: false,
        default: () => {},
    },
});

const isOpen = ref(false);

////////////////////////////////
//       START OPEN MENU
////////////////////////////////
const openSubMenu = () => {
    toggleSubMenu(true);
};

const toggleSubMenu = (bool) => {
    isOpen.value = bool;
};

////////////////////////////////
//       END OPEN MENU
////////////////////////////////

////////////////////////////////
//       START CLOSE SUB MENU
////////////////////////////////
const closeSubMenu = () => {
    toggleSubMenu(false);
};

// Close mobile submenu on page change
useBarbaHook(() => {
    closeSubMenu();
}, "beforeEnter");
////////////////////////////////
//       END CLOSE SUB MENU
////////////////////////////////
</script>

<style lang="scss" scoped>
.c-mobile-category-parent-item {
    --submenu-translate-x: 1rem;
    &.is-sub-nav-open {
        --submenu-opacity: 1;
        --submenu-translate-x: 0;
        --submenu-pointer-events: auto;
    }

    &__back {
        @include stack("horizontal", "left", $gap: var(--grid-gap-half), $var-prefix: "mobile-submenu-back");

        &__label {
            color: var(--color-grey-dark);
            font-size: var(--fs-small);
            font-weight: 600;
        }
    }
}
</style>
