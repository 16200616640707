<template>
    <div class="c-brands-showcase-compositions-desktop">
        <slot></slot>
    </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.c-brands-showcase-compositions-desktop {
    display: flex;
    align-items: flex-end;
    gap: dvar(container-gutter);
    height: 100%;
    position: relative;

    :deep(.c-brands-showcase__compositions__item) {
        --brand-composition-width: 100%;
        @include unselectable;
        opacity: var(--composition-opacity, 0);

        &:not(:first-child) {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &.is-active {
            --composition-opacity: 1;
            @include unselectable(false);
        }
    }
}
</style>
