<template>
    <div class="c-navigation-submenu">
        <div v-if="$slots.before" class="c-navigation-submenu__before">
            <slot name="before"></slot>
        </div>

        <div ref="submenuInner" class="c-navigation-submenu__inner">
            <the-navigation-children :links="subMenuList"></the-navigation-children>

            <the-navigation-featured v-if="featuredListLength > 0" :features="featuredList"></the-navigation-featured>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, toRefs } from "vue";
import { useBarbaHook } from "@vue-composables";

import TheNavigationChildren from "../TheNavigationChildren";
import TheNavigationFeatured from "../TheNavigationFeatured";

const props = defineProps({
    links: {
        type: Array,
        required: false,
        default: () => [],
    },
});

const submenuInner = ref(null);

// Always reset scroll on page change
useBarbaHook(() => {
    submenuInner.value.scrollTop = 0;
}, "beforeEnter");

////////////////////////////////
//       START FILTER LIST ITEM VS FEATURES
////////////////////////////////
const { links } = toRefs(props);

// alternatively it can be one list
const subMenuList = links.value.filter((link) => !link.title.includes("*"));
const featuredList = computed(() => {
    const featuredLinks = links.value.filter(({ title }) => title.includes("*"));
    return featuredLinks.slice(0, 2);
});
const featuredListLength = computed(() => featuredList.value.length);

////////////////////////////////
//       END FILTER LIST ITEM VS FEATURES
////////////////////////////////
</script>

<style lang="scss" scoped>
.c-navigation-submenu {
    --submenu-max-height: 100%;
    --submenu-top: 0;
    --submenu-gap: var(--grid-gap);
    --submenu-padding-top: var(--grid-gap-3X);
    --submenu-padding-bottom: var(--grid-gap-1-75X);

    --submenu-featured-count: v-bind("featuredListLength");
    --submenu-base-columns: 6;
    --submenu-columns: calc(var(--submenu-base-columns) - var(--submenu-featured-count));

    @include navigation-radius;
    width: 100%;
    height: var(--submenu-max-height);
    position: absolute;
    top: var(--submenu-top);
    left: 0;
    right: 0;
    z-index: calc(var(--z-header) - 1);
    pointer-events: var(--submenu-pointer-events, none);
    opacity: var(--submenu-opacity, 0);
    transform: translate(var(--submenu-translate-x, 0), var(--submenu-translate-y, 0));
    border-top: 0.1rem solid var(--color-grey-light);
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;

    @include min(lg) {
        --submenu-gap: var(--grid-gap-1-5X);
        --submenu-max-height: calc(100vh - var(--header-height) - var(--grid-gap));
        --submenu-top: calc(var(--header-height) - 0.1rem);
        --submenu-padding-top: var(--grid-gap-2X);
        max-height: var(--submenu-max-height);
        height: auto;
    }

    &__inner {
        --container-width: 1920px; // Wannabe fix - There's a container higher in header who replace this one
        @include hide-scrollbar;
        @include container;
        @include stack(
            "vertical",
            "stretch",
            "top",
            $wrap: false,
            $gap: var(--submenu-gap),
            $var-prefix: "navigation-submenu"
        );
        max-height: var(--submenu-max-height);
        padding-top: var(--submenu-padding-top);
        padding-bottom: var(--submenu-padding-bottom);
        overflow-y: scroll;

        @include min(lg) {
            --navigation-submenu-direction: row;
            --navigation-submenu-justify: space-between;
        }
    }

    &__before {
        display: flex;
        height: var(--submenu-padding-top);
        position: absolute;
        top: 0;
        left: dvar(container-gutter);
        right: dvar(container-gutter);
        z-index: 3;
        background: linear-gradient(white, white 70%, rgba(white, 0));
    }
}
</style>
