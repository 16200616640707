<template>
    <div class="c-navigation-children">
        <template v-if="grandChildrenList.length">
            <div
                v-for="(item, index) in grandChildrenList"
                :key="`navigation-children-${index}`"
                class="c-navigation-children__list"
            >
                <div class="c-navigation-children__list__item">
                    <the-navigation-arrow-link
                        :ref="`navigationChildren${index}`"
                        tag="a"
                        :label="item.title"
                        :aria-label="`Explorer ${item?.object?.title || item.title}`"
                        :title="`Explorer ${item?.object?.title || item.title}`"
                        :href="item.url"
                        can-hover
                    />

                    <the-navigation-grand-children
                        v-if="item.links.length"
                        :links="item.links"
                    ></the-navigation-grand-children>
                </div>
            </div>
        </template>

        <div v-if="singleChildList.length" class="c-navigation-children__list">
            <div
                v-for="(item, index) in singleChildList"
                :key="`navigation-children-single-${index}`"
                class="c-navigation-children__list__item"
            >
                <the-navigation-arrow-link
                    tag="a"
                    :label="item.title"
                    :aria-label="`Explorer ${item?.object?.title || item.title}`"
                    :title="`Explorer ${item?.object?.title || item.title}`"
                    :href="item.url"
                    can-hover
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from "vue";

import TheNavigationGrandChildren from "../TheNavigationGrandChildren";
import TheNavigationArrowLink from "@vue-components/TheNavigation/components/TheNavigationArrowLink";

const props = defineProps({
    links: {
        type: Array,
        required: false,
        default: () => [],
    },
});

////////////////////////////////
//       START FILTER LIST
////////////////////////////////

const grandChildrenList = props.links.filter((item) => item.links.length);
const singleChildList = props.links.filter((item) => !item.links.length);

////////////////////////////////
//       END FILTER LIST
////////////////////////////////

////////////////////////////////
//       START focus
////////////////////////////////
// const navigationChildren0 = ref(null);

// const setFirstGrandChildFocus = () => {
//     navigationChildren0.value ? nextTick() : null;
// };

// nextTick(() => {
//     navigationChildren0.value ? navigationChildren0.value[0].focus() : null;
// });

// onMounted(() => {
//     setFirstGrandChildFocus();
// });
////////////////////////////////
//       END focus
////////////////////////////////
</script>

<style lang="scss" scoped>
.c-navigation-children {
    $self: &;
    --nav-children-gap: var(--grid-gap);
    @include stack("vertical", "stretch", "top", $wrap: false, $var-prefix: "nav-children");
    flex: 1;

    @include min("md") {
        --nav-children-align: flex-start;
        --nav-children-gap: var(--grid-gap-1-5X);
        display: grid;
        grid-template-columns: repeat(var(--submenu-columns), 1fr);
        gap: var(--nav-children-gap);
        padding-bottom: var(--grid-gap);
    }

    @include between(sm, lg) {
        --submenu-columns: 2;
    }

    @include between(lg, xl) {
        --submenu-columns: 3;
    }

    &__list {
        @include stack("vertical", $gap: var(--nav-children-gap), $var-prefix: "nav-children-list");

        &__item {
            --nav-children-gap-item: var(--grid-gap-half);
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: var(--nav-children-gap-item);

            @include min("md") {
                --nav-children-gap-item: var(--grid-gap-1-5X);
            }

            @include max("md") {
                --navigation-arrow-link-justify: space-between;

                &:before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1px;
                    margin-bottom: var(--nav-children-gap-item);
                    background-color: var(--color-grey-light);
                }
            }

            @include between(md, xl) {
                --nav-children-gap-item: var(--grid-gap-two-third);
                --nav-grand-children-gap: var(--grid-gap-two-third);

                .c-navigation-arrow-link {
                    font-size: var(--fs-regular);
                }

                :deep(.c-nav-grand-children__item__link) {
                    font-size: var(--fs-small);
                }
            }
        }
    }
}
</style>
