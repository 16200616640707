const ON_CHANGE_DEBOUNCE_TIMER = 400;

const PUB_SUB_EVENTS = {
    cartUpdate: "cart-update",
    quantityUpdate: "quantity-update",
    variantChange: "variant-change",
};

const ANIMATION_DURATIONS = Object.freeze({
    popup: {
        in: 0.3,
        out: 0.2,
    },
    drawer: {
        in: 0.5,
        out: 0.4,
    },
    toast: {
        in: 0.3,
        out: 0.2,
    },
});

export { ON_CHANGE_DEBOUNCE_TIMER, PUB_SUB_EVENTS, ANIMATION_DURATIONS };
