<template>
    <carousel-base
        class="c-carousel-free-mode"
        :class="{ 'has-negative-offset': withNegativeOffset }"
        :options="optionsFreeMode"
    >
        <slot></slot>

        <template #arrowPrev>
            <slot name="arrowPrev"></slot>
        </template>
        <template #arrowNext>
            <slot name="arrowNext"></slot>
        </template>
    </carousel-base>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    options: {
        type: Object,
        required: false,
        default: () => {},
    },
    withNegativeOffset: {
        type: Boolean,
        required: false,
        default: true,
    },
});

const optionsFreeModeDefault = { bound: true, panelsPerView: -1, moveType: "freeScroll", align: "prev" };
const optionsFreeMode = computed(() => ({ ...optionsFreeModeDefault, ...props.options }));
</script>

<style lang="scss">
.c-carousel-free-mode {
    --carousel-base-slide-width: auto;
    --carousel-base-margin-right: var(--grid-gap-half);

    @include min(md) {
        --carousel-base-margin-right: var(--grid-gap);
    }

    &.has-negative-offset {
        @include negative-container-offset(true);
    }

    // Specific cards type
    .c-card-product {
        --card-product-max-width: calc(100vw - var(container-gutter, var(--container-gutter-default)) * 3);

        @include max(xl) {
            --card-product-width: 34rem;
        }
    }
}
</style>
