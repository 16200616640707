<template>
    <Teleport to="body">
        <TransitionGroup name="list" tag="div" class="c-toasts-manager">
            <component
                v-for="toast in getNotExpiredToasts"
                :is="toast.component"
                :name="toast.name"
                v-bind="toast.data"
                :key="toast"
            />
        </TransitionGroup>
    </Teleport>
</template>

<script setup>
import { onBeforeMount, toRefs } from "vue";
import { storeToRefs } from "pinia";

import { useToastsStore } from "@vue-stores";

import { convertStringToDate } from "@/utils";

const props = defineProps({
    toasts: {
        type: Array,
        default: [],
    },
});

const { toasts } = toRefs(props);
const toastStore = useToastsStore();

const { getNotExpiredToasts } = storeToRefs(toastStore);

onBeforeMount(() => {
    // Register submitted notifications
    if (toasts.value.length) {
        toasts.value.forEach((toast) => {
            // Escape 'empty' content here since Liquid is a little be lazy on that part.
            if (toast.body == "" || toast.body == " ") return;

            const date_begin = convertStringToDate(toast.date_begin);
            const date_end = convertStringToDate(toast.date_end);

            toastStore.registerToast({
                name: toast.id,
                data: {
                    icon: toast.icon,
                    body: toast.body,
                    date_begin,
                    date_end,
                },
            });
        });
    }
});
</script>

<style lang="scss">
.c-toasts-manager {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: dvar(container-gutter);
    z-index: calc(var(--z-header) - 2);

    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--grid-gap-three-quarter);
    pointer-events: none;

    & > * {
        z-index: 2;
    }
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateY(1rem);
}

/* ensure leaving items are taken out of layout flow so that moving
animations can be calculated correctly. */
.list-leave-active {
    position: absolute;
    z-index: 0;
}
</style>
