<template>
    <div class="c-boutiques-listing">
        <component :is="isDesktop ? BoutiquesListingSplitView : BoutiquesListingTabs">
            <template #count>
                <p class="c-boutiques-listing__count">
                    {{ getBoutiquesCount }}
                    <span v-if="getBoutiquesCount <= 1">{{ getDescription("boutiques.count_one") }}</span>
                    <span v-else>{{ getDescription("boutiques.count_many") }}</span>
                </p>
            </template>
            <template #list>
                <ul v-if="boutiques" class="c-boutiques-listing__cards">
                    <li
                        v-for="boutique in boutiques"
                        :key="`boutique-${boutique.shop_id}`"
                        :id="`boutique-${boutique.shop_id}`"
                        :class="{
                            'is-active': activeMarkerID == boutique.shop_id,
                        }"
                    >
                        <card-boutique
                            :data="boutique"
                            @boutique:click="(b) => updateActiveMarker(b)"
                            :hide-page-link="!isBoutiqueHasPage(boutique)"
                        ></card-boutique>
                    </li>
                </ul>
            </template>
            <template #map>
                <map-base
                    :key="`${last_fetch}-${location?.literal}`"
                    :markers="boutiques || []"
                    :pin="location"
                    :active-marker="activeMarker"
                    @marker:click="(m) => updateActiveMarker(m)"
                />
                <div v-if="!isDesktop" class="c-boutiques-listing__active-card-boutique">
                    <card-boutique
                        v-if="activeMarker?.shop_id"
                        :data="activeMarker"
                        :hide-page-link="!isBoutiqueHasPage(activeMarker)"
                        hide-opening-hours
                    >
                        <template #extra>
                            <button
                                class="c-boutiques-listing__active-card-boutique__close-button"
                                aria-label="Fermer"
                                @click="updateActiveMarker({})"
                            >
                                <icon-base
                                    name="cross-sm"
                                    :size="2"
                                    :bg-size="2"
                                    show-bg
                                    bg-color="blue-light"
                                    :bg-radius="2"
                                />
                            </button>
                        </template>
                    </card-boutique>
                </div>
            </template>
        </component>
    </div>
</template>

<script setup>
import { ref, provide, computed, toRefs } from "vue";
import { storeToRefs } from "pinia";
import { useMediaQuery } from "@vueuse/core";
import { useBoutiquesStore } from "@vue-stores";
import { useTranslationDescriptions } from "@vue-composables";

import MapBase from "@vue-components/MapBase";
import CardBoutique from "@vue-components/CardBoutique";
import IconBase from "@vue-components/IconBase";

import BoutiquesListingSplitView from "./components/BoutiquesListingSplitView";
import BoutiquesListingTabs from "./components/BoutiquesListingTabs";

const props = defineProps({
    availableBoutiquePages: {
        type: Array,
        default: [],
    },
});

// Composables
const { getDescription } = useTranslationDescriptions();
const isDesktop = useMediaQuery("(min-width: 768px)");
const boutiquesStore = useBoutiquesStore();

// Refs
const { availableBoutiquePages } = toRefs(props);
const { last_fetch, boutiques, location, getBoutiquesCount } = storeToRefs(boutiquesStore);

// Fn
const isBoutiqueHasPage = (boutique) => {
    return !!availableBoutiquePages.value.find((page) => Number(page.boutique_id) === boutique.shop_id);
};

// Active marker
const activeMarker = ref({});
const activeMarkerID = computed(() => activeMarker.value?.shop_id);

const updateActiveMarker = (newMarker) => {
    activeMarker.value = newMarker;
};

provide("activeMarker", activeMarker);
</script>

<style lang="scss" scoped>
.c-boutiques-listing {
    --boutiques-listing-card-padding-y: var(--grid-gap-1-25X);
    --boutiques-listing-card-padding-x: 0;
    --boutiques-listing-card-border-height: 0.2rem;
    --boutiques-listing-card-radius: var(--radius-small);

    @include min(md) {
        --boutiques-listing-card-padding-y: var(--grid-gap-1-25X);
        --boutiques-listing-card-padding-x: var(--grid-gap);
        --card-boutique-radius-default: var(--radius-regular);
    }

    &__count {
        color: var(--color-grey-dark);
        font-size: var(--fs-medium);
        padding-left: var(--boutiques-listing-card-padding-x);
    }

    &__cards {
        > li {
            padding: var(--boutiques-listing-card-padding-y) var(--boutiques-listing-card-padding-x);
            position: relative;
            margin-bottom: minus(var(--boutiques-listing-card-border-height));
            border-radius: var(--boutiques-listing-card-radius);
            transition: background-color 0.3s ease;

            &:not(:last-child) {
                &:after {
                    content: "";
                    height: var(--boutiques-listing-card-border-height);
                    position: absolute;
                    bottom: 0;
                    left: var(--boutiques-listing-card-padding-x);
                    right: var(--boutiques-listing-card-padding-x);
                    background-color: var(--color-grey-light);
                }
            }

            :deep(.c-card-boutique__title) {
                margin-bottom: calc(dvar(card-boutique-gap) * -0.5);
            }

            @include min(md) {
                --card-boutique-map_link-display: inline-flex;
            }

            &.is-active {
                @include min(md) {
                    background-color: var(--color-grey-light);
                }
            }
        }
    }

    &__active-card-boutique {
        --card-boutique-padding-y: var(--grid-gap);
        --card-boutique-padding-x: var(--grid-gap);
        --card-boutique-bg-color: var(--color-white);
        --card-boutique-gap: var(--grid-gap-half);
        position: absolute;
        bottom: dvar(container-gutter);
        left: 0;
        right: 0;
        z-index: 5;

        :deep(p) {
            font-size: var(--fs-small);
        }

        &__close-button {
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(-50%, -50%);
        }
    }
}
</style>
