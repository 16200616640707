<template>
    <Teleport to="body" :disabled="disableTeleport">
        <div class="c-modal" :class="{ 'is-open': isOpen }" :aria-expanded="isOpen" v-bind="$attrs">
            <modal-base-popup v-if="type == 'popup'" v-on-click-outside="close">
                <template #default>
                    <modal-base-close-button />
                    <slot></slot>
                </template>
            </modal-base-popup>

            <modal-base-drawer v-else v-on-click-outside="close">
                <template #closeButton>
                    <modal-base-close-button />
                </template>
                <template v-if="$slots.header" #header>
                    <slot name="header"></slot>
                </template>
                <template #default>
                    <slot></slot>
                </template>
                <template v-if="$slots.footer" #footer>
                    <slot name="footer" v-bind="{ close }"></slot>
                </template>
            </modal-base-drawer>
        </div>
    </Teleport>
</template>

<script setup>
import { computed, toRef, provide, watch, onMounted } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import { useModalsStore } from "@vue-stores";
import { useBarbaHook } from "@vue-composables";
import { ANIMATION_DURATIONS } from "@/constants";

import ModalBasePopup from "./components/ModalBasePopup.vue";
import ModalBaseDrawer from "./components/ModalBaseDrawer.vue";
import ModalBaseCloseButton from "./components/ModalBaseCloseButton.vue";

const props = defineProps({
    name: String,
    open: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: "popup",
        validator: (s) => ["popup", "drawer"].includes(s),
    },
    disableTeleport: Boolean,
});

const emit = defineEmits(["onOpen", "onClose", "onOpenAnimationCompleted", "onCloseAnimationCompleted"]);

const modalsStore = useModalsStore();

const isOpenFromProps = toRef(props, "open");
const modalFromStore = computed(() => modalsStore.getModalByName(props.name));
const isOpen = computed(() => (modalFromStore.value ? modalFromStore.value.isOpen : isOpenFromProps.value)); // Check status from store, fallback with props

// Callback after close animation is finished
const transitionDurationIn = ANIMATION_DURATIONS[props.type].in * 1000;
const transitionDurationOut = ANIMATION_DURATIONS[props.type].out * 1000;

const openAnimationCallback = () => {
    setTimeout(() => {
        emit("onOpenAnimationCompleted");
    }, transitionDurationIn);
};

const closeAnimationCallback = () => {
    setTimeout(() => {
        emit("onCloseAnimationCompleted");
    }, transitionDurationOut + 100); // Safe gap of 100ms
};

// Open/Close methods
const open = () => {
    if (isOpen.value !== false) return;

    if (modalFromStore.value) modalsStore.openModal(props.name);
    else isOpenFromProps.value = true;
};

const close = () => {
    if (isOpen.value !== true) return;

    if (modalFromStore.value) modalsStore.closeModal(props.name);
    else isOpenFromProps.value = false;
};

//function open

onMounted(() => {
    if (isOpen.value) {
        emit("onOpen");
        openAnimationCallback();
    }
});

watch(isOpen, (modalOpen) => {
    if (modalOpen) {
        emit("onOpen");
        openAnimationCallback();
    } else {
        emit("onClose");
        closeAnimationCallback();
    }
});

// Close all modals on page change
useBarbaHook(modalsStore.closeAllModals, "beforeLeave");

// Provide and Expose methods
provide("modal", { open, close });

defineExpose({ isOpen, open, close });
</script>

<style lang="scss">
.c-modal {
    @include unselectable;
    @include fullscreen(fixed);
    display: flex;
    align-items: var(--modal-flex-align, center);
    justify-content: var(--modal-flex-justify, center);
    z-index: var(--modal-z, var(--z-modal));
    overflow: hidden;

    * {
        pointer-events: none !important;
    }

    &.is-open {
        @include unselectable(false);
        * {
            pointer-events: auto !important;
        }
    }
}
</style>
