<template>
    <div class="c-boutiques-listing-tabs">
        <div class="c-boutiques-listing-tabs__nav">
            <button-count
                v-for="tab in tabs"
                :key="`tabs-nav-${tab}`"
                :label="getDescription(`boutiques.view_${tab}`)"
                :count="4"
                :is-active="tab == activeTab"
                @mouseover="updateActiveTab(tab)"
            >
                <template #count>
                    <icon-base :name="tab == 'map' ? 'localisation-sm' : 'menu-sm'" :size="1.8" />
                </template>
            </button-count>
        </div>
        <div class="c-boutiques-listing-tabs__content">
            <div
                v-for="tab in tabs"
                :key="`tabs-content-${tab}`"
                class="c-boutiques-listing-tabs__content__item"
                :class="[`-${tab}`, tab == activeTab && 'is-active']"
            >
                <slot :name="tab"></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useTranslationDescriptions } from "@vue-composables";

import IconBase from "@vue-components/IconBase";
import ButtonCount from "@vue-components/ButtonCount";

const { getDescription } = useTranslationDescriptions();

const tabs = ref(["map", "list"]);
const activeTab = ref("map");

const updateActiveTab = (brand) => {
    activeTab.value = brand;
};
</script>

<style lang="scss" scoped>
.c-boutiques-listing-tabs {
    &__nav {
        --boutiques-listing-tabs-gap: #{dvar(container-gutter)};
        @include stack($var-prefix: "boutiques-listing-tabs");

        .c-button-count {
            --button-count-padding-y: var(--grid-gap-half);
            --button-count-padding-x: var(--grid-gap);
            --button-count-radius: var(--radius-regular);
            --badge-number-base-unit: 4rem;

            @include max(sm) {
                width: calc((100% - var(--boutiques-listing-tabs-gap)) / 2);
            }
        }
    }

    &__content {
        position: relative;

        &__item {
            @include unselectable;
            position: var(--composition-position, absolute);
            top: 0;
            left: 0;
            opacity: var(--composition-opacity, 0);

            &.is-active {
                --composition-position: relative;
                --composition-opacity: 1;
                @include unselectable(false);
            }
        }
    }

    :deep(.c-map-base) {
        --map-height: 60rem;
        @include negative-container-offset;
        margin-top: var(--grid-gap-1-25X);
    }
}
</style>
