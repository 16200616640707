<template>
    <slot v-if="operation"></slot>
</template>

<script setup>
import { computed } from "vue";
import { useWindowSize } from "@vueuse/core";

const props = defineProps({
    width: {
        type: Number,
        required: true,
        default: 768,
    },
    operator: {
        type: String,
        required: false,
        default: "greater",
        validator: (o) => ["greater", "lower"].includes(o),
    },
});

const { width: windowWidth } = useWindowSize();

const operation = computed(() => {
    if (props.operator == "lower") {
        return windowWidth.value < props.width;
    } else {
        return windowWidth.value >= props.width;
    }
});
</script>
