<template>
    <div v-if="indexes" class="c-brands-showcase" :class="isMobile ? '-mobile' : '-desktop'">
        <div class="c-brands-showcase__list | col-all" :class="!isMobile ? 'col-3-lg' : ''">
            <ul class="c-brands-showcase__list__links">
                <li
                    v-for="index in indexes"
                    :key="`list-${index}`"
                    class="c-brands-showcase__list__item"
                    :class="{ 'is-active': !isMobile && index == activeBrand }"
                    @mouseover="!isMobile ? updateActiveBrand(index) : null"
                >
                    <slot :name="`list-${index}`"></slot>
                </li>
            </ul>

            <div v-if="$slots['after-list']" class="c-brands-showcase__list__extras">
                <slot name="after-list"></slot>
            </div>
        </div>

        <div class="c-brands-showcase__compositions | col-all" :class="!isMobile ? 'col-6-lg start-5-lg' : ''">
            <component
                :is="isMobile ? BrandsShowcaseCompositionMobile : BrandsShowcaseCompositionDesktop"
                :count="indexes.length"
            >
                <div
                    v-for="index in indexes"
                    :key="`composition-${index}`"
                    class="c-brands-showcase__compositions__item"
                    :class="{ 'is-active': index == activeBrand }"
                >
                    <slot :name="`composition-${index}`"></slot>
                </div>
            </component>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useMediaQuery } from "@vueuse/core";

import BrandsShowcaseCompositionDesktop from "./components/BrandsShowcaseCompositionDesktop";
import BrandsShowcaseCompositionMobile from "./components/BrandsShowcaseCompositionMobile";

const props = defineProps({
    indexes: {
        type: String,
        required: false,
        default: "",
    },
});

const indexes = computed(() => props.indexes.split(","));
const activeBrand = ref(indexes.value[0]);

const updateActiveBrand = (brand) => {
    activeBrand.value = brand;
};

// Media queries
const isTablet = useMediaQuery("(max-width: 1024px)");
const isNotTouch = useMediaQuery("(hover: hover)");
const isMobile = computed(() => !isNotTouch.value || isTablet.value);
</script>

<style lang="scss" scoped>
.c-brands-showcase {
    $_: &;

    &.-desktop {
        --grid-columns: 10;
        --grid-row-gap: var(--featured-brands-collections-padding-y);
        @include grid;
    }

    &__list {
        #{$_}.-mobile & {
            margin-bottom: var(--featured-brands-collections-padding-y);
        }

        &__links {
            --button-justify: space-between;
            --button-font-size: var(--fs-medium);
            --button-font-weight: 500;
            --grid-col-gap: #{dvar(container-gutter)};
            --grid-row-gap: 0;
            --grid-columns: 1;
            @include grid;

            #{$_}.-mobile & {
                @include min(md) {
                    --grid-columns: 2;
                }
            }
        }

        &__item {
            &:not(.is-active) {
                --button-bg-color: transparent;
                --button-border-color: transparent;
            }

            #{$_}.-mobile & {
                --showcase-item-border-width: 1px;
                border-bottom: var(--showcase-item-border-width) solid var(--color-blue);
            }
        }

        &__extras {
            margin-top: var(--grid-gap-1-5X);
        }
    }

    &__compositions {
        margin-bottom: minus(var(--featured-brands-collections-padding-y));

        #{$_}.-mobile & {
            width: 100vw;
            margin-left: 50%;
            overflow: hidden;
            transform: translateX(-50%);
        }

        #{$_}.-desktop & {
            margin-top: calc((var(--featured-brands-compo-offset) + var(--featured-brands-collections-padding-y)) * -1);
        }

        &__item {
            :deep(.c-brand-composition__column.-left) {
                --image-radius-bottom-left: 0;
                --image-radius-bottom-right: 0;
            }
        }
    }
}
</style>
