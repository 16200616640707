<template>
    <ButtonPlain @click="openModal" class="c-size-guide-button">
        <template #label><slot /></template>
    </ButtonPlain>
</template>

<script setup>
import { useModalsStore } from "@vue-stores";
import ButtonPlain from "../ButtonPlain/ButtonPlain.vue";

const modals = useModalsStore();

const openModal = () => {
    modals.openModal("size-guide-modal");
};
</script>

<style lang="scss" scoped>
.c-size-guide-button {
    --button-txt-color: var(--color-accent-blue);
    --button-border-height: 0;
}
</style>
