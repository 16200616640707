<template>
    <modal-base class="c-modal-content" type="popup" :name="name">
        <div
            v-if="title || icon || description"
            class="c-modal-content__header"
        >
            <slot name="icon">
                <icon-base
                    v-if="icon"
                    class="c-modal-content__header__icon"
                    :name="icon"
                    :size="4"
                    show-bg
                    bg-color="blue-light"
                />
            </slot>

            <slot name="title">
                <h3
                    v-if="title"
                    class="c-modal-content__header__title"
                    v-html="title"
                ></h3>
            </slot>

            <slot name="description">
                <div
                    v-if="description"
                    class="c-modal-content__header__description"
                >
                    <p v-html="description"></p>
                </div>
            </slot>
        </div>

        <div class="c-modal-content__content">
            <slot></slot>
        </div>
    </modal-base>
</template>

<script setup>
import { onBeforeMount, onUnmounted } from "vue";
import { useModalsStore } from "@vue-stores";

import ModalBase from "@vue-components/ModalBase";
import IconBase from "@vue-components/IconBase";

const props = defineProps({
    name: {
        type: String,
        required: true,
        default: "",
    },
    title: {
        type: String,
        required: false,
        default: "",
    },
    description: {
        type: String,
        required: false,
        default: "",
    },
    icon: {
        type: String,
        required: false,
        default: "",
    },
    isOpen: {
        type: Boolean,
        default: false,
    },
});

// Register modal
const modals = useModalsStore();

onBeforeMount(() => {
    modals.registerModal({
        component: "ModalContent",
        name: props.name,
        isOpen: props.isOpen,
    });
});

onUnmounted(() => {
    modals.eraseModal(props.name);
});
</script>

<style lang="scss">
.c-modal-content {
    @include min(md) {
        --modal-popup-padding: var(--grid-gap);
    }

    &__header {
        @include stack(vertical, $gap: var(--grid-gap-quarter));
        text-align: center;
        margin-bottom: var(--grid-gap-1-5X);

        &__icon {
            margin-bottom: var(--grid-gap-three-quarter);
        }

        &__title {
            @include h(h5);
            max-width: 40rem;
        }

        &__description {
            max-width: 33ch;
        }
    }

    &__content {
        max-width: 68rem;
        margin: 0 auto;
    }
}
</style>
