<template>
    <div class="c-map-base__pin">
        <div class="c-map-base__pin__inner"></div>
    </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.c-map-base__pin {
    --map-pin-size: 3rem;
    --map-pin-padding: 0.7rem;

    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--map-pin-size);
    height: var(--map-pin-size);
    padding: var(--map-pin-padding);
    border: 1px solid var(--color-accent-blue);
    border-radius: var(--map-pin-size);
    transform: translateY(50%);

    &__inner {
        width: 100%;
        height: 100%;
        background-color: var(--color-accent-blue);
        border-radius: var(--map-pin-size);
    }
}
</style>
