<template>
    <span :class="classes" :style="{ ...styles, ...bgStyles }" aria-hidden="true" focusable="false">
        <svg role="presentation">
            <use :href="`#icon-${name}`"></use>
        </svg>
    </span>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    name: {
        type: String,
        required: false,
        default: "chevron-sm",
    },
    size: {
        type: Number,
        required: false,
        default: 2, // rem
        validator: (s) => {
            return s > 0;
        },
    },
    rotation: {
        type: Number,
        required: false,
        default: 0, // deg
        validator: (r) => {
            return r >= -360 && r <= 360;
        },
    },

    // Background
    showBg: {
        type: Boolean,
        required: false,
        default: false,
    },
    bgColor: {
        type: String,
        required: false,
        default: "blue-light",
    },
    bgSize: {
        type: Number,
        required: false,
        default: 6, // rem
        validator: (s) => {
            return s > 0;
        },
    },
    bgRadius: {
        type: Number,
        required: false,
        default: 2, // rem
        validator: (r) => {
            return r > 0;
        },
    },

    // Box shadow
    showShadow: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const classes = computed(() => [
    "c-icon",
    `-${props.name}`,
    props.showBg && "has-bg",
    props.showShadow && "has-shadow",
]);

// Visual
const styles = computed(() => ({
    "--width": `${props.size}rem`,
    "--rotation": `${props.rotation}deg`,
}));

const bgStyles = computed(() => {
    if (!props.showBg) return;
    return {
        "--bg-width": `${props.bgSize}rem`,
        "--bg-color": `var(--color-${props.bgColor})`,
        "--bg-radius": `${props.bgRadius}rem`,
    };
});
</script>

<style lang="scss">
.c-icon {
    &.-sezzle {
        --icon-ratio: 215/794;
    }
}
</style>
