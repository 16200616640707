<template>
    <button class="c-button -size-small -color-grey" type="button" @click="modals.openModal(modalName)">
        <span class="c-button__label">
            {{ getDescription("products.facets.filters") }}
            <span v-if="activeValuesCount" v-html="activeValuesCount" class="c-badge-number size-small" />
        </span>
        <slot />
    </button>
</template>

<script setup>
import { useModalsStore } from "@vue-stores";
import { useTranslationDescriptions } from "@vue-composables";
import { inject } from "vue";

// Props
const props = defineProps({
    modalName: {
        type: String,
        required: true,
    },
});

// Composables
const modals = useModalsStore();
const { getDescription } = useTranslationDescriptions();

// Inject
const activeValuesCount = inject("activeValuesCount");
</script>

<style lang="scss" scoped></style>
