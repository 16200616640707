<template>
    <div class="c-navigation-featured">
        <ul class="c-navigation-featured__list">
            <li v-for="(feature, index) in filteredFeatures" :key="`features-nav--${index}`">
                <the-navigation-card :item="feature" tag="a" />
            </li>
        </ul>
    </div>
</template>

<script setup>
import { computed } from "vue";

import TheNavigationCard from "../TheNavigationCard";

const props = defineProps({
    features: {
        type: Array,
        required: false,
        default: () => [],
    },
});

// Filter features with feature that have object called object which have a string called featured_image
const filteredFeatures = computed(() => {
    return props.features.filter((feature) => feature.object && feature.object.featured_image);
});
</script>

<style lang="scss" scoped>
.c-navigation-featured {
    --navigation-featured-before-height: 0.1rem;
    --navigation-featured-before-width: 100%;
    --padding-nav-feature: var(--grid-gap) 0 0;
    padding: var(--padding-nav-feature);
    position: relative;

    @include min("lg") {
        --padding-nav-feature: 0rem 0rem var(--grid-gap) var(--grid-gap);
        --navigation-featured-before-height: calc(100% - var(--grid-gap));
        --navigation-featured-before-width: 0.1rem;
    }

    @include min("xl") {
        --padding-nav-feature: 0rem 0rem var(--grid-gap) calc(var(--grid-gap-1-5X));
    }

    &:before {
        content: " ";
        position: absolute;
        top: 0rem;
        left: 0rem;
        width: var(--navigation-featured-before-width);
        height: var(--navigation-featured-before-height);
        background-color: var(--color-grey-light);
    }

    &__list {
        @include stack("horizontal", $wrap: false);

        @include min(lg) {
            max-width: 40vw;
        }
    }
}
</style>
