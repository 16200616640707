import { unref } from "vue";

const useScrollTo = (elementRef, offset = 20) => {
    if (!elementRef) return;

    const element = unref(elementRef);
    const elementRect = element.getBoundingClientRect();

    const header = document.querySelector(".l-header__inner");
    const headerHeight = header.offsetHeight;

    const totalScrollTop = elementRect.top + window.scrollY - headerHeight - offset;

    if (totalScrollTop > 0) {
        window.scroll({
            top: totalScrollTop,
            behavior: "smooth",
        });
    }
};

export default useScrollTo;
