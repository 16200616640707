<template>
    <div class="c-mobile-category-single" :class="{ '-scrollable': isMobileListScrollable }">
        <ul ref="mobileCategoryList" class="c-mobile-category-single__list">
            <li v-for="(item, index) in categories" :key="`mobile-nav-category-single--${index}`">
                <a
                    :title="item.title"
                    :aria-label="`Explorer ${item.title}`"
                    class="c-button -color-blue"
                    :href="item.url"
                >
                    <span class="c-button__label">{{ item.title }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

const props = defineProps({
    categories: {
        type: Array,
        required: false,
        default: () => [],
    },
});

const mobileCategoryList = ref(null);
const isMobileListScrollable = ref(true);
const currentWindowWidth = ref(0);

const widthMobileCategoryList = computed(() => {
    return mobileCategoryList.value.clientWidth;
});

const toggleIsMobileListScrollable = (bool) => {
    isMobileListScrollable.value = bool;
};

const checkIsMobileListScrollable = () => {
    widthMobileCategoryList.value < window.innerWidth
        ? toggleIsMobileListScrollable(false)
        : toggleIsMobileListScrollable(true);
};

const checkIfResizeNeeded = () => {
    if (window.innerWidth !== currentWindowWidth.value) {
        checkIsMobileListScrollable();
        setCurrentWindowWidth();
    }
};

const setCurrentWindowWidth = () => {
    currentWindowWidth.value = window.innerWidth;
};

onMounted(() => {
    checkIsMobileListScrollable();
    setCurrentWindowWidth();
    window.addEventListener("resize", checkIfResizeNeeded);
});
</script>

<style lang="scss" scoped>
.c-mobile-category-single {
    --mobile-category-single-scrollable: auto;
    --mobile-category-single-justify: center;
    display: flex;
    width: 100vw;
    margin-left: minus(var(--navigation-mobile-menu-padding-x));

    overflow-x: var(--mobile-category-single-scrollable);
    justify-content: var(--mobile-category-single-justify);

    &.-scrollable {
        --mobile-category-single-scrollable: scroll;
        --mobile-category-single-justify: flex-start;
    }

    &__list {
        @include hide-scrollbar;
        display: flex;
        flex-direction: row;
        gap: var(--grid-gap-half);
        padding: 0rem var(--grid-gap-half);
        overflow-x: auto;
    }
}
.c-button {
    --button-padding-x: 1.8rem;

    &__label {
        white-space: nowrap;
    }
}
</style>
