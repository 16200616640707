import { recurse } from "@/utils";

export function useTranslationDescriptions(strings) {
    const theme_strings = strings || window.theme.strings;

    function getDescription(name) {
        return recurse(name.split("."), theme_strings);
    }

    return { getDescription };
}
