<template>
    <div class="c-navigation-mobile">
        <button
            class="c-navigation-mobile__button | c-button -size-small is-outline"
            @click="toggleNav()"
            :aria-label="ariaLabelButton()"
        >
            <span class="c-button__label">Menu</span>
            <span class="c-navigation-mobile__button__icon">
                <icon-base :name="isOpen ? 'cross-sm' : 'menu-sm'" :size="isOpen ? 2 : 1.8" />
            </span>
        </button>

        <mobile-menu :is-open="isOpen" :navigation="navigation"></mobile-menu>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useBarbaHook } from "@vue-composables";

import MobileMenu from "./components/MobileMenu";
import IconBase from "@vue-components/IconBase";

const isOpen = ref(false);

const props = defineProps({
    navigation: {
        type: Array,
        required: false,
        default: () => [],
    },
});
////////////////////////////////
//       START USER ON MOBILE OPEN THE NAV
////////////////////////////////
const toggleNav = () => {
    isOpen.value = !isOpen.value;
};

// Close mobile submenu on page change
useBarbaHook(() => {
    isOpen.value = false;
}, "beforeLeave");
////////////////////////////////
//       END USER ON MOBILE OPEN THE NAV
////////////////////////////////

const ariaLabelButton = () => {
    return isOpen.value ? "fermer la navigation" : "ouvrir la navigation";
};
</script>

<style lang="scss" scoped>
.c-navigation-mobile {
    &__button {
        --button-gap: 0.5rem;
        display: flex;
        font-weight: 600;

        &__icon {
            @include stack;
            width: 2rem;
            height: 2rem;
        }
    }
}
</style>
