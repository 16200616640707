import { watch } from "vue";
import { useScrollLock } from "@vueuse/core";

export const useDocumentScrollLock = (lockState) => {
    const documentEl = document.documentElement;
    const isScrollLocked = useScrollLock(documentEl);

    watch(
        lockState,
        (newLockState) => {
            isScrollLocked.value = newLockState;
        },
        { immediate: true }
    );

    return { isScrollLocked };
};
