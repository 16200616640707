<template>
    <form class="c-modal-instore-availability-search" @submit.prevent="searchAvailability">
        <div class="c-modal-instore-availability-search__fields">
            <input-base
                :placeholder="getDescription('boutiques.search.placeholder')"
                name="address"
                v-model="modelLocation"
            />
            <base-button type="submit" :is-loading="isLoading">
                <template #label>
                    <span class="u-visually-hidden">{{ getDescription("product.search") }}</span>
                    <icon-base name="loupe-sm" :size="2" />
                </template>
            </base-button>
        </div>
    </form>
</template>

<script setup>
import { ref, inject } from "vue";

import { useBoutiquesStore } from "@vue-stores";
import { useProductInventory, useTranslationDescriptions } from "@vue-composables";

import BaseButton from "@vue-components/BaseButton";
import IconBase from "@vue-components/IconBase";
import InputBase from "@vue-components/InputBase";

// Composables
const { getDescription } = useTranslationDescriptions();
const boutiquesStore = useBoutiquesStore();

// Refs
const isLoading = ref(false);
const firstSearched = ref(false);
const hasError = ref(false);
const modelLocation = ref(boutiquesStore.location?.literal);

// Fn
const searchAvailability = async () => {
    isLoading.value = true;
    hasError.value = false;

    const response = await useProductInventory(productData.value.sku, modelLocation.value);

    if (!response) {
        hasError.value = true;
        firstSearched.value = false;
    } else {
        emit("onSearch", response);
        firstSearched.value = true;
    }

    isLoading.value = false;
    handleUpdate();
};

// Trigger search when modal opened and location exist
const automaticSearch = () => {
    if (modelLocation.value && firstSearched.value == false) {
        searchAvailability();
    }
};

function handleUpdate() {
    const status = {
        hasError: hasError.value,
        firstSearched: firstSearched.value,
        isLoading: isLoading.value,
    };
    emit("onUpdate", status);
}
// Reset basic component refs
function reset() {
    isLoading.value = false;
    hasError.value = false;
    firstSearched.value = false;

    handleUpdate();
}

// Inject
const productData = inject("product");

// Expose
defineExpose({ automaticSearch, reset });

// Emits
const emit = defineEmits(["onSearch", "onUpdate"]);
</script>

<style lang="scss" scoped>
.c-modal-instore-availability-search {
    @include stack($var-prefix: "modal-instore-availability-search");

    &__fields {
        --field-wrap-width: 32rem;
        position: relative;

        @include max(sm) {
            --field-wrap-width: 27.5rem;
        }

        .c-button {
            --button-width: 6rem;
            --button-padding: 0;
            --button-txt-color: var(--color-blue-dark);
            --btn-loading-icon-width: 1.6rem;
            --btn-loading-icon-color: var(--color-grey-dark);
            --button-bg-color: transparent;
            --button-border-width: 0;
            position: absolute;
            top: 0;
            right: -1px; // Prevent field border to show under when hovering

            &:not(.has-no-hover) {
                @include hover {
                    --button-txt-color: var(--color-blue-dark);
                    --button-bg-color: var(--color-blue-light);
                }
            }
        }
    }

    &__error-message {
        flex-basis: 100%;
    }
}
</style>
