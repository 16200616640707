<template>
    <div class="c-navigation-mobile-menu" :class="className">
        <nav class="c-navigation-mobile-menu__inner">
            <mobile-category-single :categories="categoryWithoutChid"></mobile-category-single>

            <mobile-category-parent :categories="categoryWithChild"></mobile-category-parent>
        </nav>
    </div>
</template>

<script setup>
import { computed } from "vue";

import MobileCategoryParent from "../MobileCategoryParent";
import MobileCategorySingle from "../MobileCategorySingle";

const props = defineProps({
    isOpen: {
        type: Boolean,
        required: false,
        default: false,
    },
    navigation: {
        type: Array,
        required: false,
        default: () => [],
    },
});

const className = computed(() => [props.isOpen ? `is-mobile-nav-open` : ""]);

////////////////////////////////
//       START FILTER LIST
////////////////////////////////

// alternatively it can be one list
const categoryWithChild = props.navigation.filter((item) => item.links.length);
const categoryWithoutChid = props.navigation.filter((item) => !item.links.length);

////////////////////////////////
//       END FILTER LIST
////////////////////////////////
</script>

<style lang="scss" scoped>
.c-navigation-mobile-menu {
    --navigation-mobile-menu-padding-y: var(--grid-gap);
    --navigation-mobile-menu-padding-x: var(--grid-gap-two-third);
    @include hide-scrollbar;
    width: 100vw;
    height: calc(100vh - var(--header-height));
    position: absolute;
    top: var(--header-height);
    right: 0;
    bottom: 0;
    left: 0;
    z-index: calc(var(--z-header) - 1);
    overflow-y: auto;
    pointer-events: var(--mobile-nav-pointer-events, none);
    opacity: var(--mobile-nav-opacity, 0);
    transform: translateY(var(--mobile-nav-translate-y, -1rem));
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;

    &.is-mobile-nav-open {
        --mobile-nav-opacity: 1;
        --mobile-nav-translate-y: 0;
        --mobile-nav-pointer-events: auto;
    }

    &:before {
        @include unselectable;
        transition: 0.3s ease-out opacity;
        content: "";
        position: fixed;
        top: 0;
        right: 0rem;
        left: 0rem;
        height: var(--grid-gap);
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0.6237088585434174) 56%,
            rgba(255, 255, 255, 0) 100%
        );
        z-index: calc(var(--z-header) - 1);
    }

    &__inner {
        @include navigation-radius;
        @include stack(
            "vertical",
            "stretch",
            "top",
            $gap: var(--grid-gap-two-third),
            $var-prefix: "navigation-mobile-menu"
        );
        min-height: 100%;
        position: relative;
        z-index: 2;
        padding: var(--navigation-mobile-menu-padding-y) var(--navigation-mobile-menu-padding-x)
            calc(var(--navigation-mobile-menu-padding-y) * 2);
        overflow: hidden;
    }
}
</style>
