<template>
    <li
        class="c-desktop-item"
        :class="{ 'is-hover': isHover && hasLinks }"
        @mouseenter="enter"
        @mouseleave="leave"
        @blur="leave"
    >
        <base-button
            tag="a"
            class="c-desktop-item__button | navigation-button-active | -size-slim"
            :class="{
                'is-outlined': isOutlined,
                'is-active': isActive,
                'has-child-active': hasChildActive,
            }"
            :href="item.url"
            tabindex="0"
            role="button"
            :aria-label="a11yCopy()"
            @focus="enter"
            @click.stop.prevent="preventOnTOuch"
        >
            <template #label>
                {{ item.title }}
            </template>
        </base-button>

        <the-navigation-sub-menu v-if="hasLinks" :links="links"></the-navigation-sub-menu>
    </li>
</template>

<script setup>
import { ref, computed, toRefs } from "vue";
import { useMediaQuery } from "@vueuse/core";
import { useBarbaHook } from "@vue-composables";

import barba from "@barba/core";

import TheNavigationSubMenu from "@vue-components/TheNavigation/components/TheNavigationSubMenu";

const emit = defineEmits(["open", "close"]);

const props = defineProps({
    item: {
        type: Object,
        required: false,
        default: null,
    },
});

const isHover = ref(false);
const { item } = toRefs(props);

const url = computed(() => item.value?.url);
const links = computed(() => item.value?.links);
const hasLinks = computed(() => links.value?.length > 0);

const enter = () => {
    toggleIsHover(true);
};

const leave = () => {
    toggleIsHover(false);
};

const toggleIsHover = (bool) => {
    isHover.value = bool;
    emitStatusNav();
};

const emitStatusNav = () => {
    isHover.value ? emit("open") : emit("close");
};

const isTouch = useMediaQuery("(hover: none)");

const preventOnTOuch = (e) => {
    // Block interaction on touch devices on parent link node
    if (isTouch.value && hasLinks.value) {
        return;
    }

    // Use barba.go to ensure the redirection
    barba.go(url.value);
};

// Make sale link appear as button
const isOutlined = computed(() => item.value.handle === "soldes" || item.value.handle === "sales");
const isActive = computed(() => item.value.active);
const hasChildActive = computed(() => item.value.child_active);

// a11y
const a11yCopy = () => {
    return item.value.links.length ? a11yToggleCopy() : `Visiter ${item.value.title}`;
};

const a11yToggleCopy = () => {
    return isHover.value ? `Fermer ${item.value.title}` : `Ouvrir ${item.value.title}`;
};

// Close submenu on page change
useBarbaHook(() => {
    leave();
}, "beforeLeave");
</script>

<style lang="scss" scoped>
@mixin desktop-item-hover {
    --button-bg-color: var(--color-blue-light);
    --button-border-color: var(--color-blue-light);
}

.c-desktop-item {
    --submenu-translate-y: -1rem;
    @include stack($var-prefix: "desktop-nav-item");
    height: 100%;
    padding: 0 0.2rem;

    &.is-hover {
        --submenu-opacity: 1;
        --submenu-translate-y: 0;
        --submenu-pointer-events: auto;
    }

    &__button {
        --button-txt-color: var(--color-blue-dark);
        --button-font-size: var(--fs-small);
        --button-bg-color: transparent;
        --button-border-color: transparent;
        display: flex;
        align-items: center;
        font-size: var(--fs-small);
        font-weight: 700;
        cursor: pointer;
        transition: 0.3s ease color, 0.3s ease background-color, 0.3s ease border-color, 0.3s ease text-decoration;

        &.is-outlined {
            --button-bg-color: transparent;
            --button-border-color: var(--color-black);
            color: var(--desktop-nav-item-outlined-font-color, var(--color-blue-dark));
            padding: 0.65rem 1.3rem 0.55rem;
            border-radius: var(--radius-x-small);

            &.is-active,
            &.has-child-active {
                --button-bg-color: var(--color-grey-light);
                --button-border-color: var(--color-grey-light);
            }
            @include hover {
                @include desktop-item-hover;
            }
        }

        &.is-active,
        &.has-child-active {
            --button-bg-color: var(--color-grey-light);
            --button-border-color: var(--color-grey-light);
        }

        @include hover {
            @include desktop-item-hover;
        }
    }
}
</style>
