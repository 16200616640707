/**
 * Returns a configuration object for a fetch request with the specified type and method.
 *
 * @param {string} [type="json"] - The response type to accept (e.g. "json", "text", "html", etc.).
 * @param {string} [method="POST"] - The HTTP method to use (e.g. "GET", "POST", "PUT", "DELETE", etc.).
 * @returns {object} The configuration object for the `fetch` function.
 */
function fetchConfig(type = "json", method = "POST") {
    return {
        method,
        headers: { "Content-Type": "application/json", Accept: `application/${type}` },
    };
}
export default fetchConfig;
