<template>
    <div class="c-toast" :class="{ 'is-open': isOpen }" :aria-expanded="isOpen" v-bind="$attrs">
        <div class="c-toast__inner">
            <slot />
        </div>

        <toast-base-close-button v-if="isClosable" @click="close" class="c-toast__close" />
    </div>
</template>

<script setup>
import { computed, toRef, provide, watch, onMounted } from "vue";
import { useToastsStore } from "@vue-stores";
import { ANIMATION_DURATIONS } from "@/constants";

import ToastBaseCloseButton from "./components/ToastBaseCloseButton.vue";

const props = defineProps({
    name: String,
    isOpen: {
        type: Boolean,
        default: true,
    },
});

const emit = defineEmits(["onOpen", "onClose", "onOpenAnimationCompleted", "onCloseAnimationCompleted"]);

const toastsStore = useToastsStore();

const isOpenFromProps = toRef(props, "open");
const toastInstance = computed(() => toastsStore.getToastByName(props.name));
const isOpen = computed(() => (toastInstance.value ? toastInstance.value.isOpen : isOpenFromProps.value)); // Check status from store, fallback with props
const isClosable = computed(() => toastInstance.value.isClosable);

// Callback after close animation is finished
const transitionDurationIn = ANIMATION_DURATIONS["toast"].in * 1000;
const transitionDurationOut = ANIMATION_DURATIONS["toast"].out * 1000;

const openAnimationCallback = () => {
    setTimeout(() => {
        emit("onOpenAnimationCompleted");
    }, transitionDurationIn);
};

const closeAnimationCallback = () => {
    setTimeout(() => {
        emit("onCloseAnimationCompleted");
    }, transitionDurationOut + 100); // Safe gap of 100ms
};

// Open/Close methods
const open = () => {
    if (isOpen.value !== false) return;

    if (toastInstance.value) toastsStore.openToast(props.name);
    else isOpenFromProps.value = true;
};

const close = () => {
    if (isOpen.value !== true) return;

    if (toastInstance.value) toastsStore.closeToast(props.name);
    else isOpenFromProps.value = false;
};

onMounted(() => {
    if (isOpen.value) {
        emit("onOpen");
        openAnimationCallback();
    }
});

watch(isOpen, (toastOpen) => {
    if (toastOpen) {
        emit("onOpen");
        openAnimationCallback();
    } else {
        emit("onClose");
        closeAnimationCallback();
    }
});

// Provide and Expose methods
provide("toast", { open, close });

defineExpose({ isOpen, open, close });
</script>

<style lang="scss">
.c-toast {
    @include unselectable;
    position: relative;

    // Visual
    width: 32rem;
    border-radius: var(--radius-regular);
    background: var(--color-white);
    box-shadow: 1px 1px 15px 0px rgba(19, 46, 73, 0.15);

    * {
        pointer-events: none !important;
    }

    &.is-open {
        @include unselectable(false);
        * {
            pointer-events: auto !important;
        }
    }

    &__inner {
        padding: var(--grid-gap);
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
    }
}
</style>
