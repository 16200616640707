<template>
    <slot v-bind="{ openModal, closeModal }"></slot>
</template>

<script setup>
import { onMounted, toRefs } from "vue";
import { useModalsStore } from "@vue-stores";

const props = defineProps({
    name: {
        type: String,
        required: true,
        default: "",
    },
    openOnMounted: Boolean,
});

const { name, openOnMounted } = toRefs(props);

const modalStore = useModalsStore();

// Open/Close Methods
const openModal = () => {
    modalStore.openModal(name.value);
};

const closeModal = () => {
    modalStore.closeModal(name.value);
};

// Open on mounted if true
onMounted(() => {
    if (openOnMounted.value) openModal();
});
</script>
