<template>
    <span class="c-base-button-loader">
        <span class="c-icon -arrow-loader" aria-hidden="true" focusable="false">
            <svg role="presentation"><use href="#icon-loader"></use></svg>
        </span>
    </span>
</template>
<script setup></script>
<style lang="scss" scoped>
.c-base-button-loader {
    @include fullscreen;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    transition: opacity 0.15s ease;

    span {
        --base-button-loader-icon-width: var(--btn-loading-icon-width, 1.4rem);
        animation: spin 0.6s linear infinite;

        fill: var(--btn-loading-icon-color, var(--color-white));
        width: var(--base-button-loader-icon-width);

        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    }
}
</style>
