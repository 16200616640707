<template>
    <button class="c-modal-close-button c-button-icon" @click="close">
        <span
            class="c-icon -cross-sm has-bg"
            aria-hidden="true"
            focusable="false"
            style="
                --width: 2rem;
                --rotation: 0deg;
                --bg-width: 4rem;
                --bg-color: var(--color-grey-light);
                --bg-radius: 1.5rem;
            "
        >
            <svg role="presentation">
                <use href="#icon-cross-sm"></use>
            </svg>
        </span>
    </button>
</template>

<script setup>
import { inject } from "vue";

const { close } = inject("modal");
</script>

<style lang="scss"></style>
