<template>
    <nav v-if="navigation.length" class="c-navigation" :class="[isDesktop ? '-desktop' : '-mobile']">
        <the-navigation-desktop v-if="isDesktop" :navigation="navigation" />
        <the-navigation-mobile v-else :navigation="navigation" />
    </nav>
</template>

<script setup>
import { ref } from "vue";
import { useMediaQuery } from "@vueuse/core";
import { useBarbaHook } from "@vue-composables";

import TheNavigationDesktop from "./components/TheNavigationDesktop";
import TheNavigationMobile from "./components/TheNavigationMobile";

const isDesktop = useMediaQuery("(min-width: 1024px)");

// Settings
const sectionId = "api-navigation";
const sectionHtmlId = "shopify-section-" + sectionId;

// Refs
const navigation = ref([]);
const cache = ref([]);

// Functions
function renderNavigation() {
    const currentPathName = window.location.pathname;
    const cacheUrl = (element) => element.url === currentPathName;

    // Look for cached element
    cache.value.some(cacheUrl) ? renderSectionFromCache(currentPathName) : renderSectionFromFetch(currentPathName);
}

function renderSectionFromCache(currentPathName) {
    //
    // Get cached json based on the current pathName
    const cacheUrl = (element) => element.url === currentPathName;
    const json = cache.value.find(cacheUrl).json;

    updateNavigation(json);
}

function renderSectionFromFetch(currentPathName) {
    //
    // Construct the URL
    const url = window.location.pathname + `?section_id=${sectionId}`;

    fetch(url)
        .then((response) => response.text())
        .then((responseText) => {
            const html = responseText;

            try {
                // Get the section’s content
                const data = new DOMParser()
                    .parseFromString(html, "text/html")
                    .getElementById(sectionHtmlId)
                    .querySelector("pre").innerHTML;

                // Force JSON format
                const json = JSON.parse(data);

                // Add current page navigation to cache
                addNavigationToCache(json, currentPathName);

                // Update the navigation
                updateNavigation(json);
            } catch (error) {
                console.error(error);
            }
        });
}

function updateNavigation(json) {
    navigation.value = json;
}

function addNavigationToCache(json, url) {
    const limit = 20;

    // Ensure fast application by keeping only a certain amoutn of elements in the cache
    if (cache.value.length >= limit) {
        cache.value = cache.value.slice(Math.max(cache.value.length - limit, 0));
    }

    // Add new element to cache
    cache.value.push({ json, url });
}

// Hooks
useBarbaHook(() => {
    renderNavigation();
}, "beforeEnter");
</script>

<style lang="scss" scoped>
.c-navigation {
    @include stack($var-prefix: "navigation");
    height: 100%;

    &.-mobile {
        margin-right: auto;
    }
}
</style>

<style lang="scss">
.navigation-button-hover {
    transition: color 0.3s ease;

    @include hover {
        color: var(--color-accent-blue);
    }
}
</style>
