<template>
    <slot />
    <div class="c-product-recommandations" v-if="recommendationsHTML.length">
        <div class="c-product-recommandations__header" v-if="titleHTML">
            <component :is="headingTag" v-html="titleHTML" class="t-h2" />
        </div>
        <div class="c-product-recommandations_content">
            <carousel-free-mode
                :key="sectionId"
                :options="{ align: 'center', hasArrow: true }"
                class="c-product-recommandations__items"
            >
                <div
                    v-for="(recommendation, i) in recommendationsHTML"
                    :key="`r-${sectionId}-${i}`"
                    v-html="recommendation"
                />
            </carousel-free-mode>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import CarouselFreeMode from "@vue-components/CarouselFreeMode";

const titleHTML = ref(null);
const recommendationsHTML = ref([]);

const fetchRecommendations = async () => {
    await fetch(props.dataUrl)
        .then((response) => response.text())
        .then((text) => {
            parseTextResponse(text);
        })
        .catch((e) => {
            console.error(e);
        });
};

function parseTextResponse(text) {
    const html = document.createElement("div");
    html.innerHTML = text;

    //Get HTML from title and recommandations
    const titleElement = html.querySelector(".title");
    const recommendationsElements = html.querySelectorAll(".recommendation");

    renderTitle(titleElement);
    renderRecommandations(recommendationsElements);
}

// Extract title HTML
function renderTitle(titleElement) {
    if (titleElement && titleElement.innerHTML.trim().length) {
        titleHTML.value = titleElement.innerHTML;
    }
}
// Loop trough all recommandations, extract their HTML and push it to `recommendationsHTML`
function renderRecommandations(recommendationsElements) {
    recommendationsElements.forEach((r) => {
        if (r && r.innerHTML.trim().length) {
            recommendationsHTML.value.push(r.innerHTML);
        }
    });
}

onMounted(() => {
    fetchRecommendations();
});

const props = defineProps({
    headingTag: {
        type: String,
        default: "h3",
    },
    dataUrl: {
        type: String,
        required: true,
    },
    sectionId: {
        type: String,
        required: true,
    },
});
</script>

<style lang="scss" scoped>
.c-product-recommandations {
    display: flex;
    flex-direction: column;
    gap: var(--grid-gap-1-5X);
    margin-top: var(--grid-gap-2X);
    padding: var(--grid-gap-2X) 0;

    &__header {
        text-align: center;
    }

    &__items {
        @include min(xl) {
            --carousel-base-slide-width: calc(30% - var(--grid-gap-half));
        }

        :deep(.c-card-product) {
            --card-product-max-width: 100%;
            --card-product-width-default: 100%;
            --card-product-height: 100%;
            --card-product-medias-display: none;
        }
    }
}
</style>
