<template>
    <div
        ref="productMasonry"
        class="c-product-masonery"
        :class="{ '-visible': isMasonryReady }"
    >
        <div
            v-if="masonryWidth !== '0px'"
            :key="masoneryKey"
            v-masonry
            class="c-product-masonery__container"
            transition-duration="0.3s"
            item-selector=".masonry-item"
            stagger="0.03s"
            destroy-delay="0"
        >
            <slot></slot>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, computed } from "vue";

const productMasonry = ref(null);
const masonryWidth = ref("0px");
const masoneryKey = ref(Math.floor(Math.random() * 100) + 100);

const calculateMasonryWidth = () => {
    return productMasonry?.value
        ? `${productMasonry.value.clientWidth}px`
        : "0px";
};

const setMasonryWidth = () => {
    return (masonryWidth.value = calculateMasonryWidth());
};

const isMasonryReady = computed(() => {
    return masonryWidth.value !== "0px";
});

const onResize = () => {
    setMasonryWidth();
};

onMounted(() => {
    window.addEventListener("resize", onResize);

    setTimeout(() => {
        setMasonryWidth();
    }, 100);
});

onBeforeUnmount(() => {
    window.removeEventListener("resize", onResize);
});
</script>
<style lang="scss">
.c-product-masonery {
    --product-masonery-display: none;
    --product-masonery-width: v-bind("masonryWidth");
    --product-masonery-columns: 1;
    --product-masonery-opacity: 0;
    display: var(--product-masonery-display);
    width: calc(100% + var(--grid-gap));
    margin-left: calc(0rem - var(--grid-gap-half));
    opacity: var(--product-masonery-opacity);

    &.-visible {
        --product-masonery-opacity: 1;
    }

    @include min("md") {
        --product-masonery-display: block;
    }

    @include min("lg") {
        --product-masonery-columns: 2;
    }

    &__container {
        list-style-type: none;
        width: 100%;
        margin: 0 auto;

        .masonry-item {
            display: inline-block;
            width: calc(
                var(--product-masonery-width) / var(--product-masonery-columns)
            );
            padding: var(--grid-gap-half);
            opacity: var(--product-masonery-opacity);
        }
    }
}
</style>
