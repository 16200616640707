<template>
    <!-- Template here to prevent "nothing to render" warning -->
</template>

<script setup>
import { onBeforeMount } from "vue";

import { useBoutiquesStore, useToastsStore } from "@vue-stores";
import { useBarbaHook } from "@vue-composables";

const boutiquesStore = useBoutiquesStore();
const toastsStore = useToastsStore();

onBeforeMount(() => {
    // Fetch all boutiques on first website load
    useBarbaHook(boutiquesStore.fetchBoutiques, "afterOnce");

    // Register static toasts
    toastsStore.registerToast({
        name: "add-to-cart",
        component: "ToastAddToCart",
        data: null,
        isOpen: false,
        isClosable: false,
    });
});
</script>
