<template>
    <div class="c-facets-navigation-filters">
        <div class="c-facets-navigation-filters__inner">
            <filters-list-accordion
                v-for="(filter, i) in validFilters"
                :key="`filter-${filter.param_name}-${i}`"
                :label="filter.label"
                :active-values="filter.active_values"
                :expand-on-mount="openedFilters.includes(filter.param_name)"
            >
                <filters-list-filter-item :filter="filter" :settings="getFilterSettings(filter)" />
            </filters-list-accordion>
        </div>
    </div>
</template>

<script setup>
import { computed, toRefs } from "vue";
import FiltersListAccordion from "./components/FiltersListAccordion";
import FiltersListFilterItem from "./components/FiltersListFilterItem";

// Component properties
const props = defineProps({
    filters: {
        type: Object,
        required: true,
    },
});

// Refs
const { filters } = toRefs(props);

/**
 * Array of customizable options to control display of Shopify Built-in `list` filter type.
 * That means that `price_range` and `boolean` would not be affected if added to this variable.
 *
 * @param {string} param_name - The `param_name` from the filter object as the matching key to your customization will be rightly parse by <FiltersListFilterItem>.
 * @param {Boolean} isOpen - Whether the accordion is open or not on init.
 * @param {string} itemType - The type of input you want to use (Will need to be a matching component into <FiltersListFilterItem>’s `availableComponentsForListType`).
 * @param {Boolean} hasSearch - Display (or not) a search input to filter the values.
 *
 * Note: The `param_name` is used over other prop to ensure compatibility on multi-locale shop.
 *
 */
const customFilterSettings = [
    {
        param_name: "filter.p.m.club.age",
        isOpen: true,
        itemType: "checkbox",
        hasSearch: false,
    },
    {
        param_name: "filter.p.m.club.category",
        isOpen: true,
        itemType: "checkbox",
        hasSearch: false,
    },
    {
        param_name: "filter.v.option.color",
        isOpen: true,
        itemType: "swatch",
        hasSearch: false,
    },
    {
        param_name: "filter.v.option.size",
        isOpen: false,
        itemType: "size",
        hasSearch: false,
    },
    {
        param_name: "filter.p.vendor",
        isOpen: true,
        itemType: "checkbox",
        hasSearch: true,
    },
];

/**
 * Computed property that returns an array of opened group labels.
 *
 * @computed {Array.<string>} openedFilters
 * @readonly
 */
const openedFilters = computed(() => customFilterSettings.filter((g) => g.isOpen === true).map((g) => g.param_name));

/**
 * Computed property that returns an array filters that contain more than one value.
 *
 * @computed {Array.<string>} validFilters
 * @readonly
 */
const validFilters = computed(() => filters.value.filter((f) => f.values.length > 1));

/**
 * Returns the group object from `customFilterSettings` that matches the given label.
 *
 * @param {Object} group - The group object to search for, with a `label` property.
 * @returns {Object} The group object from `customFilterSettings` that matches the given label.
 */
function getFilterSettings(group) {
    return customFilterSettings.find((g) => g.param_name === group.param_name);
}
</script>

<style lang="scss" scoped>
.c-facets-navigation-filters {
    &__inner {
    }
}
</style>
