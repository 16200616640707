<template>
    <queso-collapsible class="c-accordion" :expandOnMount="isExpandOnMount" @toggle="(value) => (isOpen = value)">
        <template #headerText>
            <div class="c-accordion__header">
                <slot name="header" />
            </div>
        </template>

        <template #headerIcon>
            <!-- Minus -->
            <icon-base v-if="isOpen" name="moins-sm" :size="2" />
            <!-- Plus -->
            <icon-base v-else name="plus-sm" :size="2" />
        </template>

        <template #content>
            <div class="c-accordion__content">
                <slot name="default" />
            </div>
        </template>
    </queso-collapsible>
</template>

<script setup>
import { computed, ref } from "vue";
import { useMediaQuery } from "@vueuse/core";

import { QuesoCollapsible } from "@allomambo/queso";
import IconBase from "@vue-components/IconBase";

const props = defineProps({
    expandOnMount: {
        type: [Boolean, String],
        required: false,
        default: false,
        validator: (e) => [true, false, "desktop", "mobile"].includes(e),
    },
});

const isOpen = ref(false);

const isDesktop = useMediaQuery("(min-width: 768px)");
const isExpandOnMount = computed(
    () =>
        props.expandOnMount === true ||
        (props.expandOnMount === "desktop" && isDesktop.value) ||
        (props.expandOnMount === "mobile" && !isDesktop.value)
);
</script>

<style lang="scss" scoped>
.c-accordion {
    --accordion-header-spacing-default: 2rem;
    --accordion-header-font-size-default: var(--fs-regular);
    --accordion-header-font-weight-default: 700;

    --accordion-border-width-default: 1px;
    --accordion-border-color-default: var(--color-grey);

    width: 100%;
    border-top: var(--accordion-border-width, var(--accordion-border-width-default)) solid
        var(--accordion-border-color, var(--accordion-border-color-default));
    border-bottom: var(--accordion-border-width, var(--accordion-border-width-default)) solid
        var(--accordion-border-color, var(--accordion-border-color-default));
    margin-bottom: calc(0px - var(--accordion-border-width, var(--accordion-border-width-default)));

    :deep(.queso-collapsible__header) {
        --queso-collapsible-header-justify: space-between;
        gap: var(--grid-gap);
        padding: var(--accordion-header-spacing, var(--accordion-header-spacing-default)) 0;
        user-select: none;
    }

    :deep(.queso-collapsible__header__text) {
        width: 100%;
    }

    .c-accordion__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--grid-gap);
        font-size: var(--accordion-header-font-size, var(--accordion-header-font-size-default));
        font-weight: var(--accordion-header-font-weight, var(--accordion-header-font-weight-default));
    }

    .c-accordion__content {
        padding-bottom: var(--accordion-header-spacing, var(--accordion-header-spacing-default));
    }
}
</style>
