<template>
    <modal-base
        class="l-cart"
        :class="{ 'is-cart-loading': isLoading }"
        type="drawer"
        :name="modalName"
        @onCloseAnimationCompleted="cart.resetErrors()"
    >
        <template #header>
            <slot name="header"></slot>
        </template>

        <template #default>
            <modal-cart-errors :errors="errors" class="l-cart__errors" />
            <div v-if="items.length > 0" class="l-cart__items">
                <modal-cart-item v-for="item in items" :key="item" :item="item" />
            </div>
            <slot v-else name="empty"></slot>
        </template>

        <template v-if="items.length > 0" #footer>
            <slot name="footer" v-bind="{ cartSubTotalPrice, requires_shipping }"></slot>
        </template>
    </modal-base>
</template>

<script setup>
import { computed, onBeforeMount } from "vue";
import { useCartStore, useModalsStore } from "@vue-stores";
import { storeToRefs } from "pinia";
import { moneyFormat } from "@utils";

import ModalBase from "@vue-components/ModalBase";
import ModalCartItem from "./components/ModalCartItem.vue";
import ModalCartErrors from "./components/ModalCartErrors.vue";

// Register modal
const modals = useModalsStore();
const modalName = "cart-modal";

onBeforeMount(() => {
    modals.registerModal({
        component: "ModalCart",
        name: modalName,
    });
});

// Cart
const cart = useCartStore();
const { requires_shipping, items, cart_errors: errors, cart_loading_state: isLoading } = storeToRefs(cart);
const cartSubTotalPrice = computed(() => moneyFormat(cart.items_subtotal_price));
</script>

<style lang="scss">
.l-cart {
    &__items {
        position: relative;
    }

    .c-modal-drawer__content,
    .c-modal-drawer__footer {
        pointer-events: var(--cart-contents-pointer-events, auto);
        opacity: var(--cart-contents-opacity, 1);
        transition: opacity 0.15s ease;
    }

    &.is-cart-loading {
        --cart-contents-pointer-events: none;
        --cart-contents-opacity: 0.3;
    }
}
</style>
