import { defineStore } from "pinia";

import { useModalsStore } from "@vue-stores";
import { computed, ref } from "vue";

const useOverlayStore = defineStore("overlay", () => {
    const modals = useModalsStore();

    const isOverride = ref(false);

    const isVisible = computed(() => isOverride.value || modals.getOpennedModalsCount > 0);

    function show() {
        isOverride.value = true;
    }
    function hide() {
        isOverride.value = false;
    }

    return { isOverride, isVisible, show, hide };
});

export default useOverlayStore;
