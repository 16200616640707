<template>
    <div class="c-variant-selector">
        <fieldset
            v-for="(option, i) in optionsWithValues"
            :key="`${option.name}-${i}`"
            class="c-variant-selector__option"
            :class="{ 'u-visually-hidden': option.values.length <= 1 }"
        >
            <div class="c-variant-selector__label">
                <variant-selector-option-label :option-name="option.name" class="-value" />
                <span class="-value" v-html="getSelectedOptionByIndex(option.position)" />
            </div>

            <div class="c-variant-selector__values">
                <div
                    v-for="(value, i) in option.values"
                    :key="`${option.name}-${value}-${i}`"
                    class="c-variant-selector__values"
                >
                    <variant-selector-button
                        class="c-variant-selector__button"
                        :class="{ 'is-out-of-stock': isOptionDisabled(option, value) }"
                        :is-selected="getSelectedOptionByIndex(option.position) === value"
                        @click="setSelectedOptionValueByPosition(option.position, value)"
                        :disabled="!allowUnavailableSelection && isOptionDisabled(option, value)"
                        v-html="value"
                    />
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script setup>
import { computed, reactive, toRefs, watch, onMounted } from "vue";

import VariantSelectorButton from "./components/VariantSelectorButton";
import VariantSelectorOptionLabel from "./components/VariantSelectorOptionLabel";

const emit = defineEmits(["update"]);

const props = defineProps({
    currentVariant: {
        type: Object,
        required: true,
    },
    optionsWithValues: {
        type: Array,
        required: true,
    },
    product: {
        type: Object,
        required: true,
    },
    allowUnavailableSelection: {
        type: Boolean,
        default: false,
    },
});

const { currentVariant, optionsWithValues, product, allowUnavailableSelection } = toRefs(props);

const selectedOptions = reactive({
    option1: null,
    option2: null,
    option3: null,
});

const unvailableOptions = reactive({
    option1: [],
    option2: [],
    option3: [],
});

const options = computed(() => product.value.options);
const variants = computed(() => product.value.variants);
const availableVariants = computed(() => variants.value.map((v) => v.available));
const availableOptionOneValues = computed(() => variants.value.map((v) => v.option1));

/**
 * Computed property to get the currently selected variant based on the selected options.
 *
 * @computed selectedVariant
 * @type {Object|undefined} - The currently selected variant object, or undefined if no variant matches the selected options.
 * @property {string} selectedVariant.id - The ID of the selected variant.
 * @property {string} selectedVariant.name - The name of the selected variant.
 * @property {number} selectedVariant.price - The price of the selected variant.
 * @property {number} selectedVariant.quantity - The quantity of the selected variant.
 * @property {Object} selectedVariant.options - An object containing the selected options for the variant.
 * @property {string} selectedVariant.options.option1 - The selected value for option 1.
 * @property {string} selectedVariant.options.option2 - The selected value for option 2.
 * @property {string} selectedVariant.options.option3 - The selected value for option 3.
 */
const selectedVariant = computed(() => {
    return variants.value.find((variant) => {
        return !variant.options
            .map((option, index) => {
                return getSelectedOptionByIndex(index + 1) === option;
            })
            .includes(false);
    });
});

/**
 * Determines whether an option is disabled based on the value.
 *
 * @function
 * @param {object} option - The option to check.
 * @param {string|number} value - The value to check against the option.
 * @returns {boolean} - Returns true if the option is disabled, false otherwise.
 */
function isOptionDisabled(option, value) {
    return _getUnvailableOptionByIndex(option.position).includes(value);
}

/**
 * Sets the selected options object with values from the current variant object.
 *
 * @function
 * @name setSelectedOptionsFromCurrentVariant
 * @returns {void}
 */
function setSelectedOptionsFromCurrentVariant() {
    const { option1, option2, option3 } = currentVariant.value;
    selectedOptions.option1 = option1;
    selectedOptions.option2 = option2;
    selectedOptions.option3 = option3;
}

/**
 * Set the availability of option1 by comparing the availableOptionOneValues and availableVariants.
 *
 * @function
 * @name setOptionOneAvailability
 * @returns {void}
 */
function setOptionOneAvailability() {
    const output = [];

    // Push options the output when they are really available (from both availableOptionOneValues and availableVariants)
    availableOptionOneValues.value.forEach((option, index) =>
        availableVariants.value[index] === true ? output.push(option) : null
    );

    // Get difference between the two lists; aka the unavailable options
    const difference = availableOptionOneValues.value.filter((x) => !output.includes(x));

    // Update option1 with the difference array
    _setUnvailableOptionsValuesByPosition(1, difference);
}

/**
 * Updates the status of variants based on selected options.
 *
 * @function
 * @name updateVariantStatuses
 * @returns {void}
 */
function updateVariantStatuses() {
    const selectedOptionOneVariants = variants.value.filter(
        (variant) => getSelectedOptionByIndex(1) === variant.option1
    );

    // Loop through all options group except the first one
    options.value.forEach((option, index) => {
        if (index === 0) return;

        _updateOptionAvailabilityBasedOnSelectedValues(selectedOptionOneVariants, option, index);
    });
}

/**
 * Returns the selected value for a given option.
 *
 * @param {string} option - The name of the option to get the selection for.
 * @returns {*} The selected value for the given option.
 */
const getSelectedOption = (prop) => {
    return selectedOptions[prop];
};

/**
 * Returns the value of the specified unavailable option property.
 *
 * @param {string} prop - The name of the unavailable option property to retrieve.
 * @returns {*} The value of the specified unavailable option property.
 */
const getUnvailableOption = (prop) => {
    return unvailableOptions[prop];
};

/**
 * Sets the value of the selected option at the specified position.
 *
 * @param {number} position - The position of the selected option.
 * @param {any} value - The value to set for the selected option.
 * @returns {void}
 */
const setSelectedOptionValueByPosition = (position, value) => {
    selectedOptions[`option${position}`] = value;
};

/**
 * Returns the selected option for a given option index.
 *
 * @param {number} index - The option index.
 * @returns {*} The selected option.
 */
const getSelectedOptionByIndex = (index) => {
    return getSelectedOption(`option${index}`);
};

///////////// Private methods

/**
 * Sets the availability of an option and updates selected options.
 *
 * @private
 * @param {Array} selectedOptionOneVariants - Array of variants that share the same option1 value.
 * @param {string} optionName - The name of the option to set.
 * @param {number} index - The index of the option.
 * @returns {void}
 */
const _updateOptionAvailabilityBasedOnSelectedValues = (selectedOptionOneVariants, optionName, index) => {
    // Get selected value from previous option (1=0)
    const previousOptionSelected = getSelectedOptionByIndex(index);

    // Get all variants that share the previousOptionSelected value and map options
    const availableOptionInputsValue = _getAvailableOptionInputsValue(
        selectedOptionOneVariants,
        previousOptionSelected,
        index
    );

    // Force the selection of the first available option
    const selectedOptionOne = getSelectedOptionByIndex(index + 1);

    if (allowUnavailableSelection.value === false && !availableOptionInputsValue.includes(selectedOptionOne)) {
        setSelectedOptionValueByPosition(index + 1, availableOptionInputsValue[0]);
    }

    // Update options unavailability
    _updateOptionUnavailability(optionName, availableOptionInputsValue);
};

/**
 * Retrieves the value of an unavailable option by its index.
 *
 * @param {number} index - The index of the unavailable option to retrieve.
 * @returns {*} - The value of the specified unavailable option.
 */
const _getUnvailableOptionByIndex = (index) => {
    return getUnvailableOption(`option${index}`);
};

/**
 * Returns an array of available option inputs value based on the previously selected option value.
 *
 * @private
 * @param {Array} selectedOptionOneVariants - The list of available variants based on the selected option1 value.
 * @param {*} previousOptionSelected - The previously selected option value.
 * @param {number} index - The index of the option.
 * @returns {Array} An array of available option inputs value.
 */
const _getAvailableOptionInputsValue = (selectedOptionOneVariants, previousOptionSelected, index) => {
    return selectedOptionOneVariants
        .filter((variant) => variant.available && variant[`option${index}`] === previousOptionSelected)
        .map((variantOption) => variantOption[`option${index + 1}`]);
};

/**
 * Sets the availability of an option based on the list of available options.
 *
 * @private
 * @param {string} optionName - The name of the option.
 * @param {Array} listOfAvailableOptions - The list of available options.
 * @returns {Array}
 */
const _updateOptionUnavailability = (optionName, listOfAvailableOptions) => {
    //
    // Get all values option
    const option = _getOptionByName(optionName);
    const listOfOptions = option.values;
    const position = option.position;

    // Get difference between the two lists; aka the unavailable options
    const listOfUnvailableOptions = listOfOptions.filter((x) => !listOfAvailableOptions.includes(x));

    _setUnvailableOptionsValuesByPosition(position, listOfUnvailableOptions);
};

/**
 * Retrieve an option from an array of options based on its name property.
 *
 * @private
 * @param {string} optionName - The name of the option to retrieve.
 * @returns {Object} The option object with the specified name.
 */
const _getOptionByName = (optionName) => {
    return optionsWithValues.value.find((option) => option.name === optionName);
};

/**
 * Sets the value of an unavailable option at the given position.
 *
 * @param {number} position - The position of the option to set the value for.
 * @param {any} value - The value to set for the option.
 * @returns {void}
 */
const _setUnvailableOptionsValuesByPosition = (position, value) => {
    unvailableOptions[`option${position}`] = value;
};

// Start
onMounted(() => {
    setSelectedOptionsFromCurrentVariant();
    setOptionOneAvailability();
});

watch(
    selectedVariant,
    (newVariant) => {
        //
        // Update statuses on change (technically when a user click)
        updateVariantStatuses();

        // Emit an `update` only if there’s a value (!= undefined).
        // It avoids a nulled emit since `updateVariantStatuses()`
        // may circle back when the first option is unavailable.
        if (newVariant) {
            emit("update", newVariant);
        }
    },
    { immediate: true }
);
</script>

<style lang="scss" scoped>
.c-variant-selector {
    @include stack("vertical", "left", "top", $gap: var(--grid-gap-1-25X), $var-prefix: "variant-selector");

    &__option {
        @include stack(
            "vertical",
            "left",
            "top",
            $gap: var(--grid-gap-quarter),
            $var-prefix: "variant-selector-option"
        );
        border: none;
    }

    &__label {
        @include stack("horizontal", "left", $gap: var(--grid-gap-half), $var-prefix: "variant-selector-label");

        legend {
            color: var(--color-grey-dark);
            font-weight: 700;
        }
    }

    &__values {
        @include stack("horizontal", "left", $gap: var(--grid-gap-half), $var-prefix: "variant-selector-values");
    }
}
</style>
