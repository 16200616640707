/**
 * Formats money value in cents to a formatted string based on a given format string.
 * @param {Number|String} cents - The money value in cents to be formatted.
 * @param {String} format - Optional. The format string to be used. If not provided, the default theme format will be used.
 * @returns {String|undefined} - The formatted money string or undefined if the cents value is falsy.
 */
function moneyFormat(cents, format) {
    /**
     * Helper function that returns the default value if the provided value is undefined.
     * @param {*} opt - The option value to check.
     * @param {*} def - The default value to be returned if the option is undefined.
     * @returns {*} - The original option value if defined, otherwise the default value.
     */
    function defaultOption(opt, def) {
        return typeof opt == "undefined" ? def : opt;
    }

    /**
     * Helper function that formats a number with delimiters.
     * @param {Number} number - The number to be formatted.
     * @param {Number} precision - Optional. The number of digits after the decimal separator.
     * @param {String} thousands - Optional. The character used as the thousands separator.
     * @param {String} decimal - Optional. The character used as the decimal separator.
     * @returns {String} - The formatted number string.
     */
    function formatWithDelimiters(number, precision, thousands, decimal) {
        precision = defaultOption(precision, 2);
        thousands = defaultOption(thousands, ",");
        decimal = defaultOption(decimal, ".");

        if (isNaN(number) || number == null) {
            return 0;
        }

        number = (number / 100.0).toFixed(precision);

        var parts = number.split("."),
            dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + thousands),
            cents = parts[1] ? decimal + parts[1] : "";

        return dollars + cents;
    }

    // If the cents value is falsy, return undefined
    if (!cents) return;

    // If the cents value is a string, remove the decimal separator
    if (typeof cents == "string") {
        cents = cents.replace(".", "");
    }

    var value = "";
    var placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
    var formatString = format || window.theme.moneyFormat;

    // Format the cents value based on the type of format specified in the format string
    switch (formatString.match(placeholderRegex)[1]) {
        case "amount":
            value = formatWithDelimiters(cents, 2);
            break;
        case "amount_no_decimals":
            value = formatWithDelimiters(cents, 0);
            break;
        case "amount_with_comma_separator":
            value = formatWithDelimiters(cents, 2, ".", ",");
            break;
        case "amount_no_decimals_with_comma_separator":
            value = formatWithDelimiters(cents, 0, ".", ",");
            break;
    }

    // Replace the format placeholder with the formatted value
    return formatString.replace(placeholderRegex, value);
}

export default moneyFormat;
