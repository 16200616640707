import { defineStore } from "pinia";
import { modalRequiredSettingsError } from "@/errors";
import { log } from "@/utils";

/*

    Usage:

    import { useModalsStore } from "@vue-stores";
    const modals = useModalsStore();

    modals.registerModal({
        autoClose: false,
        component: "ModalDefault",
        data: null,
        isOpen: false,
        name: "default-modal",
        type: "popup",
    });

*/

// Define default settings as fallback
const defaultModalSettings = {
    autoClose: false,
    data: {},
    isOpen: false,
    type: "popup", // popup | drawer
};

const useModalsStore = defineStore("modals", {
    state: () => ({
        elements: [],
    }),
    getters: {
        getModalsCount: (state) => state.elements.length,
        getOpennedModalsCount: (getters) => getters.getOpennedModals.length,
        getModalByName: (state) => {
            return (name) => state.elements.find((modal) => modal.name === name);
        },
        getModalIndexByName: (state) => {
            return (name) => state.elements.findIndex((modal) => modal.name === name);
        },
        getOpennedModals: (state) => state.elements.filter((modal) => modal.isOpen === true),
    },
    actions: {
        ////////////////////////////////
        //       START REGISTRATION
        ////////////////////////////////
        registerModal(settings) {
            // Check if required settings are available
            if (!settings.hasOwnProperty("component") || !settings.hasOwnProperty("name")) {
                throw modalRequiredSettingsError;
                return;
            }

            // Check if there’s already a modal with that name. If so, throw an error.
            if (this.getModalByName(settings.name)) {
                log(
                    `A modal name must be unique, ${settings.name} is already registred so it won\`t be registred again.`
                );
                return;
            }

            // Merge settings with default value
            settings = { ...defaultModalSettings, ...settings };

            // Add valid modal object to the list
            this.elements.push(settings);
        },
        eraseModal(modalName) {
            const modalIndexToDelete = this.getModalIndexByName(modalName);
            if (!modalIndexToDelete) {
                log(`A modal with the name ${modalName} doesn't exist. Can't erase it.`);
                return;
            } else {
                this.elements.splice(modalIndexToDelete, 1);
            }
        },
        ////////////////////////////////
        //       END REGISTRATION
        ////////////////////////////////

        ////////////////////////////////
        //       START OPEN AND CLOSE
        ////////////////////////////////
        openModal(name, data = {}) {
            const modal = this.getModalByName(name);

            // Skip if there’s no modal using that name
            if (!modal) return;

            // Open if not already open
            if (modal.isOpen === false) {
                modal.isOpen = true;
            }

            // Refresh `data`
            modal.data = data;
        },
        closeModal(name) {
            const modal = this.getModalByName(name);

            if (modal && modal.isOpen === true) {
                modal.isOpen = false;
                modal.data = {};
            }
        },
        closeAllModals() {
            const opennedModals = this.getOpennedModals;

            if (opennedModals.length) {
                opennedModals.forEach((modal) => this.closeModal(modal.name));
            }
        },
        ////////////////////////////////
        //       END OPEN AND CLOSE
        ////////////////////////////////

        ////////////////////////////////
        //       START UPDATE CURRENT MODAL
        ////////////////////////////////

        //======= START FILL MODAL =======//

        fillModalByName(modalValues) {
            // find the current modal
            const modal = this.getModalByName(modalValues.modalName);
            // fill up data in the modal
            modal ? this.fillModalData(modal, modalValues.values) : null;
        },

        fillModalData(modal, modalValues) {
            modal.data = modalValues;
        },

        //======= END FILL MODAL =======//

        clearModalByName(modalName) {
            // find the current modal
            const modal = this.getModalByName(modalName);
            // clear
            modal ? this.clearModalData(modal) : null;
        },
        clearModalData(modal) {
            modal.data = null;
        },

        ////////////////////////////////
        //       END UPDATE CURRENT MODAL
        ////////////////////////////////
    },
});

export default useModalsStore;
