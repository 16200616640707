import { handleize } from "@/utils";

const mapAPIKey = import.meta.env.VITE_GOOGLE_API_KEY;

export const useGeocode = async (location) => {
    if (!location) {
        console.error("No location provided");
        return false;
    }

    // Get lat/lng from google geocode api
    const encodedLocation = handleize(location);
    const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedLocation}&components=country:CA&key=${mapAPIKey}`
    );
    const responseJSON = await response.json();

    // Cleanup response
    if (responseJSON.status !== "OK") {
        return false;
    } else {
        return responseJSON.results[0];
    }
};
