<template>
    <div class="c-sections-switcher">
        <slot name="before" v-bind="{ currentSection, switchSection }"></slot>

        <div
            v-for="section in sections"
            :key="section"
            class="c-sections-switcher__section"
            v-show="currentSection == section"
        >
            <slot :name="section" v-bind="{ currentSection, sectionID: section, switchSection }"></slot>
        </div>

        <slot name="after" v-bind="{ currentSection, switchSection }"></slot>
    </div>
</template>

<script setup>
import { onMounted, ref, toRef } from "vue";

const props = defineProps({
    sections: {
        type: Array,
        required: true,
        default: () => [],
    },
});

const sections = toRef(props, "sections");
const currentSection = ref(sections.value[0]);

const switchSection = (sectionID) => {
    currentSection.value = sectionID;
};

const getUrlHash = () => {
    let hash = window.location.hash;
    return hash.replace("#", "");
};

onMounted(() => {
    const currentHash = getUrlHash();
    if (sections.value && sections.value.includes(currentHash)) {
        currentSection.value = currentHash;
    }
});
</script>

<style lang="scss" scoped>
.c-sections-switcher {
}
</style>
