<template>
    <div class="c-tooltip" :class="tooltipClasses" @mouseenter="mouseIn" @mouseleave="mouseOut">
        <button class="c-tooltip__dot">
            <span class="c-tooltip__dot__circle"></span>
            <span class="c-tooltip__dot__pulse"></span>
            <span class="u-visually-hidden">{{ title }}</span>
        </button>

        <component :is="url ? 'a' : 'div'" :href="url ? url : null" class="c-tooltip__popup">
            <span v-if="surtitle" class="c-tooltip__popup__surtitle">{{ surtitle }}</span>
            <h3 v-if="title" class="c-tooltip__popup__title">{{ title }}</h3>
            <slot></slot>
        </component>
    </div>
</template>

<script setup>
import { computed, ref, toRef } from "vue";

const props = defineProps({
    title: {
        type: String,
        required: false,
    },
    surtitle: {
        type: String,
        required: false,
    },
    url: {
        type: String,
        required: false,
    },
    x: {
        type: String,
        required: false,
        default: "50%",
        required: false,
    },
    y: {
        type: String,
        required: false,
        default: "50%",
        required: false,
    },
    pulseColor: {
        type: String,
        required: false,
        default: "white",
        validator: (p) => ["white", "blue", "yellow"].includes(p),
    },
});

const tooltipClasses = computed(() => [
    rightAligned.value ? "-valign-right" : "-valign-left",
    bottomAligned.value ? "-halign-top" : "-halign-bottom",
    isHovering.value ? "is-hover" : "",
    `-pulse-${props.pulseColor}`,
]);

// Alignment
const x = toRef(props, "x");
const y = toRef(props, "y");

const rightAligned = computed(() => percentToNumber(x.value) > 50);
const bottomAligned = computed(() => percentToNumber(y.value) < 50);

const percentToNumber = (percent = "50%") => {
    return parseInt(percent.replace("%", ""));
};

// Hover
const isHovering = ref(false);

const mouseIn = () => {
    isHovering.value = true;
};

const mouseOut = () => {
    setTimeout(() => {
        isHovering.value = false;
    }, 200);
};
</script>

<style lang="scss" scoped>
.c-tooltip {
    $_: &;
    --product-tooltip-y: v-bind("y");
    --product-tooltip-x: v-bind("x");
    --product-tooltip-dot-color-default: var(--color-white);
    --product-tooltip-dot-pulse-default: var(--color-white);
    --product-tooltip-dot-size-default: 1rem;
    --product-tooltip-popup-width-default: 25rem;
    --product-tooltip-popup-padding-default: 1.5rem;

    --dot-size: #{dvar(product-tooltip-dot-size)};
    --dot-shadow-size: calc(var(--dot-size) * 3.4);
    --dot-x: calc(var(--product-tooltip-x) - (var(--dot-shadow-size) * 0.5));
    --dot-y: calc((100% - var(--product-tooltip-y)) - (var(--dot-shadow-size) * 0.5));
    --popup-offset-x: var(--grid-gap-third);
    --popup-offset-y: var(--grid-gap-1-25X);
    --popup-translate-y: 1rem;

    @include stack("vertical", "left", "top", $gap: var(--popup-offset-y), $var-prefix: "product-tooltip");
    position: absolute;
    bottom: var(--dot-y);
    left: var(--dot-x);
    z-index: 2;

    @include between(md, xl) {
        --product-tooltip-popup-width-default: 18rem;
        --product-tooltip-popup-padding-default: 1rem;
    }

    @include min(md) {
        &.-valign-right {
            --product-tooltip-align: flex-end;
            --popup-margin-left: 0;
            --popup-margin-right: var(--popup-offset-x);
            right: calc((100% - var(--product-tooltip-x)) - (var(--dot-shadow-size) * 0.5));
            left: auto;
        }

        &.-halign-top {
            --popup-translate-y: -1rem;
            --popup-order: 1;
            top: calc(var(--product-tooltip-y) - (var(--dot-shadow-size) * 0.5));
            bottom: auto;
        }
    }

    @include max(md) {
        position: static;
    }

    &.is-hover {
        --popup-translate-y: 0;
        --popup-opacity: 1;
        --popup-pointer-events: all;
    }

    //-- Dot --//
    &.-pulse-blue {
        --product-tooltip-dot-pulse-default: var(--color-accent-blue);
    }
    &.-pulse-yellow {
        --product-tooltip-dot-pulse-default: var(--color-accent-yellow);
    }

    &__dot {
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--dot-shadow-size);
        height: var(--dot-shadow-size);
        position: relative;
        background-color: rgba(white, 0.4);
        border-radius: 100%;

        &__circle {
            width: var(--dot-size);
            height: var(--dot-size);
            position: relative;
            z-index: 2;
            border-radius: 100%;
            background-color: dvar(product-tooltip-dot-color);
        }

        &__pulse {
            @include unselectable;
            @include fullscreen;
            z-index: 1;
            border-radius: 100%;
            background-color: dvar(product-tooltip-dot-pulse);
            opacity: 0;
            transform: scale(2.5);
            animation: 3s infinite pulsation;

            @keyframes pulsation {
                0% {
                    opacity: 1;
                    transform: scale(0);
                }
                100% {
                    opacity: 0;
                    transform: scale(2.5);
                }
            }
        }

        @include max(md) {
            position: absolute;
            bottom: var(--dot-y);
            left: var(--dot-x);
            z-index: 2;
        }
    }

    //-- Popup content --//
    &__popup {
        @include stack(vertical, "left", "top", $gap: 0.5rem, $var-prefix: "product-tooltip-popup");
        width: dvar(product-tooltip-popup-width);
        padding: dvar(product-tooltip-popup-padding);
        order: var(--popup-order, -1);
        background-color: var(--color-white);
        box-shadow: 0.1rem 0.1rem 1rem 0 rgba(map-get($colors, "blue-dark"), 0.1);
        border-radius: var(--radius-regular);
        transform: translateY(var(--popup-translate-y));
        opacity: var(--popup-opacity, 0);
        pointer-events: var(--popup-pointer-events, none);
        transition: opacity 0.2s ease, transform 0.2s ease;

        @include max(md) {
            --product-tooltip-popup-width-default: auto;
            position: absolute;
            left: 1rem;
            right: 1rem;

            #{$_}.-halign-top & {
                top: auto;
                bottom: 1rem;
            }
            #{$_}.-halign-bottom & {
                top: 1rem;
                bottom: auto;
            }
        }

        @include min(md) {
            margin-left: var(--popup-margin-left, var(--popup-offset-x));
            margin-right: var(--popup-margin-right, 0);
        }

        &__title {
            font-size: var(--fs-regular);
            font-weight: normal;
            line-height: 1.15;
        }

        &__surtitle {
            font-size: var(--fs-regular);
            font-weight: 700;
            line-height: 1.15;
        }
    }
}
</style>
