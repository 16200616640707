<template>
    <div
        class="c-price"
        :class="{
            'is-sold-out': !available,
            'is-on-sale': compare_at_price > price,
        }"
    >
        <template v-if="compare_at_price > price">
            <span class="c-price__item -sale">
                {{ moneyFormat(price) }}
            </span>
            <span class="c-price__item -regular">
                {{ moneyFormat(compare_at_price) }}
            </span>
        </template>

        <template v-else>
            <span class="c-price__item -regular">
                {{ moneyFormat(price) }}
            </span>
        </template>
    </div>
</template>

<script setup>
import { computed, toRefs } from "vue";
import { moneyFormat } from "@utils";

const props = defineProps({
    variant: {
        type: Object,
        required: true,
    },
});

const { variant } = toRefs(props);

const available = computed(() => variant.value.available);
const price = computed(() => variant.value.price);
const compare_at_price = computed(() => variant.value.compare_at_price);
</script>

<style lang="scss" scoped></style>
