import barba from "@barba/core";

export function useBarbaHook(fn, lifecycle = "afterLeave") {
    let barbaData = null;

    barba.hooks[lifecycle]((data) => {
        barbaData = data;
        fn();
    });

    return { barbaData };
}
