<template>
    <div :class="classes">
        <input
            :type="type"
            class="c-field__input"
            :value="modelValue"
            :placeholder="placeholder"
            @input="$emit('update:modelValue', $event.target.value)"
        />
        <label v-if="label" v-html="label" class="c-field__label" />
    </div>
</template>

<script setup>
import { computed, ref } from "vue";

defineEmits(["update:modelValue"]);

const classes = computed(() => {
    let output = "c-field";

    output += ` ${props.type}`;

    if (props.isFull) output += " is-full";
    if (props.label) output += " | has-label";

    return output;
});

const props = defineProps({
    modelValue: {
        type: [String, Number, Boolean],
        required: false,
        default: null,
    },
    type: {
        type: String,
        required: false,
        default: "text",
    },
    label: {
        type: String,
        required: false,
        default: null,
    },
    isFull: {
        type: Boolean,
        default: false,
        default: false,
    },
    placeholder: {
        type: String,
        required: false,
        default: null,
    },
});
</script>

<style lang="scss" scoped></style>
