<template>
    <Teleport to="body">
        <div class="c-overlay" :class="{ 'is-visible': isVisible }" v-bind="$attrs">
            <slot></slot>
        </div>
    </Teleport>
</template>

<script setup>
import { toRef, watch } from "vue";
import { useDocumentScrollLock } from "@vue-composables";

const props = defineProps({
    visible: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["overlay:visible", "overlay:hidden"]);

const isVisible = toRef(props, "visible");
const isScrollLock = useDocumentScrollLock(isVisible);

// Update visibility
watch(
    isVisible,
    (overlayVisible) => {
        if (overlayVisible) {
            emit("overlay:visible");
        } else {
            emit("overlay:hidden");
        }
    },
    { immediate: true }
);
</script>

<style lang="scss">
.c-overlay {
    @include fullscreen(fixed);
    @include unselectable;
    opacity: var(--overlay-visibility, 0);
    background-color: var(--overlay-bg-color, var(--color-blue-light));
    z-index: var(--overlay-z, calc(var(--z-modal) - 1));
    transition: opacity 0.25s ease;

    &.is-visible {
        @include unselectable(false);
        --overlay-visibility: var(--overlay-opacity, 0.8);
    }
}
</style>
