const getCleanProductTitle = (title, delimiter = " - ", popOnce = false) => {
    if (!title) return;

    const titleParts = title.split(delimiter);
    if (titleParts.length < 3) return title; // If there are not enough parts, return the original title

    // Remove product number and color
    titleParts.pop();
    if (!popOnce) {
        titleParts.pop();
    }

    const cleanedTitle = titleParts.join(delimiter);

    return cleanedTitle;
};

export default getCleanProductTitle;
