<template>
    <form class="c-boutiques-search" @submit.prevent="searchBoutique">
        <div class="c-boutiques-search__fields">
            <input-base
                :placeholder="getDescription('boutiques.search.placeholder')"
                name="address"
                v-model="modelLocation"
            />
            <base-button type="submit" :is-loading="isLoading">
                <template #label>
                    {{ getDescription("boutiques.search.submit_label") }}
                </template>
            </base-button>
        </div>

        <div
            v-if="hasError"
            class="c-boutiques-search__error-message | c-warning"
            :aria-hidden="!hasError"
        >
            {{ getDescription("boutiques.search.error") }}
        </div>
    </form>
</template>

<script setup>
import { ref } from "vue";
import { useBoutiquesStore } from "@vue-stores";
import { useTranslationDescriptions } from "@vue-composables";

import InputBase from "@vue-components/InputBase";
import BaseButton from "@vue-components/BaseButton";

const { getDescription } = useTranslationDescriptions();

const boutiquesStore = useBoutiquesStore();

const isLoading = ref(false);
const hasError = ref(false);
const modelLocation = ref(boutiquesStore.location?.literal);

const searchBoutique = async () => {
    isLoading.value = true;
    hasError.value = false;
    const response = await boutiquesStore.updateLocation(modelLocation.value);

    if (!response) {
        hasError.value = true;
    }
    isLoading.value = false;
};
</script>

<style lang="scss" scoped>
.c-boutiques-search {
    --boutiques-search-fields-gap: var(--grid-gap);

    &__fields {
        --field-wrap-width: 32rem;
        @include stack($var-prefix: "boutiques-search-fields");

        @include max(sm) {
            --field-wrap-width: 100%;
            --button-width: 100%;
        }
    }

    &__error-message {
        flex-basis: 100%;
        margin-top: var(--boutiques-search-fields-gap);
    }
}
</style>
