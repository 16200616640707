import { useToastsStore } from "@vue-stores";

export function useAddToCartToast() {
    const toastName = "add-to-cart";
    const { openToast, closeToast, fillToastDataByName, clearToastDataByName } = useToastsStore();

    function fill(product, variant) {
        fillToastDataByName(toastName, { product, variant });
    }

    function open() {
        openToast(toastName);
    }
    function close() {
        closeToast(toastName);
    }

    function clear() {
        clearToastDataByName(toastName);
    }

    return { fill, open, close, clear };
}
