<template>
    <legend v-html="translatedLabel" />
</template>

<script setup>
import { computed, toRefs } from "vue";
import { handleize } from "@/utils";

const props = defineProps({
    optionName: {
        type: String,
        required: true,
    },
});

const { optionName } = toRefs(props);

const translatedLabel = computed(() => {
    const name = handleize(optionName.value);
    const description_string = window.theme.strings.product[name];

    if (!description_string || description_string.includes("translation missing")) {
        return optionName.value;
    }

    return description_string;
});
</script>
