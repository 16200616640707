import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { useGeocode, useGetBoutiques, useNearestBoutiques } from "@vue-composables";
import { formatPhoneNumber } from "@/utils";

const locationEmpty = {
    literal: "",
    position: {
        lat: "",
        lng: "",
    },
    geocode: null,
};

const useBoutiquesStore = defineStore("boutiques", {
    state: () => ({
        last_fetch: useStorage("stores.last_fetch", 0),
        boutiques: useStorage("stores.boutiques", []),
        location: useStorage("stores.location", locationEmpty),
    }),
    getters: {
        getBoutiquesCount: (state) => {
            return state.boutiques.length;
        },
        getBoutiqueByID: (state) => {
            return (id) => state.boutiques.find((boutique) => boutique.shop_id == id);
        },
    },
    actions: {
        async fetchBoutiques() {
            const currentTime = Date.now();
            const oneDay = 24 * 60 * 60 * 1000;
            // If last fetch was less than 24h ago, don't fetch
            if (this.last_fetch != 0 && Math.abs(currentTime - this.last_fetch) < oneDay) return;

            // Get all boutiques
            const boutiquesJSON = await useGetBoutiques();

            // Data mutations before injection
            boutiquesJSON.forEach((boutique) => {
                boutique.phone = formatPhoneNumber(boutique.phone);
            });

            // Refresh boutiques array
            this.$patch({
                last_fetch: currentTime,
                boutiques: boutiquesJSON,
            });
        },
        async updateLocation(updatedLocation) {
            if (!updatedLocation) {
                this.resetBoutiques();
                return true;
            }

            // Get lat/lng from google geocode api
            const geocoding = await useGeocode(updatedLocation);

            // ERROR, invalid location
            if (!geocoding) {
                console.error("Invalid location.");
                this.resetBoutiques();
                return false;
            }

            // VALID, so refresh location and update distance
            this.$patch({
                location: {
                    literal: updatedLocation,
                    position: geocoding.geometry.location,
                    geocode: geocoding,
                },
            });

            this.updateBoutiquesDistance(updatedLocation);

            return true;
        },
        async updateBoutiquesDistance(location) {
            const distancesJSON = await useNearestBoutiques(location);

            // Update each boutique's distance
            distancesJSON.forEach((boutique) => {
                const distance = boutique.distance;
                const i = this.boutiques.findIndex(({ shop_id }) => shop_id === boutique.shop_id);
                this.boutiques[i].distance = distance >= 10 ? Math.trunc(distance) : distance.toFixed(1);
            });

            // Re-order by distance (nearest to farthest)
            this.sortBoutiques("distance");
        },
        sortBoutiques(property = "shop_id") {
            this.boutiques.sort((a, b) => a[property] - b[property]);
        },
        resetBoutiques() {
            this.boutiques.forEach((boutique) => {
                delete boutique.distance;
            });

            this.location = locationEmpty;

            this.sortBoutiques("shop_id");
        },
    },
});

export default useBoutiquesStore;
