<template>
    <modal-base
        class="c-modal-video"
        type="popup"
        :name="name"
        @onOpenAnimationCompleted="play"
        @onClose="pause"
    >
        <video
            v-if="video?.duration > 0"
            ref="videoElem"
            class="c-modal-video__video"
            :poster="poster"
            controls
        >
            <source :src="src" type="video/mp4" />
        </video>
    </modal-base>
</template>

<script setup>
import { computed, onBeforeMount, onUnmounted, ref } from "vue";
import { useModalsStore } from "@vue-stores";

import ModalBase from "@vue-components/ModalBase";

const props = defineProps({
    name: {
        type: String,
        required: true,
        default: "",
    },
    video: {
        type: Object,
        required: true,
        default: () => {},
    },
});

// Register modal
const modals = useModalsStore();

onBeforeMount(() => {
    modals.registerModal({
        component: "ModalVideo",
        name: props.name,
    });
});

onUnmounted(() => {
    modals.eraseModal(props.name);
});

// Video
const videoElem = ref(null);

const src = computed(() => {
    const mp4 = props.video?.sources.filter(({ format }) => format == "mp4");
    mp4.sort((a, b) => b.width - a.width);
    return mp4[0].url;
});
const poster = computed(() => props.video?.preview_image?.src);

// Methods
const play = () => {
    videoElem.value.play();
};

const pause = () => {
    videoElem.value.pause();
};
</script>

<style lang="scss">
.c-modal-video {
    --modal-popup-padding: 0;
    --modal-popup-bg-color: var(--color-black);

    @include min(md) {
        --modal-popup-radius: var(--radius-medium);
    }

    &__video {
        display: block;
        width: 100%;
        aspect-ratio: 1 / 0.77;
        object-fit: contain;
    }

    .queso-scrollable__content {
        &:after {
            display: none;
        }
    }
}
</style>
