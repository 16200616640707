<template>
    <modal-base :disable-teleport="true" :name="modalName" type="drawer" class="c-facets-navigation-filters-modal">
        <template #header>
            <slot name="modal-header">
                <slot name="modal-header-icon" />
                <h2 class="modal-title">
                    <span v-html="getDescription('products.facets.filters')" />
                    <span v-if="activeValuesCount" v-html="activeValuesCount" class="c-badge-number" />
                </h2>
            </slot>
        </template>

        <template #default>
            <slot />
        </template>

        <template #footer="{ close }">
            <div class="c-facets-navigation-filters-modal__actions">
                <button
                    @click="clearAllFilters"
                    type="button"
                    class="c-button is-outline | -clear"
                    :disabled="!activeValuesCount"
                >
                    <span class="c-button__label">{{ getDescription("products.facets.clear") }}</span>
                </button>
                <button @click="close" type="button" class="c-button -color-blue is-full | -apply">
                    <span class="c-button__label">{{ getDescription("products.facets.apply") }}</span>
                </button>
            </div>
        </template>
    </modal-base>
</template>

<script setup>
import { inject, onBeforeMount, toRefs } from "vue";
import { useModalsStore } from "@vue-stores";
import { useTranslationDescriptions } from "@vue-composables";

import ModalBase from "@vue-components/ModalBase";

const modals = useModalsStore();
const { getDescription } = useTranslationDescriptions();

// Register modal
onBeforeMount(() => {
    modals.registerModal({
        component: "FacetsNavigationFiltersModal",
        name: modalName,
    });
});

const { modalName } = toRefs(props);
const activeValuesCount = inject("activeValuesCount");

function clearAllFilters() {
    emit("clearAllFilters");
}

const emit = defineEmits(["clearAllFilters"]);

const props = defineProps({
    modalName: {
        type: String,
        required: true,
    },
});
</script>

<style lang="scss">
.c-facets-navigation-filters-modal {
    &__actions {
        display: flex;
        gap: var(--grid-gap-three-quarter);

        .c-button {
            &.-clear {
                flex-shrink: 0;
            }
        }
    }

    .modal-title {
        display: flex;
        align-items: center;
        gap: var(--grid-gap-half);
        height: 4rem; /* Fake bad-number height to avoid any height change */
        @include h(h5);
    }
}
</style>
