/**
 * Returns a debounced version of the given function that delays execution until the specified time has elapsed since the last invocation
 *
 * @param {function} fn - The function to debounce
 * @param {number} wait - The number of milliseconds to wait before invoking the function after the last call
 * @returns {function} A debounced version of the function
 */
function debounce(fn, wait) {
    let t;
    return (...args) => {
        clearTimeout(t);
        t = setTimeout(() => fn.apply(this, args), wait);
    };
}
export default debounce;
