/**
 * Logs data to the console if the environment is development mode.
 *
 * @param {any} data - Data to be logged to the console.
 */
const log = (data) => {
    const env = import.meta.env.MODE;
    const isDev = env == "dev" || env == "development";

    if (!isDev) {
        return;
    }

    console.log(
        `%c🫓 ${window.Arepa.name} ${window.Arepa.edition} ${window.Arepa.version}`,
        "color: #2C68D6; font-weight: bold",
        data
    );
};

export default log;
