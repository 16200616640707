<template>
    <div class="c-modal-drawer">
        <slot name="closeButton"></slot>

        <div class="c-modal-drawer__header">
            <slot name="header"></slot>
        </div>

        <QuesoScrollable shadows class="c-modal-drawer__content">
            <slot></slot>
        </QuesoScrollable>

        <div v-if="$slots.footer" class="c-modal-drawer__footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script setup>
import { QuesoScrollable } from "@allomambo/queso";
import { ANIMATION_DURATIONS } from "@/constants";

const transitionDuration = {
    in: `${ANIMATION_DURATIONS.drawer.in}s`,
    out: `${ANIMATION_DURATIONS.drawer.out}s`,
};
</script>

<style lang="scss">
.c-modal-drawer {
    --modal-drawer-width-default: 48.5rem;
    --modal-drawer-padding-x-default: var(--grid-gap);
    --modal-drawer-padding-y-default: var(--grid-gap-two-third);
    --modal-drawer-gap-default: var(--modal-drawer-padding-y-default);
    --modal-drawer-gutter-default: var(--grid-gap-quarter);
    --modal-drawer-radius-default: var(--radius-medium);
    --modal-drawer-transition-duration: v-bind("transitionDuration.out");

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--modal-drawer-gap, var(--modal-drawer-gap-default));
    width: var(--modal-drawer-width, var(--modal-drawer-width-default));
    max-width: calc(100% - var(--modal-drawer-gutter, var(--modal-drawer-gutter-default)));
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: var(
        --modal-drawer-padding,
        var(--modal-drawer-padding-y, var(--modal-drawer-padding-y-default))
            var(--modal-drawer-padding-x, var(--modal-drawer-padding-x-default))
    );
    background-color: var(--color-white);
    border-top-left-radius: var(--modal-drawer-radius, var(--modal-drawer-radius-default));
    border-bottom-left-radius: var(--modal-drawer-radius, var(--modal-drawer-radius-default));
    transform: translateX(var(--modal-drawer-translate, calc(100% + 1px)));
    transition: transform var(--modal-drawer-transition-duration) $in-out-quint;
    overflow: auto;

    @include min(md) {
        --modal-drawer-padding-x-default: var(--grid-gap-1-5X);
        --modal-drawer-padding-y-default: var(--grid-gap-1-25X);
        --modal-drawer-radius-default: var(--radius-large);
    }

    .c-modal-close-button {
        position: absolute;
        top: var(--modal-drawer-padding-y, var(--modal-drawer-padding-y-default));
        right: calc(
            var(--modal-drawer-padding-x, var(--modal-drawer-padding-x-default)) *
                var(--modal-drawer-close-button-right-ratio, 0.75)
        );
        z-index: 1;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--grid-gap-two-third);

        @include min(md) {
            flex-direction: column;
        }

        @include max(md) {
            padding-right: var(--modal-drawer-header-padding-right, 4rem); // Size of close button
        }

        .c-icon {
            flex-shrink: 0;
        }

        .t-h5 {
            @include min(xl) {
                font-size: 3rem;
            }
        }
    }

    &__content {
        --overflow-shadow-color: var(--color-white);
        --overflow-shadow-size: 9rem;
        flex: 1;

        &:before,
        &:after {
            transition: opacity 0.2s ease;
        }
    }

    &__footer {
    }

    // OPENED
    .c-modal.is-open & {
        --modal-drawer-translate: 0;
        --modal-drawer-transition-duration: v-bind("transitionDuration.in");
    }
}
</style>
