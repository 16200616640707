<template>
    <div class="c-card-compact type-product">
        <div v-if="item.featured_image?.url" class="c-card-compact__image">
            <img :src="imageSrc1x" :srcset="`${imageSrc1x} 1x, ${imageSrc2x} 2x`" :alt="item.featured_image.alt" />
        </div>

        <div class="c-card-compact__content">
            <h3 class="c-card-compact__heading">
                <a :href="item.url" v-html="getCleanProductTitle(item.product_title, ' - ', true)" />
            </h3>

            <div v-if="item.options_with_values" class="c-card-compact__options">
                <span
                    v-for="option in item.options_with_values"
                    :key="`${option.name}-${option.value}`"
                    class="c-card-compact__options__item"
                >
                    {{ option.value }}
                </span>
            </div>

            <quantity-input
                :min="item.quantity_rule?.min || 1"
                :max="item.quantity_rule?.max || 10"
                :step="item.quantity_rule?.increment || 1"
                :model-value="item.quantity"
                @update:modelValue="(newQuantity) => updateQuantity(item.key, newQuantity)"
            >
                <template #minus>
                    <span class="c-icon" style="--width: 1.8rem; --rotation: 0deg">
                        <svg role="presentation">
                            <use href="#icon-moins-sm"></use>
                        </svg>
                    </span>
                </template>
                <template #plus>
                    <span class="c-icon" style="--width: 1.8rem; --rotation: 0deg">
                        <svg role="presentation">
                            <use href="#icon-plus-sm"></use>
                        </svg>
                    </span>
                </template>
            </quantity-input>
        </div>

        <div class="c-card-compact__slot -after">
            <div class="c-card-compact__price">
                <price-tag
                    :price="isOnSale ? moneyFormat(priceOriginal) : moneyFormat(item.price)"
                    :sale="isOnSale ? moneyFormat(item.price) : null"
                />
            </div>

            <button class="c-button-plain -color-grey-dark" @click="removeLine(item.key)">
                <span class="c-button-plain__label">Retirer</span>
            </button>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useCartStore } from "@vue-stores";

import { cropImage, getCleanProductTitle, moneyFormat } from "@utils";

import PriceTag from "@vue-components/PriceTag";

const props = defineProps({
    item: Object,
});

// Cart
const cart = useCartStore();

const updateQuantity = (lineKey, lineQuantity) => {
    cart.updateLineQuantity(lineKey, lineQuantity);
};

const removeLine = (lineKey) => {
    cart.removeLine(lineKey);
};

// Images
const imageSrc1x = computed(() => {
    return cropImage(props.item.featured_image.url, 60, 60);
});
const imageSrc2x = computed(() => {
    return cropImage(props.item.featured_image.url, 120, 120);
});

// Price
const priceOriginal = computed(() =>
    props.item.properties["Prix original"] ? props.item.properties["Prix original"].replace(/\D/g, "") : 0
);
const isOnSale = computed(() => priceOriginal.value > props.item.price);
</script>

<style lang="scss"></style>
