<template>
    <filter-value-base
        :type="type"
        :value="value"
        class="c-filter-value-swatch"
        :class="`color-${color}`"
        :data-swatch="`color-${color}`"
    />
</template>

<script setup>
import { computed, toRefs } from "vue";

import { handleize } from "@/utils";

import FilterValueBase from "../FilterValueBase";

const { value, type } = toRefs(props);

const color = computed(() => handleize(value.value.value));

const props = defineProps({
    value: {
        type: Object,
        required: true,
    },
    type: {
        type: String,
        default: "checkbox",
    },
});
</script>

<style lang="scss" scoped>
/*
 *
 *  See `/src/assets/styles/02.base/_colors.scss` to see the complete list or to add colors
 *
 */
.c-filter-value-swatch {
    --filter-value-swatch-width-default: 4rem;
    --filter-value-swatch-height-default: var(--filter-value-swatch-width-default);

    --filter-value-swatch-border-color-default: var(--color-grey);
    --filter-value-swatch-border-radius-default: var(--radius-small);
    --filter-value-swatch-active-border-color-default: var(--color-black);
    --filter-value-swatch-inset-color-default: var(--color-white);
    --filter-value-swatch-inset-size-default: 0.4rem;

    :deep(label) {
        display: none;
    }
    :deep(input) {
        --filter-value-swatch-shadow-color: var(
            --filter-value-swatch-border-color,
            var(--filter-value-swatch-border-color-default)
        );
        --filter-value-swatch-border-width: 0px;

        display: block;
        width: var(--filter-value-swatch-width, var(--filter-value-swatch-width-default));
        height: var(--filter-value-swatch-height, var(--filter-value-swatch-height-default));
        aspect-ratio: 1;

        background: var(--data-swatch-color);
        box-shadow: 0 0 0 1px var(--filter-value-swatch-shadow-color);
        border: var(--filter-value-swatch-border-width) solid
            var(--filter-value-swatch-inset-color, var(--filter-value-swatch-inset-color-default));
        border-radius: var(--filter-value-swatch-border-radius, var(--filter-value-swatch-border-radius-default));

        margin: 1px; /* UX Fix to avoid any cutout in the shadow */
        appearance: none;
        overflow: hidden;

        transition: border 0.1s ease, box-shadow 0.3s ease;

        &:checked {
            --filter-value-swatch-shadow-color: var(
                --filter-value-swatch-active-border-color,
                var(--filter-value-swatch-active-border-color-default)
            );
            --filter-value-swatch-border-width: var(
                --filter-value-swatch-inset-size,
                var(--filter-value-swatch-inset-size-default)
            );
        }
    }
}
</style>
