/**
 * Custom Elements to ensure fetch or rendered html that contains Vue Component declaration are correctly interpreted by the broswers
 */

import { defineCustomElement } from "vue";
import OpenQuickView from "@/vue/components/OpenQuickView/OpenQuickView.vue";

function createCustomElements() {
    const customOpenQuickView = defineCustomElement(OpenQuickView);
    customElements.define("open-quick-view", customOpenQuickView);
}

export { createCustomElements };
