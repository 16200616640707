<template>
    <div class="c-modal-quick-view-image">
        <div v-if="selectedMedia" class="c-modal-quick-view-image__inner">
            <div class="c-modal-quick-view-image__inner__image">
                <img
                    :src="fallBackImage(selectedMedia.src, 450)"
                    :srcset="`${fallBackImage(
                        selectedMedia.src,
                        450
                    )} 1x, ${fallBackImage(selectedMedia.src, 900)} 2x`"
                />
            </div>
            <div
                v-if="medias.length"
                class="c-modal-quick-view-image__inner__carousel"
            >
                <carousel-base :options="carouselOptions">
                    <button
                        v-for="(media, index) in medias"
                        :key="`variant-image-${index}`"
                        class="c-modal-quick-view-image__inner__carousel__asset"
                        :class="{ '-active': isItemActive(media) }"
                        @click="userClicked(media)"
                    >
                        <img
                            :src="fallBackImage(media.src, 100)"
                            :srcset="`${fallBackImage(
                                media.src,
                                100
                            )} 1x, ${fallBackImage(media.src, 200)} 2x`"
                            loading="lazy"
                        />
                    </button>
                </carousel-base>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, watch, onMounted } from "vue";
import CarouselBase from "@vue-components/CarouselBase";

const props = defineProps({
    medias: {
        type: Array,
        required: false,
        default: () => [],
    },
    isSelectedEmitable: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const fallBackImage = (media, maxSize) => {
    return media
        ? `${media}&width=${maxSize}&height=${maxSize}&crop=center`
        : new URL(`@/assets/images/fallback.jpeg`, import.meta.url).href;
};
const carouselOptions = { align: "prev" };

////////////////////////////////
//       START SELECTED MEDIA
////////////////////////////////

onMounted(() => {
    updateSelectedMedia(props.medias[0]);
});

watch(
    () => props.media,
    () => {
        props.media.length ? updateSelectedMedia(props.medias[0]) : null;
    }
);
////////////////////////////////
//       END SELECTED MEDIA
////////////////////////////////

////////////////////////////////
//       START USER UPDATE SELECTED ITEM
////////////////////////////////
const selectedMedia = ref(null);

const isItemActive = (media) => {
    return selectedMedia.value && media.id === selectedMedia.value.id;
};
const userClicked = (media) => {
    props.isSelectedEmitable ? emitToParent(media) : updateSelectedMedia(media);
};
const updateSelectedMedia = (media) => {
    selectedMedia.value = media;
};

/*------------------------------
Start EMIT 
------------------------------*/
// needed if carousel can update selected variant
const emit = defineEmits(["updateVariant"]);
const emitToParent = () => {
    emit("updateVariant", variant);
};
/*------------------------------
End EMIT 
------------------------------*/
////////////////////////////////
//       END USER UPDATE SELECTED ITEM
////////////////////////////////
</script>
<style lang="scss" scoped>
.c-modal-quick-view-image {
    --radius-mobile-category-card: var(--radius-small);
    width: 100%;

    &__inner {
        @include stack("vertical", $gap: var(--grid-gap-three-quarter));

        &__image {
            @include squareImage;
            border-radius: var(--radius-medium);
        }

        &__carousel {
            width: 100%;
            &__asset {
                --carousel-base-margin-right: var(--grid-gap-three-quarter);
                --carousel-asset-border-color: var(--color-grey-light);
                @include squareImage;
                width: 10rem;
                border: 2px solid var(--carousel-asset-border-color);

                &.-active {
                    --carousel-asset-border-color: var(--color-blue-dark);
                }
            }
        }
    }
}
</style>
