<template>
    <queso-clickable :class="btnClasses">
        <span class="c-button-count__label">
            <slot name="label">{{ label }}</slot>
        </span>
        <span class="c-button-count__count c-badge-number">
            <slot name="count">{{ count }}</slot>
        </span>
    </queso-clickable>
</template>

<script setup>
import { computed } from "vue";
import { QuesoClickable } from "@allomambo/queso";

const props = defineProps({
    label: {
        type: String,
        required: true,
        default: "",
    },
    count: {
        type: [String, Number],
        required: true,
        default: null,
    },
    color: {
        type: String,
        required: false,
        default: "blue",
        validator: (c) => ["blue", "yellow"].includes(c),
    },
    isActive: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const btnClasses = computed(() => ["c-button-count", `-color-${props.color}`, props.isActive && "is-active"]);
</script>

<style lang="scss" scoped>
// src/assets/styles/08.components/_button-count.scss
</style>
