<template>
    <div class="c-field number">
        <label v-if="label" class="c-field__label">{{ label }}</label>
        <div class="c-field__controls">
            <button
                class="-minus"
                type="button"
                tabindex="-1"
                :disabled="disabled || readonly || !decreasable"
                @click.prevent="decrease"
            >
                <slot name="minus">
                    <icon-base name="moins-sm" :size="2" />
                </slot>
            </button>
            <input
                class="c-field__input"
                ref="input"
                v-bind="attrs"
                type="number"
                :name="name"
                :value="isNaN(value) ? '' : value"
                :min="min"
                :max="max"
                :step="step"
                :readonly="readonly || !inputtable"
                :disabled="disabled || (!decreasable && !increasable)"
                autocomplete="off"
                @change="change"
                @paste="paste"
            />
            <button
                class="-plus"
                type="button"
                tabindex="-1"
                :disabled="disabled || readonly || !increasable"
                @click.prevent="increase"
            >
                <slot name="plus">
                    <icon-base name="plus-sm" :size="2" />
                </slot>
            </button>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, toRefs, watch } from "vue";
import { watchDebounced } from "@vueuse/core";
import { ON_CHANGE_DEBOUNCE_TIMER } from "@/constants";

// Helpers
const isNaN = Number.isNaN || window.isNaN;
const REGEXP_NUMBER = /^-?(?:\d+|\d+\.\d+|\.\d+)(?:[eE][-+]?\d+)?$/;
const REGEXP_DECIMALS = /\.\d*(?:0|9){10}\d*$/;
const normalizeDecimalNumber = (value, times = 100000000000) =>
    REGEXP_DECIMALS.test(String(value)) ? Math.round(value * times) / times : value;

const value = ref(NaN);
const input = ref();

const { min, max, step, rounded, modelValue } = toRefs(props);

/**
 * Indicate if the value is increasable.
 * @returns {boolean} Return `true` if it is decreasable, else `false`.
 */
const increasable = computed(() => {
    return isNaN(value.value) || value.value < max.value;
});
/**
 * Indicate if the value is decreasable.
 * @returns {boolean} Return `true` if it is decreasable, else `false`.
 */
const decreasable = computed(() => {
    return isNaN(value.value) || value.value > min.value;
});

/**
 * Change event handler.
 * @param {string} value - The new value.
 */
function change(event) {
    setValue(event.target.value);
}
/**
 * Paste event handler.
 * @param {Event} event - Event object.
 */
function paste(event) {
    const clipboardData = event.clipboardData || window.clipboardData;
    if (clipboardData && !REGEXP_NUMBER.test(clipboardData.getData("text"))) {
        event.preventDefault();
    }
}
/**
 * Decrease the value.
 */
function decrease() {
    if (decreasable.value) {
        //let { value } = this;
        if (isNaN(value.value)) {
            value.value = 0;
        }
        setValue(normalizeDecimalNumber(value.value - step.value));
    }
}
/**
 * Increase the value.
 */
function increase() {
    if (increasable.value) {
        //let { value } = this;
        if (isNaN(value.value)) {
            value.value = 0;
        }
        setValue(normalizeDecimalNumber(value.value + step.value));
    }
}
/**
 * Set new value and dispatch change event.
 * @param {number} value - The new value to set.
 */
function setValue(someValue) {
    const oldValue = value.value;
    let newValue = typeof someValue !== "number" ? parseFloat(someValue) : someValue;
    if (!isNaN(newValue)) {
        if (min.value <= max.value) {
            newValue = Math.min(max.value, Math.max(min.value, newValue));
        }
        if (rounded.value) {
            newValue = Math.round(newValue);
        }
    }
    value.value = newValue;
    if (newValue === oldValue) {
        // Force to override the number in the input box (#13).
        input.value.value = String(newValue);
    }
}

// Watchers
watch(
    modelValue,
    (newValue, oldValue) => {
        if (
            // Avoid triggering change event when created
            !(isNaN(newValue) && typeof oldValue === "undefined") &&
            // Avoid infinite loop
            newValue !== value.value
        ) {
            setValue(newValue);
        }
    },
    { immediate: true }
);

watchDebounced(
    value,
    () => {
        emit("update:modelValue", value.value);
    },
    {
        debounce: ON_CHANGE_DEBOUNCE_TIMER,
    }
);

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
    label: {
        type: String,
        required: false,
    },
    attrs: {
        type: Object,
        default: undefined,
    },
    disabled: Boolean,
    inputtable: {
        type: Boolean,
        default: true,
    },
    max: {
        type: Number,
        default: Infinity,
    },
    min: {
        type: Number,
        default: -Infinity,
    },
    name: {
        type: String,
        default: undefined,
    },
    readonly: Boolean,
    rounded: Boolean,
    step: {
        type: Number,
        default: 1,
    },
    modelValue: {
        type: Number,
        default: NaN,
    },
});
</script>

<style lang="scss" scoped>
.c-field {
    --line-height-label-field: auto;
    --field-label-color: var(--color-grey-dark);

    label {
        --field-label-opacity: 1;
        line-height: var(--line-height-label-field);
    }

    input {
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    // product form in a modal
    .c-product-form.-modal & {
        --line-height-label-field: 1em;
        gap: var(--grid-gap-half);
    }
}
</style>
