<template>
    <table>
        <thead>
            <tr>
                <th v-for="column in columns" :key="column">{{ column }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
                <td v-for="(value, columnIndex) in row" :key="columnIndex">
                    {{ value }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script setup>
import { computed, watchEffect } from "vue";

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const columns = computed(() => props.data.columns);
const rows = computed(() => props.data.rows);

watchEffect(() => {
    if (!columns.length || !rows.length) return;

    // Check if each row has the correct number of columns
    const expectedColumnCount = columns.value.length;
    const invalidRows = rows.value.filter((row) => row.length !== expectedColumnCount);
    if (invalidRows.length > 0) {
        console.error("Invalid rows found:", invalidRows);
    }
});
</script>

<style lang="scss" scoped>
table {
    --table-width-default: calc(100% - (var(--grid-gap-2X) * 2));
    --table-margin-default: 0 var(--grid-gap-2X) var(--grid-gap);

    --table-th-padding-default: 0 3rem 2rem 0;
    --table-th-text-color-default: inherit;
    --table-th-font-weight-default: 700;

    --table-td-padding-default: 0.5rem 0;
    --table-td-text-color-default: var(--color-grey-dark);
    --table-td-font-weight-default: 400;

    width: dvar(table-width);
    margin: dvar(table-margin);
    border-collapse: collapse;

    @include max(sm) {
        --table-width: calc(100% - (var(--grid-gap-third) * 2));
        --table-margin: 0 var(--grid-gap-third) var(--grid-gap);
    }
    tr {
        + tr {
            td {
                border-top: 1px solid var(--color-grey);
            }
        }
    }

    th,
    td {
        text-align: left;
    }

    th {
        padding: dvar(table-th-padding);
        color: dvar(table-th-text-color);
        font-weight: dvar(table-th-font-weight);
        border-bottom: 1px solid var(--color-grey);
    }
    td {
        padding: dvar(table-td-padding);
        color: dvar(table-td-text-color);
        font-weight: dvar(table-td-font-weight);
    }
}
</style>
