<template>
    <component
        v-if="item.object"
        :is="tag"
        :href="hrefButton"
        :aria-label="`Explorer ${item?.object?.title || item.title}`"
        :title="`Explorer ${item?.object?.title || item.title}`"
        class="c-navigation-card navigation-button-hover"
    >
        <div class="c-navigation-card__media">
            <img
                class="c-navigation-card__media__asset"
                :src="imageSrc1x"
                :srcset="`${imageSrc1x} 1x, ${imageSrc2x} 2x`"
                :alt="item.title"
                loading="lazy"
            />
        </div>

        <the-navigation-arrow-link :label="item.title" />
    </component>
</template>

<script setup>
import { computed } from "vue";
import { cropImage } from "@utils";

import TheNavigationArrowLink from "@vue-components/TheNavigation/components/TheNavigationArrowLink";

const props = defineProps({
    item: {
        type: Object,
        required: false,
        default: null,
    },
    tag: {
        type: String,
        required: false,
        default: "button",
        validator: (tagName) => ["button", "a"].includes(tagName),
    },
});

const hrefButton = computed(() => (props.tag === "a" ? `${props.item.url}` : null));

const imageSrc1x = computed(() => {
    return cropImage(props.item.object.featured_image, 307, 410);
});
const imageSrc2x = computed(() => {
    return cropImage(props.item.object.featured_image, 614, 820);
});
</script>

<style lang="scss" scoped>
.c-navigation-card {
    --navigation-card-max-width: 23.5rem;
    --navigation-card-radius: var(--radius-regular);
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: var(--navigation-card-max-width);
    gap: var(--grid-gap-half);

    @include min("md") {
        --navigation-card-radius: var(--radius-medium);
    }

    @include min("lg") {
        --navigation-card-max-width: 31rem;
    }

    &__media {
        width: 100%;
        aspect-ratio: 1 / 1.335;
        background-color: var(--color-grey-light);
        border-radius: var(--navigation-card-radius);
        overflow: hidden;

        &__asset {
            @include object-fit;
            display: block;
        }
    }
}
</style>
