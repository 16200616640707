/**
 * Recursively retrieves a nested value from an object based on an array of keys.
 *
 * @param {Array} array - The array of keys representing the path to the desired value.
 * @param {Object} object - The object to retrieve the nested value from.
 * @returns {*} - The nested value from the object.
 */
function recurse(array, object) {
    var next = array.length ? object[array.shift()] : object;
    return next instanceof Object && next[array[0]] ? recurse(array, next) : next;
}

export default recurse;
