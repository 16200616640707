<template>
    <div v-if="markers.length > 0" class="c-map-base">
        <GoogleMap
            class="c-map-base__map"
            ref="mapRef"
            :api-key="mapAPIKey"
            :center="mapCenter"
            :zoom="zoom"
            :styles="mapStyles"
            zoomControlPosition="RIGHT_TOP"
            :streetViewControl="false"
            :fullscreenControl="false"
            :mapTypeControl="false"
            :clickableIcons="false"
            language="fr-CA"
        >
            <MarkerCluster :options="{ renderer: renderer }">
                <CustomMarker
                    v-for="(marker, i) in markers"
                    :key="`${i}-${marker.name}`"
                    :options="{
                        position: marker.location.position,
                        anchorPoint: 'BOTTOM_CENTER',
                    }"
                    @click="markerClicked(marker)"
                >
                    <map-base-marker :is-active="activeMarker?.shop_id == marker.shop_id" />
                </CustomMarker>
            </MarkerCluster>

            <CustomMarker
                v-if="pin?.literal"
                :key="pin.literal"
                :options="{
                    position: pin.position,
                    anchorPoint: 'BOTTOM_CENTER',
                    zIndex: 999,
                }"
            >
                <map-base-pin />
            </CustomMarker>
        </GoogleMap>
    </div>
</template>

<script setup>
import { ref, toRaw, toRefs, watch } from "vue";
import { GoogleMap, CustomMarker, MarkerCluster } from "vue3-google-map"; // https://www.npmjs.com/package/vue3-google-map

import MapBaseMarker from "./components/MapBaseMarker";
import MapBasePin from "./components/MapBasePin";

const props = defineProps({
    markers: {
        type: Array,
        required: true,
        default: () => [],
    },
    pin: {
        type: Object,
        required: false,
        default: () => {},
    },
    zoom: {
        type: Number,
        required: false,
        default: 13,
    },
    activeMarker: {
        type: Object,
        required: false,
        default: () => {},
    },
});

const emits = defineEmits(["marker:click"]);

const markers = toRaw(props.markers); // Remove reactivity
const pin = toRaw(props.pin); // Remove reactivity
const { activeMarker } = toRefs(props);

const mapRef = ref(null);
const mapAPIKey = import.meta.env.VITE_GOOGLE_API_KEY;
const mapCenter = { lat: 46.857258, lng: -71.510065 }; // Quebec city coordinates. Only before fitBounds do his things.

// Center the map according to all markers
watch(
    () => mapRef.value?.ready,
    (ready) => {
        if (!ready) return;

        if (pin?.literal) {
            panTo(pin.position.lat, pin.position.lng);
        } else if (markers?.length > 1) {
            const bounds = new google.maps.LatLngBounds();
            markers.forEach((marker) => {
                bounds.extend(new google.maps.LatLng(marker.location.position.lat, marker.location.position.lng));
            });
            mapRef.value.map.fitBounds(bounds);
        } else {
            const firstMarker = markers.find((m) => m?.location);
            if (!firstMarker) return;

            panTo(firstMarker.location.position.lat, firstMarker.location.position.lng);
        }
    }
);

/*====================
=      METHODS
====================*/

const panTo = (lat, lng, zoom = props.zoom) => {
    if (mapRef.value?.ready) {
        mapRef.value.map.panTo({ lat: lat, lng: lng });
        mapRef.value.map.setZoom(zoom);
    }
};

const markerClicked = (marker) => {
    panTo(marker.location.position.lat, marker.location.position.lng);

    emits("marker:click", marker);
};

watch(
    activeMarker,
    (newMarker) => {
        if (newMarker?.location) panTo(newMarker.location.position.lat, newMarker.location.position.lng);
    },
    { deep: true }
);

/*====================
=      VISUAL
====================*/

// Greyscale map colors
const mapStyles = [
    { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
    { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#f5f5f5" }] },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [{ color: "#bdbdbd" }],
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#e5e5e5" }],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e" }],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#dadada" }],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#616161" }],
    },
    {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e" }],
    },
    {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [{ color: "#e5e5e5" }],
    },
    {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#c9c9c9" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e" }],
    },
];

// Cluster marker
const svg = window.btoa(`
  <svg fill="#99D2F2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" style="filter:drop-shadow(1px 1px 3px rgba(19, 46, 73, 0.15));">
    <circle cx="50%" cy="50%" r="30" />
  </svg>`);

const renderer = {
    render: ({ count, position }) =>
        new google.maps.Marker({
            label: {
                text: String(count),
                color: "#24243F",
                fontSize: "14px",
                fontWeight: "700",
                fontFamily: "Museo Sans Rounded, sans-serif",
            },
            position,
            icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new google.maps.Size(75, 75),
            },
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
            gridSize: 10,
        }),
};
</script>

<style lang="scss" scoped>
.c-map-base {
    --map-height-default: max(100%, 40rem);
    --map-radius-default: var(--radius-regular);
    height: dvar(map-height);
    overflow: hidden;
    border-radius: dvar(map-radius);

    @include min(md) {
        --map-radius-default: var(--radius-large);
    }

    &__map {
        width: 100%;
        height: 100%;
    }
}
</style>
