<template>
    <base-button
        :class="{ 'is-full': isFull }"
        :is-loading="isLoading"
        :disabled="!variant.available"
        @click="add"
    >
        <template #label>
            <slot>
                {{ label }}
            </slot>
        </template>
    </base-button>
</template>

<script setup>
import { computed, ref } from "vue";
import { useTranslationDescriptions } from "@vue-composables";
import { useCartStore } from "@vue-stores";
import { moneyFormat } from "@utils";

import BaseButton from "@vue-components/BaseButton";

const props = defineProps({
    quantity: {
        type: Number,
        default: 1,
    },
    variant: {
        type: Object,
        required: true,
    },
    isFull: {
        type: Boolean,
        default: true,
    },
});

const emit = defineEmits(["addedToCart"]);

// Composables
const cart = useCartStore();
const { getDescription } = useTranslationDescriptions();

// Refs
const isLoading = ref(false);
const label = computed(() =>
    props.variant.available
        ? getDescription("variant.addToCart")
        : getDescription("variant.out_of_stock")
);

// Add to cart function
async function add() {
    isLoading.value = true;

    const line = {
        id: props.variant.id,
        quantity: props.quantity,
    };

    if (props.variant?.compare_at_price) {
        line.properties = {
            "Prix original": moneyFormat(props.variant.compare_at_price),
        };
    }

    const response = await cart.addLine(line);

    emit("addedToCart", response);

    isLoading.value = false;
}
</script>

<style lang="scss" scoped></style>
