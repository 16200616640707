<template>
    <modal-base
        class="c-modal-instore-availability"
        type="drawer"
        :name="name"
        @onOpenAnimationCompleted="triggerAutomaticSearch"
        @onCloseAnimationCompleted="resetSearchForm"
    >
        <template #header>
            <slot name="header"></slot>

            <modal-instore-availability-search
                ref="instoreAvailabilitySearch"
                @onSearch="(b) => (availableInBoutiques = b)"
                @onUpdate="(status) => updateStatus(status)"
            />
        </template>

        <template #default>
            <div
                v-if="hasError"
                class="c-modal-instore-availability-search__error-message | c-warning"
                :aria-hidden="!hasError"
            >
                <!-- Warning: Info and Errors -->
                {{ getDescription("boutiques.search.error") }}
            </div>

            <template v-if="firstSearched">
                <template v-if="availableInBoutiquesDatas.length > 0">
                    <div class="c-modal-instore-availability-search__success-message | c-warning">
                        <strong>
                            {{ productData.name }}
                            <span v-if="productData.size"> (dans la taille {{ productData.size }}) </span>
                        </strong>
                        <span v-html="getDescription('product.product_available')" />
                    </div>

                    <ul class="c-modal-instore-availability__cards-list">
                        <li v-for="boutique in availableInBoutiquesDatas" :key="`boutique-${boutique.id}`">
                            <card-boutique :data="boutique" hide-directions-link hide-opening-hours>
                                <template #extra>
                                    <p v-if="boutique.qty == 1" class="c-card-boutique__notice">
                                        {{ getDescription("product.low_quantity_one") }}
                                    </p>
                                    <p v-else-if="boutique.qty <= 10" class="c-card-boutique__notice">
                                        {{ getDescription("product.low_quantity_number") }} {{ boutique.qty }}.
                                    </p>
                                </template>
                            </card-boutique>
                        </li>
                    </ul>
                </template>

                <div v-else class="c-modal-instore-availability__empty">
                    <slot name="empty">
                        <h3 class="t-h5" v-html="getDescription('product.no_result.title')"></h3>
                        <p v-html="getDescription('product.no_result.description')"></p>
                    </slot>
                </div>
            </template>
        </template>

        <template v-if="$slots.footer" #footer>
            <slot name="footer"></slot>
        </template>
    </modal-base>
</template>

<script setup>
import { ref, onBeforeMount, onUnmounted, computed, provide, toRefs } from "vue";
import { useModalsStore, useBoutiquesStore } from "@vue-stores";
import { useTranslationDescriptions } from "@vue-composables";
import { storeToRefs } from "pinia";

import { getCleanProductTitle } from "@/utils";

import CardBoutique from "@vue-components/CardBoutique";
import ModalBase from "@vue-components/ModalBase";
import ModalInstoreAvailabilitySearch from "./components/ModalInstoreAvailabilitySearch.vue";

// Props
const props = defineProps({
    name: {
        type: String,
        required: true,
        default: "",
    },
    selectedVariant: {
        type: Object,
        required: true,
    },
    product: {
        type: Object,
        required: true,
    },
});

// Composables
const modals = useModalsStore();
const { getDescription } = useTranslationDescriptions();

// Refs
const { boutiques } = storeToRefs(useBoutiquesStore());
const { product, selectedVariant } = toRefs(props);
const availableInBoutiques = ref([]);
const instoreAvailabilitySearch = ref(null);
const isLoading = ref(false);
const firstSearched = ref(false);
const hasError = ref(false);

// Computed
const variantSize = computed(() => selectedVariant.value.option2);
const variantSku = computed(() => selectedVariant.value.sku);
const variantTitle = computed(() => selectedVariant.value.public_title);
const productTitle = computed(() => getCleanProductTitle(product.value.title));
const displayTitle = computed(() => `${productTitle.value} (${variantTitle.value})`);

const productData = computed(() => {
    return { sku: variantSku.value, name: productTitle.value, size: variantSize.value };
});

const availableInBoutiquesDatas = computed(() => {
    let boutiquesUpdated = [];

    availableInBoutiques.value.forEach((availableBoutique) => {
        const boutiqueData = boutiques.value.find((b) => b.shop_id == availableBoutique.shop_id);
        boutiquesUpdated.push({ ...availableBoutique, ...boutiqueData });
    });

    return boutiquesUpdated;
});

// Provide
provide("product", productData);

// Fn
function updateStatus(status) {
    isLoading.value = status.isLoading;
    firstSearched.value = status.firstSearched;
    hasError.value = status.hasError;
}

const triggerAutomaticSearch = () => {
    instoreAvailabilitySearch.value.automaticSearch();
};

const resetSearchForm = () => {
    availableInBoutiques.value = [];
    instoreAvailabilitySearch.value.reset();
};

// Hooks
onBeforeMount(() => {
    modals.registerModal({
        component: "ModalInstoreAvailability",
        name: props.name,
    });
});

onUnmounted(() => {
    modals.eraseModal(props.name);
});
</script>

<style lang="scss">
.c-modal-instore-availability {
    @include min(md) {
        --modal-drawer-padding-x: var(--grid-gap-2X);
        --modal-drawer-close-button-right-ratio: 0.55;
    }

    .c-modal-drawer__header {
        flex-direction: column;
    }

    &__cards-list {
        > li {
            padding: var(--grid-gap-1-25X) 0;

            &:not(:last-child) {
                border-bottom: 0.2rem solid var(--color-grey-light);
            }
        }

        .c-card-boutique {
            --card-boutique-gap: var(--grid-gap-half);

            &__distance {
                display: none;
            }

            &__links {
                position: absolute;
                top: dvar(card-boutique-padding-y);
                right: dvar(card-boutique-padding-x);
            }

            &__notice {
                color: var(--color-accent-blue);
                font-weight: 700;
            }
        }
    }

    &__empty {
        @include stack("vertical", $gap: var(--grid-gap-half), $var-prefix: "instore-availability-empty");
        max-width: 35rem;
        text-align: center;
        margin: var(--grid-gap-3X) auto 0;
    }
}
</style>
