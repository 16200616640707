import { fetchConfig, handleize } from "@/utils";

const API = "https://monclubc.com";
const config = fetchConfig("json", "GET");

// Fetch all boutiques
export const useGetBoutiques = async () => {
    const response = await fetch(`${API}/address`, config);
    const boutiquesJSON = await response.json();

    return boutiquesJSON;
};

// Get boutiques distance from location
export const useNearestBoutiques = async (location) => {
    const encodedLocation = handleize(location); // Make sure location is right format

    const response = await fetch(`${API}/nearest?place=${encodedLocation}`, config);
    const distancesJSON = await response.json();

    return distancesJSON;
};

// Get product inventory in boutiques
export const useProductInventory = async (productID, location) => {
    const encodedLocation = handleize(location); // Make sure location is right format

    const response = await fetch(`${API}/inventory?product=${productID}&place=${encodedLocation}`, config);
    const productInventoryJSON = await response.json();

    const parsedBoutiques = productInventoryJSON.filter((b) => b.qty > 0);

    return parsedBoutiques;
};
