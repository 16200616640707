<template>
    <template v-if="settings && settings.hasSearch && filter.type == 'list'">
        <input-base
            type="search"
            v-model="searchTerm"
            placeholder="Rechercher..."
            :is-full="true"
            class="filter-input"
        />
    </template>

    <!-- First, filter Filters based on their Shopify´s filter.type -->
    <!-- Price Range -->
    <template v-if="filter.type == 'price_range'" class="filter-type-price_range">
        <!-- TODO: Range Component -->
        <input
            type="number"
            :name="filter.min_value.param_name"
            :value="filter.min_value.value || 0"
            :min="0"
            :max="filter.range_max"
        />
        <input
            type="number"
            :name="filter.max_value.param_name"
            :value="filter.max_value.value || filter.range_max"
            :min="0"
            :max="filter.range_max"
        />
    </template>

    <!-- Boolean -->
    <template v-else-if="filter.type == 'boolean'" class="filter-type-boolean">
        <div v-for="(filterValue, i) in filtredFilterValues">
            <!-- TODO: Toggle Component -->
            <filter-value-base :value="filterValue" />
        </div>
    </template>

    <!-- List -->
    <div
        v-else-if="filtredFilterValues.length && settings"
        class="filter-type-list"
        :class="`list-item-type-${settings.itemType}`"
    >
        <template v-for="filterValue in filtredFilterValues" :key="filterValue.param_name">
            <!-- Use listItemTypeComponent to display the right component based on the `settings` prop -->
            <component :is="listItemTypeComponent" :value="filterValue" />
        </template>
    </div>

    <!-- Use Checkbox as fallback -->
    <div
        v-else-if="filtredFilterValues.length"
        class="filter-type-list"
        :class="`list-item-type-${listItemTypeComponent}`"
    >
        <template v-for="filterValue in filtredFilterValues" :key="filterValue.param_name">
            <component :is="listItemTypeComponent" :value="filterValue" />
        </template>
    </div>
</template>

<script setup>
import { computed, ref, toRefs } from "vue";
import FilterValueBase from "./components/FilterValueBase";
import FilterValueCheckbox from "./components/FilterValueCheckbox";
import FilterValueSize from "./components/FilterValueSize";
import FilterValueSwatch from "./components/FilterValueSwatch";

import InputBase from "@vue-components/InputBase";

// Static Object to have access to component instances
const availableComponentsForListType = {
    checkbox: FilterValueCheckbox,
    size: FilterValueSize,
    swatch: FilterValueSwatch,
};

// Refs
const searchTerm = ref(null);
const { filter, settings } = toRefs(props);

/**
 * Computed property that returns the component name based on the `settings` item type.
 * If `settings.itemType` is defined, the component name will be taken from the `availableComponentsForListType` object using that value.
 * Otherwise, the component name will default to "checkbox".
 *
 * @returns {string} The name of the component to be used.
 */
const listItemTypeComponent = computed(() =>
    settings?.value?.itemType && availableComponentsForListType.hasOwnProperty(settings.value.itemType)
        ? availableComponentsForListType[settings.value.itemType]
        : availableComponentsForListType.checkbox
);

/**
 * Computed property to filter values based on `searchTerm` value and filter’s `active` state.
 * Both `label` and `searchTerm` are converted to lower case for convenience.
 *
 * @computed filtredFilterValues
 * @type {Array}
 * @readonly
 * @returns {Array} An array of filtered values.
 */
const filtredFilterValues = computed(() => {
    if (!searchTerm.value) {
        return filter.value.values;
    }

    return filter.value.values.filter(
        (v) => v.active || v.label.toLowerCase().includes(searchTerm.value.toLowerCase())
    );
});

const props = defineProps({
    filter: {
        type: Object,
        required: true,
    },
    settings: {
        type: Object,
        required: false,
    },
});
</script>

<style lang="scss" scoped>
/* Hide unwanted elements from Retail1 imports */
[title="Sans catégorie"] {
    display: none;
}

.filter-input {
    margin-bottom: var(--grid-gap);
}

.filter-type {
    /* Price Range */
    &-price_range {
    }
    /* Boolean */
    &-boolean {
    }
    /* List */
    &-list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--grid-gap-half);

        /* TMP: Will probably need some adjustments when real products are going to be available */
        max-height: 20rem;
        overflow-y: auto;

        &.list-item-type-size {
        }
        &.list-item-type-swatch {
        }
        &.list-item-type-checkbox {
        }
    }
}
</style>
