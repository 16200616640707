<template>
    <modal-base class="c-modal-search" type="drawer" :name="modalName">
        <template #header>
            <slot name="header"></slot>
        </template>

        <template #default>
            <modal-search-form />
            <slot />
        </template>

        <template #footer>
            <slot name="footer"></slot>
        </template>
    </modal-base>
</template>

<script setup>
import { onBeforeMount } from "vue";
import { useModalsStore } from "@vue-stores";

import ModalBase from "@vue-components/ModalBase";
import ModalSearchForm from "./components/ModalSearchForm.vue";

// Register modal
const modals = useModalsStore();
const modalName = "search-modal";

onBeforeMount(() => {
    modals.registerModal({
        component: "ModalSearch",
        name: modalName,
    });
});
</script>

<style lang="scss">
.c-modal-search {
    .c-modal-drawer__header {
        --modal-drawer-header-padding-right: 0;

        @include max(md) {
            flex-direction: column;
        }

        p {
            max-width: 28ch;
            text-align: center;
        }
    }
}
</style>
